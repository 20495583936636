import * as React from "react";
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAppTheme } from "../../utils/theme";
import { useNavigate } from "react-router";

const breadcrumbStyles = {
	textTransform: "uppercase",
	fontWeight: 700,
};

interface BreadcrumbItem {
	label: string;
	href?: string;
	isLink: boolean;
}

interface BreadcrumbsProps {
	items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
	const theme = useAppTheme();
	const navigate = useNavigate();

	return (
		<div role="presentation">
			<MUIBreadcrumbs
				separator={<NavigateNextIcon fontSize="small" />}
				aria-label="breadcrumb"
			>
				{items.map((item, index) =>
					item.isLink ? (
						<Link
							key={`breadcrumb-${index}`}
							sx={{
								...breadcrumbStyles,
								cursor: "pointer",
							}}
							variant="labelMedium"
							underline="none"
							color={theme.palette.textMain.dark4}
							onClick={() => navigate(item.href as string)}
						>
							{item.label}
						</Link>
					) : (
						<Typography
							key={`breadcrumb-${index}`}
							sx={breadcrumbStyles}
							variant="labelMedium"
							color={theme.palette.textMain.main2}
						>
							{item.label}
						</Typography>
					),
				)}
			</MUIBreadcrumbs>
		</div>
	);
};

export default Breadcrumbs;
