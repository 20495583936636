import { axios } from "../utils/axios.create";
import { TotalsResponse } from "../domain/totals.interface";

const getKPIData = async (userId: string): Promise<TotalsResponse> => {
	return axios
		.get(`/users/${userId}/totals`)
		.then((res) => res.data.body)
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

export const dashboardService = {
	getKPIData,
};
