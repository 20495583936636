import { axios } from "../utils/axios.create";
import { CardOrder, CardOrderRequest } from "../domain/cardOrders.interface";

const getCardOrders = async (
	userId?: string,
	orgUnitId?: string,
	pageSize?: number,
	pageNumber?: number,
): Promise<{ data: CardOrder[]; total: number }> => {
	return axios
		.get(
			`/cards/orders?pageNumber=${pageNumber}${userId ? "&userId=" + userId : ""}${orgUnitId ? "&orgUnitIds=" + orgUnitId : ""}${pageSize ? "&pageSize=" + pageSize : ""}`,
		)
		.then((res) => res.data.body)
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getCardOrder = async (orderId?: string): Promise<CardOrder> => {
	return axios
		.get(`/cards/orders?${orderId ? "id=" + orderId : ""}`)
		.then((res) => {
			return res.data.body?.data?.length ? res.data.body.data[0] : undefined;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const addOrder = async (order: CardOrderRequest): Promise<CardOrder> => {
	return axios
		.post("/cards/order", order)
		.then((res) => res.data.body)
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

export const cardsService = {
	getCardOrders,
	getCardOrder,
	addOrder,
};
