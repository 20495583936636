// Basic libraries
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Material UI Components
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// Custom Components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
// Services and Hooks
import { useCreateUser } from "../../hooks/useUser/index";
// Utils and Stores
import UserInformation from "./UserInformation";
import { UserSchema } from "./useSchema";

import { IUserPayload } from "@/domain/user.interface";
import { Alert, Snackbar } from "@mui/material";
import { useCreateAuditlog } from "../../hooks/useAuditlog/index";
import { useUserStore } from "../../stores/user.store";

const AddUser: FC = () => {
	const { userInfo } = useUserStore();
	const { mutateAsync: create, isPending, error } = useCreateUser();
	const navigate = useNavigate();
	const { mutateAsync: createAuditLog } = useCreateAuditlog();
	const showConfirmationState = useState(false);
	const generalErrorMessageState = useState("");

	const handleSubmit = async (data: UserSchema) => {
		createAuditLog({
			action: "When inviting a new user (adding)",
			module: "Users",
			option: "User add",
			detail: `Invited new user ${data.email}`,
			actionCode: "WEB_USRS_USR_ADD_INVITE",
			appType: "WEB_BACK_OFFICE",
			createdBy: userInfo?.id || "",
		});

		const dataMapped: IUserPayload = {
			account: process.env.REACT_APP_ACCOUNT as string,
			email: data.email,
			firstName: data.firstName,
			lastName: data.lastName,
			status: data.status,
			phoneNumber: data.phoneNumber,
			npi: data.npi || "",
			programs:
				data.programOrganizationsGroupList?.map((selOrg) => {
					return {
						programId: selOrg.programId,
						orgUnitId: selOrg.organizationIdsList?.map((item) => item) || [],
					};
				}) || [],
			roles: [data.roleId],
		};

		await create(dataMapped).then(() => {
			navigate("/users");
		});
	};

	useEffect(() => {
		if (error) {
			generalErrorMessageState[1](
				"An error occurred while saving the data, please check the data and try again.",
			);
		}
	}, [error]);

	return (
		<div className="w-full overflow-y-auto scroll-smooth">
			<div className="container">
				<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							width: "100%",
						}}
					>
						<Breadcrumbs
							items={[
								{
									label: "user list",
									href: "/users",
									isLink: true,
								},
								{ label: "add user", isLink: false },
							]}
						/>
						<Typography color="text.dark2" variant="headlineSmall">
							Create new user
						</Typography>
					</Box>

					<Card
						sx={{
							minWidth: 275,
							width: "100%",
							minHeight: 826,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<CardContent className="space-y-5" sx={{ flexGrow: 1 }}>
							<UserInformation
								isEditMode={false}
								onSubmit={handleSubmit}
								showConfirmationState={showConfirmationState}
								generalErrorMessageState={generalErrorMessageState}
							/>
						</CardContent>
						<CardActions sx={{ borderTop: "1px solid #e5e7eb" }}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									width: "100%",
									gap: "0.5rem",
									padding: "1rem",
								}}
							>
								<Button
									size="large"
									onClick={() => navigate("/users")}
									variant="outlined"
								>
									CANCEL
								</Button>
								<Button
									size="large"
									color="primary"
									variant="contained"
									sx={{ textTransform: "none", textAlign: "center" }}
									disabled={isPending}
									onClick={() => {
										showConfirmationState[1](true);
									}}
								>
									INVITE
								</Button>
							</Box>
						</CardActions>
					</Card>
				</div>
			</div>
			{!!generalErrorMessageState[0] && (
				<Snackbar
					open={true}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					autoHideDuration={6000}
					onClose={() => {
						generalErrorMessageState[1]("");
					}}
				>
					<Alert
						onClose={() => {
							generalErrorMessageState[1]("");
						}}
						severity="error"
						variant="filled"
						sx={{ width: "100%" }}
					>
						{generalErrorMessageState[0]}
					</Alert>
				</Snackbar>
			)}
		</div>
	);
};

export default AddUser;
