import Box from "@mui/material/Box";
import * as React from "react";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
	children,
	value,
	index,
	...other
}) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ py: 3, px: 1 }} className="space-y-4">
					{children}
				</Box>
			)}
		</div>
	);
};

export default TabPanel;
