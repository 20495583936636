/* eslint-disable no-undef */
/* global process */

export function getConfig() {
	// Configure the audience here. By default, it will take whatever is in the config
	// (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
	// is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
	// don't have an API).
	// If this resolves to `null`, the API page changes to show some helpful info about what to do
	// with the audience.
	if (
		!process.env.REACT_APP_AUTH0_DOMAIN ||
		!process.env.REACT_APP_AUTH0_CLIENT_ID ||
		!process.env.REACT_APP_AUTH0_ORGANIZATION
	) {
		throw new Error(
			"Please set REACT_APP_AUTH0_ORGANIZATION, REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID in your .env file",
		);
	}

	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const audience = `https://${domain}/api/v2/`;
	const organization = process.env.REACT_APP_AUTH0_ORGANIZATION;

	return {
		domain: domain,
		clientId: clientId,
		audience: audience,
		organization: organization,
	};
}
