import { rolService } from "../../services/role.service";
import { useQuery } from "@tanstack/react-query";

const { findActiveRolesByAccount } = rolService;

export const useGetActiveRolesByAccount = (account: string) => {
	return useQuery({
		queryKey: ["sims-account-roles"],
		queryFn: () => findActiveRolesByAccount(account),
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});
};
