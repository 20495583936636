import { WaveChartComponent } from "./WaveChartComponent";
import { TotalBeneficiariesComponent } from "./TotalBeneficiariesComponent";
import { EventsAndAlertsComponent } from "./EventsAndAlertsComponent";
import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Stack, Box } from "@mui/material";
import Header from "./Header";
import { useUserStore } from "../../stores/user.store";
import AverageComponent from "./AverageComponent";
import KPICard from "../../components/KpiCards/KpiCard";
import BasicKPIComponent from "./BasicKPIComponent";
import useKPIData from "../../hooks/useKPIData";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { useCurrencySetting } from "../../hooks/useSetting";
import { useTranslation } from "react-i18next";
import { formatCommaSeparatedNumber } from "../../utils/general";

interface Filters {
	programId: string;
	providerId: string;
	providerSiteId: string;
	status: string[];
	search: string;
	pageNumber: number;
	pageSize: number;
}

const Dashboard: React.FC = () => {
	const { userInfo, userHasAccessToCode } = useUserStore();
	const { t } = useTranslation("dashboard");
	const currencySetting = useCurrencySetting();
	const [filters, setFilters] = useState<Filters>({
		programId: "",
		providerId: "",
		providerSiteId: "",
		status: [],
		search: "",
		pageNumber: 0,
		pageSize: 10,
	});

	const createAuditLogMutation = useCreateAuditlog();

	const { data: kpiData, refetch } = useKPIData(userInfo?.id || "");

	useEffect(() => {
		if (userInfo?.id) {
			refetch();
		}
	}, [userInfo?.id, filters, refetch]);

	useEffect(() => {
		createAuditLogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: "Dashboard",
			action: "When entering",
			detail: "Access to dashboard",
			actionCode: "WEB_DASH_ACCESS",
			option: "Dashboard",
			// @ts-expect-error - userInfo wont be null
			createdBy: userInfo?.id,
		});
	}, []);

	const handleSearch = () => {
		if (userInfo?.id) {
			refetch();
		}
	};

	return (
		<Container
			disableGutters={true}
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "calc(100vh - 120px)",
			}}
		>
			<Header
				filtersState={[filters, setFilters]}
				handleSearch={handleSearch}
			/>

			{userHasAccessToCode("DASH_VIEW") && (
				<Box
					sx={{
						flex: "1 1 auto",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Grid
						container
						rowSpacing={2}
						sx={{ flex: "0 0 auto", display: { xs: "none", md: "flex" } }}
					>
						<Grid item container spacing={2} marginBottom={2}>
							<BasicKPIComponent
								title={t("totalUDTs")}
								content={kpiData?.totalUDTs || 0}
								footer={{
									hidden: true,
									increased: true,
									label: "11%",
									description: t("past30Days"),
								}}
							/>
							<BasicKPIComponent
								title={t("totalIncentivesEarned")}
								content={
									currencySetting.mask +
									formatCommaSeparatedNumber(
										Math.abs(kpiData?.totalIncentivesEarned || 0),
									)
								}
								footer={{
									hidden: true,
									increased: true,
									label: "",
									description: "",
								}}
							/>
							<BasicKPIComponent
								title={t("totalIncentivesReceived")}
								content={
									currencySetting.mask +
									formatCommaSeparatedNumber(
										Math.abs(kpiData?.totalIncentivesReceived || 0),
									)
								}
								footer={{
									hidden: true,
									increased: true,
									label: "5%",
									description: t("past30Days"),
								}}
							/>
							<BasicKPIComponent
								title={t("currentIncentivesBanked")}
								content={
									currencySetting.mask +
									formatCommaSeparatedNumber(
										Math.abs(kpiData?.currentIncentivesBanked || 0),
									)
								}
								footer={{
									hidden: true,
									increased: true,
									label: "5%",
									description: t("past30Days"),
								}}
							/>
						</Grid>
					</Grid>
					<Box
						sx={{
							flex: "1 1 0px",
							overflowY: "auto",
						}}
					>
						<EventsAndAlertsComponent />
					</Box>
					{false && (
						<>
							<Grid item container direction={"column"} rowSpacing={2} md={2.5}>
								<AverageComponent
									title={t("avgLengthOfTreatment")}
									icon="lightBulb"
									content={
										<Typography align="left" variant="headlineSmall">
											{t("weeks", { count: 11 })}
										</Typography>
									}
									increased
									footerLabel="12 %"
									footerDescription={t("past30Days")}
									boxProps={{ xs: 12, sm: 12, md: 2, item: true }}
								/>
								<AverageComponent
									title={t("avgLengthOfTreatment")}
									icon="lightBulb"
									content={
										<Stack direction={"row"} alignItems={"end"}>
											<Typography align="left" variant="headlineSmall">
												75%
											</Typography>
											<Typography
												align="left"
												color={(theme) => theme.palette.textMain.main2}
												variant="bodyLarge"
											>
												({t("bankedEarned")})
											</Typography>
										</Stack>
									}
									increased={false}
									footerLabel="12 %"
									footerDescription={t("past30Days")}
									boxProps={{ xs: 12, sm: 12, md: 2, item: true }}
								/>
							</Grid>
							<TotalBeneficiariesComponent />
						</>
					)}
				</Box>
			)}
			{false && (
				<>
					<Typography
						marginTop={2}
						variant="h5"
						fontWeight={700}
						color="textMain.dark2"
					>
						{t("cmVisitsInformation")}
					</Typography>
					<Grid container spacing={2}>
						<Grid item container spacing={2}>
							<WaveChartComponent
								title={t("totalCMVisits")}
								gridProps={{ md: 5 }}
							/>
							<WaveChartComponent
								title={t("negativeUDTResults")}
								gridProps={{ md: 3.5 }}
							/>
							<WaveChartComponent
								title={t("positiveUDTResults")}
								gridProps={{ md: 3.5 }}
							/>
						</Grid>
						<Grid item container spacing={2}>
							<KPICard
								gridProps={{ md: 5 }}
								header={
									<Typography variant="subtitle1" color="textSecondary">
										{t("udtNegativeByPercentage")}
									</Typography>
								}
							/>
							<WaveChartComponent
								title={t("excusedUDTVisits")}
								gridProps={{ md: 3.5 }}
							/>
							<WaveChartComponent
								title={t("unexcusedUDTVisits")}
								gridProps={{ md: 3.5 }}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Container>
	);
};

export default Dashboard;
