import { Box, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, Control, FieldErrors } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import { ICreateUserPayload } from "@/domain/user.interface";

const statusList = [
	{ id: "ACTIVE", name: "Active" },
	{ id: "INACTIVE", name: "Inactive" },
];

type Props = {
	control: Control<ICreateUserPayload>;
	errors: FieldErrors<ICreateUserPayload>;
	isEditMode: boolean;
};

const UserForm: FC<Props> = (props) => {
	const { control, errors, isEditMode } = props;

	return (
		<Box
			my={4}
			display="flex"
			flexDirection="column"
			justifyContent="flex-start"
			gap={3}
			p={2}
			sx={{ border: "1px solid #e5e7eb", borderRadius: "8px" }}
			width={["90%", "80", "60%", "40%", "30%"]}
		>
			<Controller
				name="firstName"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, name } }) => (
					<TextField
						onChange={onChange}
						value={value}
						name={name}
						id="firstName"
						label="First name"
						variant="outlined"
						required
						error={!!errors.firstName}
						helperText={errors.firstName ? errors.firstName.message : null}
					/>
				)}
			/>
			<Controller
				name="lastName"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, name } }) => (
					<TextField
						onChange={onChange}
						value={value}
						name={name}
						id="lastName"
						label="Last name"
						variant="outlined"
						required
						error={!!errors.lastName}
						helperText={errors.lastName ? errors.lastName.message : null}
					/>
				)}
			/>
			<Controller
				name="email"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, name } }) => (
					<TextField
						onChange={onChange}
						value={value}
						name={name}
						id="email"
						label="Email"
						variant="outlined"
						required
						error={!!errors.email}
						helperText={errors.email ? errors.email.message : null}
					/>
				)}
			/>
			<Controller
				name="phoneNumber"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, name } }) => (
					<MuiTelInput
						onChange={onChange}
						value={value}
						name={name}
						defaultCountry="US"
						disableDropdown
						label="Phone number"
						variant="outlined"
						required
						className="flex flex-1"
						error={!!errors.phoneNumber}
						helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
					/>
				)}
			/>
			<Controller
				name="npi"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value, name } }) => (
					<TextField
						onChange={onChange}
						value={value}
						name={name}
						id="npi"
						label="NPI"
						variant="outlined"
						className="flex flex-1"
						error={!!errors.npi}
						helperText={errors.npi ? errors.npi.message : null}
					/>
				)}
			/>
			<Controller
				name="status"
				control={control}
				defaultValue={statusList[0].id}
				render={({ field: { onChange, value } }) => (
					<BasicSelect
						handleChange={onChange}
						value={value}
						data={
							statusList?.map((status) => {
								return { key: status.id, value: status.name };
							}) || []
						}
						required
						disabled={!isEditMode}
						id="status"
						label="Status"
						error={!!errors.status}
						errorMessage={errors.status?.message || ""}
					/>
				)}
			/>
		</Box>
	);
};

export default UserForm;
