import { useMutation, useQuery } from "@tanstack/react-query";
import { auditlogService } from "../../services/auditlog.service";
import { AuditlogPayload } from "../../domain/auditlog.interface";

const { create, findByModule } = auditlogService;

export const useCreateAuditlog = () => {
	return useMutation({
		mutationFn: (data: AuditlogPayload) => create(data),
	});
};

export const useGetAuditlog = (
	module: string,
	from: string,
	to: string,
	pageNumber: number,
	pageSize: number,
	lastDocId?: string,
) => {
	return useQuery({
		queryKey: ["sims-audit-get"],
		queryFn: () =>
			findByModule({ module, from, to, pageNumber, pageSize, lastDocId }),
	});
};
