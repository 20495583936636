import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, TextField, TextFieldProps } from "@mui/material";
import Popover from "@mui/material/Popover";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem as TreeItemComponent } from "@mui/x-tree-view/TreeItem";
import { TreeItem } from "../../domain/common.interface";
import { treeToArray } from "../../utils/general";

type Props = {
	label: string;
	selectedId?: string;
	data: TreeItem[];
	onClose?: () => void;
	onSelect: (id: string) => void;
	anchorEl: null | HTMLElement;
	expanded?: boolean;
	/**
	 * Includes children in the selected id, comma separated
	 */
	includeChildren?: boolean;
	textFieldProps?: TextFieldProps;
};

const findInTree = (tree: TreeItem[], id: string): TreeItem | undefined => {
	for (const item of tree) {
		if (item.id === id) {
			return item;
		}
		const found = findInTree(item.children, id);
		if (found) {
			return found;
		}
	}

	return undefined;
};

export const TreeView: FC<Props> = (props) => {
	const { data, anchorEl } = props;
	const [isTreeViewVisible, setIsTreeViewVisible] = useState(false);
	const [selectedId, setSelectedId] = useState(props.selectedId);

	const firstOrDefaultSelectedId = useMemo(() => {
		return props.includeChildren ? selectedId?.split(",")[0] : selectedId;
	}, [props.includeChildren, selectedId]);

	const [selectedLabel, setSelectedLabel] = useState(
		props.selectedId
			? findInTree(data, firstOrDefaultSelectedId ?? "")?.name || ""
			: "",
	);

	const ref = useRef(null);
	const expandedItems = useMemo(() => {
		return treeToArray(data).map((item) => item.id);
	}, [data]);

	const handleSelect = (item: TreeItem) => {
		setSelectedId(item.id);
		setSelectedLabel(item.name);
		setIsTreeViewVisible(false);

		if (props.includeChildren) {
			const children = treeToArray([item]);
			props.onSelect(children.map((child) => child.id).join(","));
		} else {
			props.onSelect(item.id);
		}
		if (props?.onClose) props.onClose();
	};

	useEffect(() => {
		setSelectedLabel(
			findInTree(data, firstOrDefaultSelectedId ?? "")?.name || "",
		);
	}, [data, firstOrDefaultSelectedId]);

	const makeTree = (item: TreeItem) => {
		return (
			<TreeItemComponent
				key={item.id}
				itemId={item.id}
				label={item.name}
				onClick={() => {
					handleSelect(item);
				}}
				sx={{
					paddingTop: "8px",
					paddingBottom: "8px",
				}}
			>
				{item?.children?.map((subItem) => makeTree(subItem))}
			</TreeItemComponent>
		);
	};

	return (
		<Box
			sx={{
				minWidth: 180,
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				maxWidth: "100%",
			}}
		>
			<TextField
				ref={ref}
				label={props.label}
				value={selectedLabel}
				InputProps={{
					readOnly: true,
				}}
				onClick={() => {
					setIsTreeViewVisible(true);
				}}
				disabled={props.data.length === 0}
				{...props.textFieldProps}
			/>
			{isTreeViewVisible && (
				<Popover
					open={isTreeViewVisible}
					anchorEl={anchorEl || ref.current}
					onClose={() => {
						setIsTreeViewVisible(false);
						if (props?.onClose) props.onClose();
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
				>
					<SimpleTreeView
						sx={{
							width: "auto",
							// @ts-expect-error minwidth default value is input width
							minWidth: ref.current?.offsetWidth || "auto",
							padding: "8px 16px 8px 16px",
						}}
						selectedItems={firstOrDefaultSelectedId}
						defaultExpandedItems={props.expanded ? expandedItems : undefined}
					>
						{data?.map((item) => makeTree(item))}
					</SimpleTreeView>
				</Popover>
			)}
		</Box>
	);
};

export default TreeView;
