import { createContext, useContext, MutableRefObject } from "react";
interface PDFGeneratorContextType {
	downloadRef: MutableRefObject<HTMLDivElement | null>;
	handleDownloadCertificate: (fileName: string) => void;
	handleBase64Certificate: (callback: (base64: string) => void) => void;
	// TODO: Add the correct type for the data parameter
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	generateTablePDF: (headers: string[], data: any[], fileName: string) => void;
}

export const PDFGeneratorContext = createContext<
	PDFGeneratorContextType | undefined
>(undefined);

export const usePDFGenerate = () => {
	const context = useContext(PDFGeneratorContext);
	if (!context) {
		throw new Error(
			"usePDFGenerate must be used within a PDFGeneratorProvider",
		);
	}
	return context;
};
