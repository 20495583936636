import { Box, CircularProgress } from "@mui/material";

const Spinner = () => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="240px"
		>
			<CircularProgress sx={{ color: "#6F3996" }} />
		</Box>
	);
};

export default Spinner;
