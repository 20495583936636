import ContentPasteOutlined from "@mui/icons-material/ContentPasteOutlined";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import PersonAdd from "@mui/icons-material/PersonAdd";
import AssessmentOutlined from "@mui/icons-material/AssessmentOutlined";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import AssignmentTurnedIn from "@mui/icons-material/AssignmentTurnedIn";
import CreditCard from "@mui/icons-material/CreditCard";
import HubOutlined from "@mui/icons-material/HubOutlined";
import ArticleOutlined from "@mui/icons-material/ArticleOutlined";
import GroupOutlined from "@mui/icons-material/GroupOutlined";
import DomainOutlined from "@mui/icons-material/DomainOutlined";
import NoteAltOutlined from "@mui/icons-material/NoteAltOutlined";

const getIcon = (iconName: string) => {
	switch (iconName) {
		case "content-paste-outlined":
			return <ContentPasteOutlined />;
		case "person-outlined":
			return <PersonOutlined />;
		case "assessment-outlined":
			return <AssessmentOutlined />;
		case "help-outline-outlined":
			return <HelpOutlineOutlined />;
		case "assignment-turned-in":
			return <AssignmentTurnedIn />;
		case "credit-card":
			return <CreditCard />;
		case "person-add":
			return <PersonAdd />;
		case "hub-outlined":
			return <HubOutlined />;
		case "programs-config":
			return <ArticleOutlined />;
		case "group-outlined":
			return <GroupOutlined />;
		case "domain-outlined":
			return <DomainOutlined />;
		case "note-outlined":
			return <NoteAltOutlined />;
		default:
			return null;
	}
};

export default getIcon;
