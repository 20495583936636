import { ISetting, ISettingResponse } from "../domain/setting.interface";
import { axios } from "../utils/axios.create";

const findAll = async (): Promise<ISettingResponse[]> => {
	return axios
		.get("/settings/account/applications/backoffice")
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getSettingsV2 = async (
	accountId: string,
	userId?: string,
): Promise<ISetting> => {
	const queryParams = new URLSearchParams({
		userId: userId ?? "",
	});

	return axios
		.get(
			`/account/${accountId}/settings-v2/applications/backoffice?${queryParams.toString()}`,
		)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

export const settingService = {
	findAll,
	getSettingsV2,
};
