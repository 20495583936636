import * as yup from "yup";

const userSchema = yup.object().shape({
	firstName: yup
		.string()
		.required("This field is required")
		.min(2, "This field should have at least 2 characters")
		.max(50, "This field should have at most 50 characters"),
	lastName: yup
		.string()
		.required("This field is required")
		.min(2, "This field should have at least 2 characters")
		.max(50, "This field should have at most 50 characters"),
	email: yup
		.string()
		.required("This field is required")
		.email("The format is incorrect"),
	phoneNumber: yup
		.string()
		.required("This field is required")
		.matches(
			/^(\+1|1)?\s?-?\.?\(?\d{3}\)?\s?-?\.?\d{3}\s?-?\.?\d{4}$/,
			"The format is incorrect",
		),
	npi: yup.string().optional(),
	status: yup.string().required("This field is required"),
	roleId: yup.string().required("This field is required").default(""),
	programOrganizationsGroupList: yup.array().of(
		yup
			.object()
			.shape({
				programId: yup.string().required("This field is required"),
				organizationIdsList: yup
					.array()
					.of(yup.string().required("This field is required")),
			})
			.default([]),
	),
});

export type UserSchema = yup.InferType<typeof userSchema>;
export default userSchema;
