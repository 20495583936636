import { Link, Typography } from "@mui/material";
import GenericAlertModal from "../GenericAlertModal";

type LinkNotOpenedModalProps = {
	isOpen: boolean;
	onClose: () => void;
	link: string;
};
const LinkNotOpenedModal: React.FC<LinkNotOpenedModalProps> = (props) => {
	return (
		<>
			{props.isOpen && (
				<GenericAlertModal
					icon={<></>}
					title="Print Gift Card Reward"
					description={
						<>
							<Typography variant="bodyLarge">
								If the gift card page did not open, click{" "}
								<Link href={props.link} variant="bodyLarge" target="_blank">
									here
								</Link>{" "}
								to access the reward.
								<br />
								<br /> If you have already printed the card, you may close this
								pop up.
							</Typography>
						</>
					}
					submitText="Close"
					onClick={props.onClose}
				/>
			)}
		</>
	);
};

export default LinkNotOpenedModal;
