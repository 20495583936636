import { FC, useEffect, ReactNode, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useDeleteUser, useGetUsersApp } from "../../hooks/useUser/index";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DataTable, { HeadCell, DataTableCell } from "../../components/DataTable";
import { headCellsConfig } from "../../utils/tableConfig";
import ActionMenu from "../../components/ActionMenu";
import Button from "@mui/material/Button";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useAppTheme } from "../../utils/theme";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IUser, IUserWithId } from "@/domain/user.interface";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import GenericAlertModal from "../../components/modals/GenericAlertModal";
import { useGetProgramsByAccount } from "../../hooks/usePrograms";
import TreeView from "../../components/TreeView/Index";
import { OrganizationResponse } from "@/domain/organization.interface";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import { Order } from "@/components/DataTable/dataTable.interface";
import { useGetOrganizationList } from "../../hooks/useOrganizations";
import AuditLogModal from "../../components/modals/AuditLog";
import { useUserStore } from "../../stores/user.store";
import { TreeItem } from "../../domain/common.interface";

function createData(
	id: number,
	name: string,
	lastname: string,
	action: ReactNode,
): DataTableCell {
	return {
		id,
		name,
		lastname,
		action,
	};
}

const arrayToTree = (array: OrganizationResponse[]): TreeItem[] => {
	const map: Record<string, TreeItem> = {};
	const result: TreeItem[] = [];

	// Populate the map with TreeItem objects
	array.forEach((item) => {
		map[item.id] = {
			id: item.id,
			name: item.name,
			children: [],
		};
	});

	// Build the tree structure
	array.forEach((item) => {
		if (item.parentOrgUnitId && map[item.parentOrgUnitId]) {
			const parent = map[item.parentOrgUnitId];
			const child = map[item.id];

			// Use a Set to track added children IDs
			const childrenIds = new Set(parent.children.map((child) => child.id));

			if (!childrenIds.has(child.id)) {
				parent.children.push(child);
			}
		} else {
			result.push(map[item.id]);
		}
	});

	return result;
};

const filterUser = (
	userToEvaluate: IUser,
	filterPrograms: string[],
	filterOrgId: string,
	filterText: string,
): boolean => {
	filterPrograms = filterPrograms.filter((id) => id !== "all");

	return (
		filterPrograms.some(() =>
			userToEvaluate?.programs?.some((userProgram) => {
				return (
					filterPrograms.includes(userProgram.programId) &&
					(!filterOrgId ||
						userProgram.orgUnitId.some((orgId) =>
							filterOrgId.includes(
								typeof orgId === "string" ? orgId : orgId.orgUnitId,
							),
						))
				);
			}),
		) &&
		(filterText.trim().length === 0 ||
			`${userToEvaluate.firstName} ${userToEvaluate.lastName}`

				.toLowerCase()

				.includes(filterText.trim().toLowerCase()))
	);
};

const UserList: FC = () => {
	const { userInfo } = useUserStore();
	const theme = useAppTheme();
	const [searchedVal, setSearchedVal] = useState("");
	const [searchedIdProgram, setSearchedIdProgram] = useState<string[]>(["all"]);
	const account = process.env.REACT_APP_ACCOUNT as string;
	const [searchedIdOrganization, setSearchedIdOrganization] = useState("");
	const { data: dataOrganization } = useGetOrganizationList(account);
	const navigate = useNavigate();
	const { data: dataProgram, isSuccess: isSuccessProgram } =
		useGetProgramsByAccount(account);
	const [order] = useState<Order>("desc");
	const {
		data: dataUser,
		refetch: refetchUser,
		isSuccess: isSucessUser,
	} = useGetUsersApp(account, []);
	const { mutateAsync: mutationDeleteUser, isPending } = useDeleteUser();
	const [currentUserToDelete, setCurrentUserToDelete] = useState({
		id: "",
		email: "",
	});

	const [openResponseDelete, setOpenResponseDelete] = useState(false);
	const [deleteResponseMessage, setDeleteResponseMessage] = useState("");
	const { mutateAsync: createAuditLog } = useCreateAuditlog();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [openAuditLogModal, setOpenAuditLogModal] = useState(false);

	const handleDeleteUser = async (userId: string, email: string) => {
		try {
			const response = await mutationDeleteUser(userId);
			setDeleteResponseMessage(response.message);
			setOpenResponseDelete(true);
			setIsDeleteDialogOpen(false);
			refetchUser();
			createAuditLog({
				action: "When deleting a user",
				module: "Users",
				option: "Delete",
				detail: `Deleted the user ${email}`,
				actionCode: "WEB_USRS_USR_DELETE",
				appType: "WEB_BACK_OFFICE",
				createdBy: userInfo?.id || "",
			});
		} catch (error) {
			setDeleteResponseMessage(
				"Failed to delete user because of Network Error",
			);
			setOpenResponseDelete(true);
			setIsDeleteDialogOpen(false);
		}
	};

	const handleEditUser = async (userId: string) => {
		navigate(`/users/${userId}`);
	};

	const rows = Array.isArray(dataUser)
		? (dataUser as IUserWithId[])
				?.filter((item) =>
					filterUser(
						item,
						searchedIdProgram,
						searchedIdOrganization,
						searchedVal,
					),
				)
				.map((item, index) =>
					createData(
						index,
						item.firstName,
						item.lastName,
						<ActionMenu
							options={[
								{ value: "Edit", onClick: () => handleEditUser(item.id) },
								{
									value: "Delete",
									sx: { color: "error.main" },
									onClick: () => {
										setIsDeleteDialogOpen(true);
										setCurrentUserToDelete({
											id: item.id,
											email: item.email,
										});
									},
								},
							]}
						/>,
					),
				)
		: [];

	const selectDataProgram = Array.isArray(dataProgram)
		? dataProgram.map((item) => ({ key: item.id, value: item.name }))
		: [];
	selectDataProgram.unshift({ key: "all", value: "All Programs" });

	const headCells: HeadCell[] = headCellsConfig.user;

	const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
	const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
	const hasCalledCreateAuditLog = useRef(false);

	useEffect(() => {
		if (!hasCalledCreateAuditLog.current) {
			createAuditLog({
				action: "When entering",
				module: "Users",
				option: "Users list",
				detail: "Viewed the list of users",
				actionCode: "WEB_USRS_LIST_VIEW",
				appType: "WEB_BACK_OFFICE",
				createdBy: userInfo?.id || "",
			});
			hasCalledCreateAuditLog.current = true;
		}
	}, []);

	useEffect(() => {
		if (isSuccessProgram) {
			selectDataProgram.forEach((element) => {
				searchedIdProgram.push(element.key);
			});
			setSearchedIdProgram(searchedIdProgram);
		}
	}, [isSuccessProgram]);

	return (
		<>
			<div className="w-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							<Typography variant="headlineSmall">User list</Typography>
						</Box>

						<Card sx={{ minWidth: 275, width: "100%" }}>
							<CardContent className="space-y-5">
								<Box
									display="flex"
									flexDirection={matchesMD ? "column" : "row"}
									gap="1rem"
								>
									<div className="flex flex-1 bg-surfaceCardsmall rounded-lg px-4 py-3 max-w-4xl items-center justify-between h-14 max-w-full">
										<InputBase
											className="flex-1"
											placeholder="Search users"
											inputProps={{ "aria-label": "search" }}
											onChange={(e) => setSearchedVal(e.target.value)}
										/>
										<SearchIcon className="text-icon-primary" />
									</div>

									<Tooltip title="Audit log">
										<IconButton
											size="large"
											sx={{
												borderRadius: "8px",
												border: `1px solid ${theme.palette.outline.dark}`,
												width: matchesXS
													? "100%"
													: matchesMD
														? "100%"
														: "3.5rem",
												marginRight: matchesMD ? "0" : "0.5rem",
											}}
											onClick={() => setOpenAuditLogModal(true)}
										>
											<UpdateOutlinedIcon sx={{ color: "black[50]" }} />
										</IconButton>
									</Tooltip>

									<Button
										size={matchesMD ? "small" : "large"}
										onClick={() => navigate("/user")}
										color="primary"
										variant="contained"
										sx={{
											textTransform: "none",
											textAlign: "center",
											width: matchesXS ? "100%" : matchesMD ? "100%" : "auto",
										}}
									>
										ADD
									</Button>
								</Box>

								<Box
									sx={{
										display: "flex",
										gap: "1rem",
										width: matchesMD ? "100%" : "50%",
										flexDirection: matchesMD ? "column" : "row",
									}}
								>
									<BasicSelect
										handleChange={(value) => {
											if (Array.isArray(value)) {
												if (value[value.length - 1] === "all") {
													setSearchedIdProgram(
														selectDataProgram.map((item) => item.key),
													);
												} else if (
													value[value.length - 1] !== "all" &&
													value.includes("all")
												) {
													value.splice(value.indexOf("all"), 1);
													setSearchedIdProgram(value);
												} else if (
													value.length === selectDataProgram.length - 1 &&
													!value.includes("all") &&
													searchedIdProgram.includes("all")
												) {
													setSearchedIdProgram([]);
												} else if (
													value.length === selectDataProgram.length - 1 &&
													!value.includes("all") &&
													!searchedIdProgram.includes("all")
												) {
													value.push("all");
													setSearchedIdProgram(value);
												} else {
													setSearchedIdProgram(value);
												}
											}
										}}
										data={selectDataProgram}
										value={selectDataProgram
											.filter((data) => searchedIdProgram.includes(data.key))
											.map((data) => data.key)}
										label="Filter by program"
										id="user-program"
										multiple={true}
									/>
									<TreeView
										label="Filter by OU"
										selectedId={searchedIdOrganization}
										expanded
										onSelect={(id) => {
											setSearchedIdOrganization(id);
										}}
										data={arrayToTree(dataOrganization || [])}
										anchorEl={null}
									/>
								</Box>

								<DataTable
									data={rows}
									rowsPerPage={5}
									order={order}
									orderBy="name"
									page={0}
									headCells={headCells}
									isLoading={!isSucessUser}
								/>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
			{isDeleteDialogOpen && (
				<GenericAlertModal
					title="Delete User"
					description="Are you sure you want to delete this registry?"
					submitText="Confirm"
					cancelText="Cancel"
					isLoading={isPending}
					onClick={() => {
						handleDeleteUser(currentUserToDelete.id, currentUserToDelete.email);
					}}
					onCancel={() => {
						setIsDeleteDialogOpen(false);
					}}
				/>
			)}

			{openResponseDelete && (
				<GenericAlertModal
					title="Delete User"
					description={deleteResponseMessage}
					submitText="Accept"
					onClick={() => {
						setOpenResponseDelete(false);
					}}
				/>
			)}
			{openAuditLogModal ? (
				<AuditLogModal
					open={openAuditLogModal}
					module="Users"
					onClose={() => setOpenAuditLogModal(false)}
				/>
			) : null}
		</>
	);
};

export default UserList;
