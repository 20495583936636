import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";

export const CustomStack = styled(Stack)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(2, 1fr)",
	gap: theme.spacing(1),
	width: "100%",
	justifyContent: "flex-start",
}));
