import * as React from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { DataTableCell, DataTableHeadProps } from "./dataTable.interface";
import { Typography } from "@mui/material";

export default function DataTableHead(props: DataTableHeadProps) {
	const { order, orderBy, onRequestSort, headCells } = props;
	const createSortHandler =
		(property: keyof DataTableCell) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						padding="normal"
						sortDirection={orderBy === headCell.id ? order : false}
						style={{
							width: headCell.width,
							textAlign: headCell.align
								? headCell.align
								: headCell.isSortable
									? "left"
									: "right",
						}}
					>
						{headCell.id === "action" || !headCell.isSortable ? (
							<Box component="span">
								<Typography variant="titleTableHeader">
									{headCell.label}
								</Typography>
							</Box>
						) : (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								<Typography variant="titleTableHeader">
									{headCell.label}
								</Typography>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
