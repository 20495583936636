import { useUserStore } from "../../stores/user.store";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { Button, Stack, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AccessDenied: FC = () => {
	const { logout } = useAuth0();
	const createAuditlogMutation = useCreateAuditlog();
	const { userInfo, clearUser } = useUserStore();
	const postAuditlog = async ({
		action,
		actionDetail,
		actionCode,
	}: {
		action: string;
		actionDetail: string;
		actionCode: string;
	}) => {
		await createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: "",
			action,
			detail: actionDetail,
			actionCode,
			option: "",
			createdBy: userInfo?.id || "",
		});
	};

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current) {
			postAuditlog({
				action: "Inactive or not existing account",
				actionDetail:
					"An inactive account or a not existing one(only feasible with SSO) accessed SIMS",
				actionCode: "WEB_INVALID_ACCOUNT",
			});
			postAuditlogCalledRef.current = true;
		}
	}, []);

	const logoutWithRedirect = async () => {
		clearUser();
		await logout({
			logoutParams: {
				returnTo: window.location.origin,
				federated: true,
			},
		});
	};

	return (
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="center"
			width={"100%"}
			height={"100vh"}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<img
					className="w-[269.1px] relative object-cover"
					alt=""
					src={"/assets/access-denied.png"}
				/>
				<Typography variant="h4">Access denied</Typography>
				<Typography variant="body1">
					You do not have access to SIMS. Please contact your system
					administrator.
				</Typography>

				<Button variant="contained" onClick={logoutWithRedirect}>
					BACK TO SIGN IN
				</Button>
			</Stack>
		</Stack>
	);
};

export default AccessDenied;
