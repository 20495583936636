import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { optionsHelp } from "../../utils/helpOptionConfig";
import OptionHelp from "./OptionHelp";

import { CardContent } from "@mui/material";
import { useSettingsV2 } from "../../hooks/useSetting";
import { useAppTheme } from "../../utils/theme";
import { helpUrlsValue, ISetting } from "../../domain/setting.interface";

const HelpSupportList: FC = () => {
	const { data: settingsV2 } = useSettingsV2();
	const { helpUrls, helpPhone, helpSchedule, displayContactFooter } =
		settingsV2 || ({} as ISetting);
	const theme = useAppTheme();
	return (
		<>
			<div className="w-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-start min-h-screen space-y-3">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							<Typography variant="headlineSmall">Help and Support</Typography>
						</Box>

						<Grid container spacing={2} sx={{ padding: 0 }}>
							{optionsHelp.map((opt) => {
								return (
									<OptionHelp
										id={opt.id}
										title={opt.title}
										description={opt.description}
										image={opt.image}
										buttonText={opt.buttonText}
										colorButton={opt.colorButton}
										buttonVariant={opt.buttonVariant}
										url={
											((helpUrls || {}) as helpUrlsValue)[
												opt.id as keyof helpUrlsValue
											]
										}
									/>
								);
							})}
						</Grid>
						{(displayContactFooter as boolean) && (
							<Card sx={{ marginTop: "2rem", width: "100%" }}>
								<CardContent>
									<Typography variant="titleSmallOptionHelp">
										Still need help? Contact us
									</Typography>
									<Box display="flex" gap={10} justifyContent="space-between">
										<Box sx={{ flex: "0 0 30%" }}>
											<Typography
												variant="bodyOptionHelp"
												color="text.secondary"
											>
												If you are in need of additional support, please call
												the incentive manager help desk at:
											</Typography>
											<Typography
												sx={{
													padding: ".6rem 0",
													fontWeight: "bold",
													color: theme.palette.textLinkHelpSupport.main,
													textDecoration: "underline",
												}}
											>
												{(helpPhone as string) ?? ""}
											</Typography>
										</Box>
										<Box sx={{ flex: "0 0 20%" }}>
											<Typography
												variant="bodyOptionHelp"
												color="text.secondary"
											>
												{
													// eslint-disable-next-line @typescript-eslint/no-explicit-any
													`Help Desk is avaliable Monday-Friday between the hours of  ${(helpSchedule as any)["from"]} ET and ${(helpSchedule as any)["to"]} PT.`
												}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default HelpSupportList;
