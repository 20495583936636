import React, { FC } from "react";
import { Box, GridOwnProps, Grid, Stack } from "@mui/material";

interface KPIBaseCardProps {
	boxProps?: GridOwnProps;
	children?: React.ReactNode;
}

interface KPICardProps extends KPIBaseCardProps {
	header?: React.ReactNode;
	content?: React.ReactNode;
	footer?: React.ReactNode;
	gridProps?: GridOwnProps;
}

export const KPIBaseCard: FC<KPIBaseCardProps> = (props) => {
	return (
		<Box
			sx={{
				bgcolor: "white",
				borderRadius: 2,
				boxShadow: 1,
				minWidth: 200,
				minHeight: 150,
			}}
			padding={2}
			{...props.boxProps}
		>
			{props.children}
		</Box>
	);
};

const KPICard: React.FC<KPICardProps> = (props) => (
	<Grid item xs={12} sm={6} md={3} {...props.gridProps}>
		<KPIBaseCard boxProps={props.boxProps}>
			{props.header && (
				<Stack direction={"column"}>
					{props.header}
					{props.content}
					{props.footer}
				</Stack>
			)}
			{props.children}
		</KPIBaseCard>
	</Grid>
);

export default KPICard;
