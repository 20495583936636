import { useMutation, useQuery } from "@tanstack/react-query";
import { alertEventService } from "../../services/alertEvent.service";

const { getAll, putAlertEvent } = alertEventService;
export const useGetAlertAndEvents = (userId: string) => {
	return useQuery({
		queryKey: ["sims-alert-events-get"],
		queryFn: () => getAll(userId),
	});
};

export const useDismissAll = (userId: string) => {
	return useMutation({
		mutationFn: (alertId?: string) => putAlertEvent(userId, alertId),
	});
};
