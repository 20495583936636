import React, {
	createContext,
	useContext,
	useState,
	FC,
	ReactNode,
} from "react";

type ModalContextType = {
	openModal: () => void;
	closeModal: () => void;
	isOpen: boolean | undefined;
};

const OrderModalContext = createContext<ModalContextType | null>(null);

export const useOrderModal = () => {
	const context = useContext(OrderModalContext);
	if (!context) {
		throw new Error("useOrderModal must be used within an OrderModalProvider");
	}
	return context;
};

export const OrderModalProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);

	const openModal = () => setIsOpen(true);
	const closeModal = () => {
		setIsOpen(false);
	};

	return (
		<OrderModalContext.Provider value={{ openModal, closeModal, isOpen }}>
			{children}
		</OrderModalContext.Provider>
	);
};
