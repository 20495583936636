// Basic libraries
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from "react";
// Material UI Components
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CircularProgress from "@mui/material/CircularProgress";
// Specific Components
import UserForm from "./UserForm";
import RoleForm from "./RoleForm";
import AssignOuForm from "./AssignOuForm";
// Models
import { ParentOrganization } from "@/domain/organization.interface";
// Services and Hooks
import { organizationService } from "../../services/organization.service";
import { useGetActiveRolesByAccount } from "../../hooks/useRoles";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser } from "@/domain/user.interface";
import PreviewAssignedOuModal from "./PreviewAssignedOU";
import userSchema, { UserSchema } from "./useSchema";
import { useGetProgramsByAccount } from "../../hooks/usePrograms";
import GenericAlertModal from "../../components/modals/GenericAlertModal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function SimsTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ py: 3, px: 1 }} className="space-y-4">
					{children}
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

type Props = {
	isEditMode: boolean;
	user?: IUser;
	onSubmit: (data: UserSchema) => void;
	showConfirmationState: [boolean, Dispatch<SetStateAction<boolean>>];
	generalErrorMessageState: [string, Dispatch<SetStateAction<string>>];
};

const UserInformation: FC<Props> = (props) => {
	const { isEditMode } = props;
	const {
		control,
		formState: { errors, isDirty },
		getValues,
		handleSubmit,
		setValue,
	} = useForm({
		resolver: yupResolver(userSchema),
		defaultValues: {
			firstName: props?.user?.firstName,
			lastName: props?.user?.lastName,
			email: props?.user?.email,
			phoneNumber: props?.user?.phoneNumber,
			npi: props?.user?.npi,
			status: props?.user?.status,
			// @ts-expect-error - undefined > 0 is false
			roleId: props?.user?.roles?.length > 0 ? props?.user?.roles[0] : "",
			programOrganizationsGroupList:
				props?.user?.programs?.map((p) => ({
					programId: p.programId,
					organizationIdsList: p.orgUnitId.map((o) => o.orgUnitId),
				})) || [],
		},
	});

	const showPreviewState = useState(false);
	const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
	const [areParentOUsLoading, setAreParentOUsLoading] = useState(false);
	const [parentLevelName, setParentLevelName] = useState<string>("");
	const [parentOrganizationsList, setParentOrganizationsList] = useState<
		Array<ParentOrganization>
	>([]);
	const { data: rolesList, isPending: areRolesLoading } =
		useGetActiveRolesByAccount(process.env.REACT_APP_ACCOUNT as string);
	const { findOrganizationsByRoleId } = organizationService;
	const { data: programsList } = useGetProgramsByAccount(
		process.env.REACT_APP_ACCOUNT as string,
	);
	const [showConfirmation, setShowConfirmation] = props.showConfirmationState;
	const [showSelectRoleModal, setShowSelectRoleModal] = useState(false);

	const selectedRoleId = useWatch({
		control: control,
		name: "roleId",
	});

	const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
		if (tabIndex === 2 && !selectedRoleId) {
			setShowSelectRoleModal(true);
			return;
		}
		setSelectedTabIndex(tabIndex);
	};

	useEffect(() => {
		if (isDirty) {
			setValue("programOrganizationsGroupList", []);
		}
		const level = rolesList?.find((role) => role.id === selectedRoleId)?.level;
		if (!level || level === 0) {
			setParentLevelName("NO_PARENT_LEVEL_NAME_FOUND");
			setParentOrganizationsList([]);
		} else {
			setAreParentOUsLoading(true);
			findOrganizationsByRoleId(
				process.env.REACT_APP_ACCOUNT as string,
				selectedRoleId,
			)
				.then(({ levelName, organizations }) => {
					setParentLevelName(levelName);
					setParentOrganizationsList(organizations);
				})
				.finally(() => {
					setAreParentOUsLoading(false);
				});
		}
	}, [selectedRoleId, rolesList]);

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			props.generalErrorMessageState[1]("Please fix the errors in the form.");
		}
	}, [errors]);
	return (
		<>
			<Box sx={{ width: "100%" }}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						value={selectedTabIndex}
						onChange={handleTabChange}
						aria-label="basic tabs example"
					>
						<Tab label="User" {...a11yProps(0)} />
						<Tab label="User roles" {...a11yProps(1)} />
						<Tab label="Assign OU" {...a11yProps(2)} />
					</Tabs>
				</Box>
				<SimsTabPanel value={selectedTabIndex} index={0}>
					<UserForm control={control} errors={errors} isEditMode={isEditMode} />
				</SimsTabPanel>
				<SimsTabPanel value={selectedTabIndex} index={1}>
					{areRolesLoading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="240px"
						>
							<CircularProgress />
						</Box>
					) : (
						<RoleForm control={control} rolesList={rolesList || []} />
					)}
				</SimsTabPanel>
				<SimsTabPanel value={selectedTabIndex} index={2}>
					{areParentOUsLoading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="240px"
						>
							<CircularProgress />
						</Box>
					) : (
						<AssignOuForm
							control={control}
							getValues={getValues}
							programsList={programsList || []}
							parentLevelName={parentLevelName}
							parentOrganizationsList={parentOrganizationsList}
							showPreviewState={showPreviewState}
						/>
					)}
				</SimsTabPanel>
				{showPreviewState[0] && (
					<PreviewAssignedOuModal
						open={true}
						onClose={() => {
							showPreviewState[1](false);
						}}
						allPrograms={programsList || []}
						programOrganizationGroups={
							getValues().programOrganizationsGroupList?.map((group) => ({
								programId: group.programId,
								organizationIdsList: group.organizationIdsList || [],
							})) || []
						}
					/>
				)}
				{showConfirmation && (
					<PreviewAssignedOuModal
						open={showConfirmation}
						onClose={() => {
							setShowConfirmation(false);
						}}
						allPrograms={programsList || []}
						programOrganizationGroups={
							getValues().programOrganizationsGroupList?.map((group) => ({
								programId: group.programId,
								organizationIdsList: group.organizationIdsList || [],
							})) || []
						}
						confirmAction={handleSubmit(props.onSubmit)}
					/>
				)}
			</Box>

			{showSelectRoleModal ? (
				<GenericAlertModal
					title="Select a role"
					description="You have to select a role first."
					submitText="Confirm"
					icon={<CancelOutlinedIcon />}
					onClick={() => {
						setShowSelectRoleModal(false);
						setSelectedTabIndex(1);
					}}
				/>
			) : null}
		</>
	);
};

export default UserInformation;
