import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import BasicDatepicker from "../../BasicDatepicker";
import dayjs, { Dayjs } from "dayjs";
import { headCellsConfig } from "../../../utils/tableConfig";
import DataTable, { DataTableCell, HeadCell } from "../../DataTable";
import { ReactNode } from "react";
import { useGetAuditlog } from "../../../hooks/useAuditlog";
import { useGetSetting } from "../../../hooks/useSetting";
import GenericAlertModal from "../GenericAlertModal";

const AuditLogDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));
const nameSettingMinusDays = "daysAuditlog";
const createData = (
	id: number,
	timestamp: ReactNode,
	user: ReactNode,
	eventDescription: ReactNode,
): DataTableCell => {
	return {
		id,
		timestamp,
		user,
		eventDescription,
	};
};

interface AuditLogModalProps {
	open: boolean;
	module: string;
	onClose: () => void;
}

export default function AuditLogModal({
	open,
	module,
	onClose,
}: AuditLogModalProps) {
	const { data: dataSetting } = useGetSetting();
	const invalidMessage =
		"Invalid date range selected. Please select a valid range.";
	const [openInvalidRange, setOpenInvalidRange] = React.useState(false);
	const defaultMinusDays = Number(
		dataSetting?.find((setting) => setting.name === nameSettingMinusDays)
			?.default ?? 1,
	);
	const [dateRange, setDateRange] = React.useState({
		from: dayjs().subtract(defaultMinusDays, "day").startOf("day"),
		to: dayjs().endOf("day"),
	});
	const [lastDocId, setLastDocId] = React.useState<string | undefined>(
		undefined,
	);
	const [pageNumber, setPageNumber] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const { data: dataAudit, refetch: refecthGetAuditLogs } = useGetAuditlog(
		module,
		dateRange.from.toISOString(),
		dateRange.to.toISOString(),
		pageNumber + 1,
		pageSize,
		lastDocId,
	);
	const prevLastDocIdRef = React.useRef(lastDocId);
	const prevPageNumberRef = React.useRef(pageNumber);
	React.useEffect(() => {
		if (dateRange.to.endOf("day").diff(dateRange.from.startOf("day")) > 0) {
			refecthGetAuditLogs();
		} else {
			setOpenInvalidRange(true);
		}
		prevPageNumberRef.current = pageNumber;
		prevLastDocIdRef.current = lastDocId;
	}, [pageNumber, pageSize, dateRange.from, dateRange.to]);

	React.useEffect(() => {
		if (dataAudit) {
			if (pageNumber > prevPageNumberRef.current) {
				setLastDocId(dataAudit.lastDocId);
			} else if (pageNumber < prevPageNumberRef.current) {
				setLastDocId(prevLastDocIdRef.current);
			}
		}
	}, [dataAudit]);
	const headCells: HeadCell[] = headCellsConfig.beneficiaryAuditLog;

	const rows = Array.isArray(dataAudit?.data)
		? dataAudit?.data.map((row, index) => {
				return createData(index, row.timestamp, row.user, row.eventDescription);
			})
		: [];

	const onRefresh = () => {
		setDateRange({
			from: dayjs().subtract(defaultMinusDays, "day").startOf("day"),
			to: dayjs().endOf("day"),
		});
	};

	return (
		<>
			<React.Fragment>
				<AuditLogDialog
					onClose={onClose}
					aria-labelledby="register-beneficiary-dialog-title"
					sx={{
						"& .MuiDialog-paper": {
							width: "100%",
							maxWidth: 920,
						},
					}}
					open={open}
				>
					<DialogTitle
						sx={{ m: 0, p: 2 }}
						id="register-beneficiary-dialog-title"
						variant="headlineSmall"
						color={(theme) => theme.palette.surface.light}
						bgcolor={(theme) => theme.palette.primary.dark}
					>
						AuditLog
					</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.surface.light,
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent
						sx={{
							gap: "1rem",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: "1rem",
							}}
						>
							<Box sx={{ display: "flex", gap: 1 }}>
								<BasicDatepicker
									label="Date from"
									onChange={(value) => {
										setDateRange((prev) => ({
											...prev,
											from: value?.startOf("day") as Dayjs,
										}));
									}}
									value={dateRange.from}
									sx={{ maxWidth: 220 }}
								/>
								<BasicDatepicker
									label="Date to"
									onChange={(value) => {
										setDateRange((prev) => ({
											...prev,
											to: value?.endOf("day") as Dayjs,
										}));
									}}
									value={dateRange.to}
									sx={{ maxWidth: 220 }}
								/>
								<Button
									variant="contained"
									color="primary"
									onClick={onRefresh}
									sx={{ width: 120 }}
								>
									Refresh
								</Button>
							</Box>

							<DataTable
								data={rows as DataTableCell[]}
								rowsPerPage={pageSize}
								order="asc"
								orderBy="none"
								page={pageNumber}
								headCells={headCells}
								total={dataAudit?.total}
								messageEmptyData="No events found for the selected date range."
								onChangePage={(event, newPage) => {
									setPageNumber(newPage);
								}}
								onChangeSize={(event) => {
									setPageSize(Number(event.target.value));
								}}
							/>
						</Box>
					</DialogContent>
				</AuditLogDialog>
			</React.Fragment>
			{openInvalidRange && (
				<GenericAlertModal
					title="Warning Selection"
					description={invalidMessage}
					submitText="Accept"
					onClick={() => {
						setOpenInvalidRange(false);
					}}
				/>
			)}
		</>
	);
}
