import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";

const Unauthorized: FC = () => {
	return (
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="center"
			width={"100%"}
			height={"100vh"}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<img
					className="w-[269.1px] relative object-cover"
					alt=""
					src={"/assets/unauthorized@2x.png"}
				/>
				<Typography variant="h4">Authorization required</Typography>
				<Typography variant="body1">
					You do not have access to this page. Please contact your system
					administrator.
				</Typography>

				<Button
					variant="contained"
					onClick={() => {
						location.replace("/");
					}}
				>
					GO TO HOME
				</Button>
			</Stack>
		</Stack>
	);
};

export default Unauthorized;
