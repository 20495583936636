import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useIANATimeZoneSetting } from "../useSetting";

dayjs.extend(utc);
dayjs.extend(timezone);

const useDate = () => {
	const ianaTimeZone = useIANATimeZoneSetting();
	const [defaultTimeZone, setDefaultTimeZone] =
		useState<string>("America/Lima");

	useEffect(() => {
		if (ianaTimeZone) {
			dayjs.tz.setDefault(ianaTimeZone);
			setDefaultTimeZone(ianaTimeZone);
		}
	}, [ianaTimeZone]);

	const getCurrentDefaultTimeZone = (): string => defaultTimeZone;

	return {
		dayjs,
		ianaTimeZone,
		getCurrentDefaultTimeZone,
	};
};

export default useDate;
