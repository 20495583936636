import { axios } from "../utils/axios.create";
import { IAlertEvent } from "@/domain/alertEvent.interface";

const getAll = async (userId: string): Promise<IAlertEvent[]> => {
	return axios
		.get(`/alert-events-list/${userId}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const putAlertEvent = async (
	providerId: string,
	alertId?: string,
): Promise<{
	acknowledged: boolean;
	modifiedCount: number;
	upsertedId: number | undefined;
	upsertedCount: number;
	matchedCount: number;
}> => {
	const url = `/alert-events/${providerId}${alertId ? `?alertId=${alertId}` : ""}`;

	return axios
		.put(url)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

export const alertEventService = {
	getAll,
	putAlertEvent,
};
