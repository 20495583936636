import Dialog from "@mui/material/Dialog";
import styled from "@mui/material/styles/styled";

// Remove the theme function
export const CustomDialog = styled(Dialog)`
	& .MuiDialogContent-root {
		padding: 16px; /* or any other default spacing */
	}
	& .MuiDialogActions-root {
		padding: 8px; /* or any other default spacing */
	}
	& .MuiDialogTitle-root {
		background-color: ${({ theme }) => theme.palette.primary.main};
	}
`;
