import { beneficiaryService } from "../../services/beneficiary.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	BeneficiaryPayload,
	ConsentPayload,
	Phone,
	CardPayload,
	CardPinPayload,
} from "../../domain/beneficiary.interface";

const {
	findAll,
	findById,
	create,
	update,
	updateReconfirm,
	getConsentForms,
	patchConsentForm,
	getConsentFormUrl,
	findPrograms,
	findProviders,
	findProviderSites,
	disenroll,
	reenroll,
	findByName,
	getCurrentWeekUdtStatus,
	findListVisits,
	getEnrollmentPeriods,
	findIncentiveHistory,
	deleteCard,
	getCards,
	postAddCard,
	postSetPin,
} = beneficiaryService;

export const useGetBeneficiaries = ({
	providerSite = "",
	programId = "",
	providerId = "",
	userId = "",
	status = ["ACTIVE"],
	search = "",
	pageNumber,
	pageSize,
}: {
	providerSite?: string;
	programId?: string;
	providerId?: string;
	userId?: string;
	status?: string[];
	search?: string;
	pageNumber: number;
	pageSize: number;
}) => {
	const beneficiaries = useQuery({
		queryKey: ["sims-beneficiaries"],
		queryFn: () =>
			findAll({
				providerSite,
				programId,
				providerId,
				userId,
				status,
				search,
				pageNumber,
				pageSize,
			}),
		staleTime: 1000 * 60 * 60,
		enabled: false,
	});

	return beneficiaries;
};

export const useGetBeneficiaryById = (
	beneficiaryId: string,
	enrollmentId?: string,
) => {
	return useQuery({
		queryKey: ["sims-beneficiary", beneficiaryId],
		queryFn: () => findById(beneficiaryId, enrollmentId),
		staleTime: 1000 * 60 * 60,
	});
};

export const useCreateBeneficiary = () => {
	return useMutation({
		mutationFn: (data: BeneficiaryPayload) => create(data),
	});
};

export const useUpdateBeneficiary = ({
	beneficiaryId,
	enrollmentId,
}: {
	beneficiaryId: string;
	enrollmentId: string;
}) => {
	return useMutation({
		mutationFn: (data: Partial<BeneficiaryPayload>) =>
			update(data, beneficiaryId, enrollmentId),
	});
};

export const useUpdateReconfirmBeneficiary = () => {
	return useMutation({
		mutationFn: (data: {
			userId: string;
			beneficiaryId: string;
			enrollmentId: string;
		}) => updateReconfirm(data.userId, data.beneficiaryId, data.enrollmentId),
	});
};

export const useGetConsentForms = (beneficiaryId: string) => {
	return useQuery({
		queryKey: ["sims-beneficiaries-consent-forms"],
		queryFn: () => getConsentForms(beneficiaryId),
	});
};

export const usePatchConsentForm = (
	beneficiaryId: string,
	enrollmentId: string,
) => {
	return useMutation({
		mutationFn: (data: ConsentPayload) =>
			patchConsentForm(data, beneficiaryId, enrollmentId),
	});
};

export const useGetConsentFormUrl = (s3Url: string) => {
	return useQuery({
		queryKey: ["sims-beneficiaries-consent-form-url"],
		queryFn: () => getConsentFormUrl(s3Url),
		enabled: !!s3Url,
	});
};

export const useGetProgramsByIds = (programIds: string[]) => {
	return useQuery({
		queryKey: ["sims-beneficiaries-programs"],
		queryFn: () => findPrograms(programIds),
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});
};

export const useGetProviderSitesByOUId = ({
	ouIds,
	userId,
	createTree,
	queryKey,
}: {
	ouIds: string[];
	userId: string;
	createTree?: boolean;
	queryKey?: string;
}) => {
	return useQuery({
		queryKey: [queryKey || "sims-beneficiaries-provider-sites"],
		queryFn: () => findProviderSites(ouIds, userId, createTree || false),
		enabled: ouIds.length > 0,
	});
};

export const useGetProvidersByProgramId = (
	programId: string[],
	providerSiteId: string[],
	includeAll?: boolean,
) => {
	return useQuery({
		queryKey: ["sims-beneficiaries-providers"],
		queryFn: () =>
			findProviders(programId, providerSiteId, includeAll || false),
		staleTime: 1000 * 60 * 60 * 24 * 7,
		enabled: programId.length > 0 && providerSiteId.length > 0,
	});
};

export const useDisenrollBeneficiary = ({
	beneficiaryId,
	enrollmentId,
}: {
	beneficiaryId: string;
	enrollmentId: string;
}) => {
	return useMutation({
		mutationFn: (data: { reasons: string; notes: string; userId: string }) =>
			disenroll(data, beneficiaryId, enrollmentId),
	});
};

export const useReenrollBeneficiary = ({
	beneficiaryId,
	enrollmentId,
}: {
	beneficiaryId: string;
	enrollmentId: string;
}) => {
	return useMutation({
		mutationFn: (data: { userId: string }) =>
			reenroll(data, beneficiaryId, enrollmentId),
	});
};

export const useGetSearchBeneficiary = (searchName: string, userId: string) => {
	return useQuery({
		queryKey: ["get-beneficiary-searched"],
		queryFn: () => findByName(searchName, userId),
	});
};

export const useGetCurrentWeekUdtStatus = (enrollmentId: string) => {
	return useQuery({
		queryKey: ["get-current-week-udt-status"],
		queryFn: () => getCurrentWeekUdtStatus(enrollmentId),
	});
};

export const useGetListVisits = (enrollmentId: string, rangeWeeks: string) => {
	return useQuery({
		queryKey: ["get-list-visits", enrollmentId],
		queryFn: () =>
			findListVisits(enrollmentId, rangeWeeks).then((data) =>
				data?.sort((a, b) => a.visit - b.visit),
			),
	});
};

export const usePatchParticipant = (beneficiaryId: string) => {
	return useMutation({
		mutationFn: (data: { email?: string; phone?: Phone[]; userId: string }) =>
			beneficiaryService.patchBeneficiary(beneficiaryId, data),
	});
};

export const useGetBeneficiaryEnrollmentPeriods = (beneficiaryId: string) => {
	return useQuery({
		queryKey: ["get-beneficiary-enrollment-periods", beneficiaryId],
		queryFn: () => getEnrollmentPeriods(beneficiaryId),
	});
};

export const useGetListIncentives = ({
	enrollmentId,
	pageNumber,
	pageSize,
}: {
	enrollmentId: string;
	pageNumber: number;
	pageSize: number;
}) => {
	const query = useQuery({
		queryKey: ["get-benefiary-get-list-incentives", enrollmentId],
		queryFn: () => findIncentiveHistory(enrollmentId, pageNumber, pageSize),
	});

	return {
		incentives: query.data,
		isLoading: query.isLoading,
		refetchIncentives: query.refetch,
	};
};

export const useDeleteCard = (beneficiaryId: string) => {
	return useMutation({
		mutationFn: async (data: { cardId: string; providerId: string }) =>
			await deleteCard({
				beneficiaryId,
				cardId: data.cardId,
				providerId: data.providerId,
			}),
	});
};

export const useGetCards = (beneficiaryId: string) => {
	const query = useQuery({
		queryKey: ["sims-beneficiary-get-cards"],
		queryFn: () => getCards(beneficiaryId),
		staleTime: 1000 * 60 * 60,
		enabled: false,
	});

	return {
		cards: query.data,
		areCardsLoading: query.isLoading,
		refetchCards: query.refetch,
	};
};

export const usePostAddCard = (beneficiaryId: string) => {
	return useMutation({
		mutationFn: (data: CardPayload) => postAddCard(beneficiaryId, data),
	});
};

export const usePostSetPin = () => {
	const query = useMutation({
		mutationFn: (data: CardPinPayload & { accountId: string }) =>
			postSetPin(data.accountId, data),
	});

	return query;
};
