import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { useUserStore } from "../../stores/user.store";
import { useEffect } from "react";
import Spinner from "../../components/Spinner/index";
import { useNavigate } from "react-router";

const Callback = () => {
	const { userInfo } = useUserStore();
	const navigate = useNavigate();
	const createAuditlogMutation = useCreateAuditlog();

	const postAuditlog = async ({
		action,
		actionDetail,
		actionCode,
	}: {
		action: string;
		actionDetail: string;
		actionCode: string;
	}) => {
		await createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: "Authentication",
			action,
			detail: actionDetail,
			actionCode,
			option: "Sign in",
			createdBy: userInfo?.id || "",
		});
	};

	useEffect(() => {
		postAuditlog({
			action: "Sign in (successfully)",
			actionDetail: "User signed in successfully",
			actionCode: "WEB_SIGN_IN_OK",
		});

		navigate("/");
	}, [userInfo]);

	return (
		<div className="flex w-screen h-screen items-center justify-center">
			<Spinner />
		</div>
	);
};

export default Callback;
