import {
	BeneficiaryPayload,
	BeneficiaryResponse,
} from "@/domain/beneficiary.interface";
import dayjs from "dayjs";

export const compareFields = (
	data: Partial<BeneficiaryPayload>,
	beneficiary: BeneficiaryResponse,
	cleanedPhoneNumber: string,
) => {
	const updatedFields = [];

	if (data.firstName !== beneficiary?.beneficiary.firstName)
		updatedFields.push("First Name");
	if (data.lastName !== beneficiary?.beneficiary.lastName)
		updatedFields.push("Last Name");
	if (
		dayjs(data.dateOfBirth).format("YYYY-MM-DD") !==
		dayjs(beneficiary?.beneficiary.dateOfBirth).format("YYYY-MM-DD")
	)
		updatedFields.push("Date of Birth");
	if (data.email !== beneficiary?.beneficiary.email)
		updatedFields.push("Email");
	if (cleanedPhoneNumber !== beneficiary?.beneficiary.phone[0].number)
		updatedFields.push("Phone");
	if (
		data.address?.addressLine1 !== beneficiary?.beneficiary.address.addressLine1
	)
		updatedFields.push("Address Line 1");
	if (
		data.address?.addressLine2 !== beneficiary?.beneficiary.address.addressLine2
	)
		updatedFields.push("Address Line 2");
	if (data.address?.city !== beneficiary?.beneficiary.address.city)
		updatedFields.push("City");
	if (data.address?.state !== beneficiary?.beneficiary.address.state)
		updatedFields.push("State");
	if (data.address?.zipCode !== beneficiary?.beneficiary.address.zipCode)
		updatedFields.push("ZIP Code");
	if (
		data.sims?.clientIdNumber !== beneficiary?.beneficiary.sims.clientIdNumber
	)
		updatedFields.push("CIN");

	return updatedFields;
};
