export enum VISIT_STATUS {
	REGISTERED = "REGISTERED",
	MISSED = "MISSED",
	DISABLED = "DISABLED",
	ENABLED_ENTER = "ENABLED_ENTER",
	ENABLED_TIMER = "ENABLED_TIMER",
	ENABLED = "ENABLED",
}

export enum RESULT_STATUS {
	POSITIVE = "POSITIVE",
	NEGATIVE = "NEGATIVE",
	EXCUSED_ABSENCE = "EXCUSED_ABSENCE",
	UNEXCUSED_ABSENCE = "UNEXCUSED_ABSENCE",
}

export enum BENEFICIARY_STATUS {
	ACTIVE = "ACTIVE",
	COMPLETED = "COMPLETED",
	DISENROLLED = "DISENROLLED",
}

export enum DaysOfWeekEnum {
	SUNDAY = "Sunday",
	MONDAY = "Monday",
	TUESDAY = "Tuesday",
	WEDNESDAY = "Wednesday",
	THURSDAY = "Thursday",
	FRIDAY = "Friday",
	SATURDAY = "Saturday",
}

export enum MonthsEnum {
	JANUARY = "January",
	FEBRUARY = "February",
	MARCH = "March",
	APRIL = "April",
	MAY = "May",
	JUNE = "June",
	JULY = "July",
	AUGUST = "August",
	SEPTEMBER = "September",
	OCTOBER = "October",
	NOVEMBER = "November",
	DECEMBER = "December",
}

export enum DELIVERY_METHODS {
	EMAIL = "EMAIL",
	PHONE = "PHONE",
	LINK = "LINK",
}

export enum CARD_STATUS {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
}

export enum CARD_TYPE {
	VIRTUAL = "VIRTUAL",
	PHYSICAL = "PHYSICAL",
}
