import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { organizationService } from "../../services/organization.service";
import { OrganizationResponse } from "../../domain/organization.interface";

const {
	findOrganizationsByRoleId,
	findOrganizationsByAccountProgramAndParentOrg,
} = organizationService;

export const useGetOrganizationsByRoleId = (account: string, rolId: string) => {
	return useQuery({
		queryKey: ["sims-account-phips"],
		queryFn: () => findOrganizationsByRoleId(account, rolId),
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});
};

export const useGetOrgsByAccountAndParentOrgId = (
	account: string,
	orgId: string,
	programId: string,
) => {
	return useQuery({
		queryKey: ["sims-account-orgs"],
		queryFn: () =>
			findOrganizationsByAccountProgramAndParentOrg(account, orgId, programId),
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});
};

export const useGetOrganizationList = (
	account: string,
	initialData = [],
): UseQueryResult<OrganizationResponse[]> => {
	const userQuery = useQuery({
		queryKey: ["get-organizaation-list"],
		queryFn: () => organizationService.findAll({ account }),
		initialData,
		staleTime: 0,
	});

	return userQuery;
};
