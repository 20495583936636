import React, { ReactNode } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { XlsGeneratorContext } from "./XlsGeneratorContext";

interface HeaderMapping {
	[key: string]: string;
}

interface TransformedItem {
	// TODO: Define the type for the transformed item or remove TODO if decided to keep it
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

const XlsGeneratorProvider = ({ children }: { children: ReactNode }) => {
	// TODO: Define the type for the array parameter or remove TODO if decided to keep it
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const transformArray = (array: any[], headerMapping: HeaderMapping) => {
		return array.map((item) => {
			const newItem: TransformedItem = {};
			Object.keys(headerMapping).forEach((key) => {
				const mappedKey = headerMapping[key];
				if (mappedKey && mappedKey in item) {
					newItem[key] = item[mappedKey];
				}
			});
			return newItem;
		});
	};

	const exportToExcel = (
		headerMapping: HeaderMapping,
		// TODO: Use the defined type of the jsonData parameter
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		jsonData: any[],
		fileName: string,
	) => {
		const transformedData = transformArray(jsonData, headerMapping);

		const worksheet = XLSX.utils.json_to_sheet(transformedData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});
		const data = new Blob([excelBuffer], { type: "application/octet-stream" });
		saveAs(data, `${fileName}.xlsx`);
	};

	return (
		<XlsGeneratorContext.Provider value={{ exportToExcel }}>
			{children}
		</XlsGeneratorContext.Provider>
	);
};

export default XlsGeneratorProvider;
