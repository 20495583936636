import { AppTheme } from "@/utils/theme";
import { SxProps } from "@mui/material/styles";

export const buttonExport = (theme: AppTheme): SxProps => ({
	borderRadius: "8px",
	border: `1px solid ${theme.palette.outline.dark}`,
	color: theme.palette.textMain.dark2,
	fontWeight: 700,
	textTransform: "none",
	textAlign: "center",
});

export const searchOffIcon = (theme: AppTheme): SxProps => ({
	borderRadius: "8px",
	border: `1px solid ${theme.palette.outline.dark}`,
	width: "3.5rem",
	padding: "16px",
});

export const runReportButton = (theme: AppTheme): SxProps => ({
	borderRadius: "8px",
	border: `1px solid ${theme.palette.outline.dark}`,
	fontWeight: 700,
	textTransform: "none",
	textAlign: "center",
	padding: "1rem 1.5rem",
});
