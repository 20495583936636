export enum EVENT_ENUM {
	EVE_31DAYS_ABS = "EVE_31DAYS_ABS",
	EVE_CM_ELIG = "EVE_CM_ELIG",
	EVE_MISSED_ENTRY = "EVE_MISSED_ENTRY",
	EVE_BEN_COMPL = "EVE_BEN_COMPL",
	EVE_BEN_ENROLL = "EVE_BEN_ENROLL",
	EVE_BEN_DISENROLL = "EVE_BEN_DISENROLL",
	// backend alert types
	EVE_UDT_RESULT_UPD = "EVE_UDT_RESULT_UPD",
	EVE_UDT_NOTES_UPD = "EVE_UDT_NOTES_UPD",
	EVE_MOVE_TO = "EVE_MOVE_TO",
	EVE_MOVE_FROM = "EVE_MOVE_FROM",
	EVE_PROV_ACCOUNT = "EVE_PROV_ACCOUNT",
	EVE_USER_ENROLL = "EVE_USER_ENROLL",
}
