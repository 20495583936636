import { IAlertEvent } from "@/domain/alertEvent.interface";
import { BeneficiaryResponse } from "@/domain/beneficiary.interface";
import {
	BENEFICIARY_STATUS,
	RESULT_STATUS,
} from "../../domain/beneficiary.enum";
import dayjsObject from "dayjs";

type EventCategory = "ENROLLMENT";

const createEvent = (
	beneficiary: BeneficiaryResponse,
	category: EventCategory,
	events: IAlertEvent[],
	dayjs: typeof dayjsObject,
	narrative: (beneficiary: BeneficiaryResponse) => string,
	actionCode?: string,
	fullName?: string,
) => {
	events.push({
		_id: "",
		type: "EVENT",
		narrative: narrative(beneficiary),
		isDeleted: false,
		dateOfProcess: dayjs().toDate(),
		dismissed: false,
		account: beneficiary.beneficiary.account,
		programId: beneficiary.lastEnrollment.programId,
		orgUnitId: "",
		beneficiaryId: beneficiary._id,
		providerId: beneficiary.lastEnrollment.providerId,
		enrollmentId: beneficiary.lastEnrollment._id,
		category: category,
		actionCode,
		fullName,
		beneficiary: beneficiary.beneficiary,
		program: beneficiary.lastEnrollment.program,
	});
};

const addEventsBasedOnCondition = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	condition: (beneficiary: BeneficiaryResponse) => boolean,
	category: EventCategory,
	dayjs: typeof dayjsObject,
	narrative: (beneficiary: BeneficiaryResponse) => string,
	actionCode?: string,
) => {
	for (const beneficiary of beneficiaryList) {
		if (condition(beneficiary)) {
			createEvent(
				beneficiary,
				category,
				events,
				dayjs,
				narrative,
				actionCode,
				`${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName}`,
			);
		}
	}
};

export const addBeneficiariesEnrollEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) =>
			dayjs(beneficiary.lastEnrollment.startDate).isSame(dayjs(), "day"),
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`Beneficiary ${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} has been enrolled to the ${beneficiary.lastEnrollment.program.name}!`,
		actionCode,
	);
};

export const addBeneficiariesDisenrollEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) =>
			dayjs(beneficiary.lastEnrollment.endDate).isSame(dayjs(), "day") &&
			beneficiary.lastEnrollment.status === BENEFICIARY_STATUS.DISENROLLED,
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`Beneficiary ${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} has been disenrolled from the ${beneficiary.lastEnrollment.program.name}!`,
		actionCode,
	);
};

export const addBeneficiariesMissedEntryEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) =>
			beneficiary.lastEnrollment.udtResults.some(
				(visit) => !visit.result && visit.isMissed,
			),
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} has missed entries.`,
		actionCode,
	);
};

export const addBeneficiariesCompletedEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) =>
			dayjs(beneficiary.lastEnrollment.endDate).isSame(dayjs(), "day") &&
			beneficiary.lastEnrollment.status === BENEFICIARY_STATUS.COMPLETED,
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`Beneficiary ${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} has completed the ${beneficiary.lastEnrollment.program.name} program!`,
		actionCode,
	);
};

export const addBeneficiariesCertificateAvailableEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) => beneficiary.lastEnrollment.certificate_available,
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`Beneficiary ${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} has completed the ${beneficiary.lastEnrollment.program.name} program and the certificate of completion is available!`,
		actionCode,
	);
};

export const addBeneficiariesReconfirmationEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) => {
			const cmElegibilityDate =
				beneficiary.lastEnrollment.eligibilityDate ||
				beneficiary.lastEnrollment.createdAt;
			return (
				beneficiary.lastEnrollment.status === BENEFICIARY_STATUS.ACTIVE &&
				dayjs().diff(dayjs(cmElegibilityDate), "days") > 30
			);
		},
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} requires a CM eligibility reconfirmation.`,
		actionCode,
	);
};

export const addBeneficiaries31DaysAbsEvents = (
	events: IAlertEvent[],
	beneficiaryList: BeneficiaryResponse[],
	dayjs: typeof dayjsObject,
	actionCode?: string,
) => {
	addEventsBasedOnCondition(
		events,
		beneficiaryList,
		(beneficiary) => {
			const pne = [
				RESULT_STATUS.POSITIVE,
				RESULT_STATUS.NEGATIVE,
				RESULT_STATUS.EXCUSED_ABSENCE,
			];
			const pneArraySorted = beneficiary.lastEnrollment.udtResults
				.filter((v) => pne.includes(v.result))
				.sort((a, b) => b.visitNumber - a.visitNumber);
			const lastVisitPNE = pneArraySorted.length && pneArraySorted[0];
			return (
				beneficiary.lastEnrollment.status === BENEFICIARY_STATUS.ACTIVE &&
				dayjs().diff(dayjs(lastVisitPNE.visitDate), "days") > 30
			);
		},
		"ENROLLMENT",
		dayjs,
		(beneficiary) =>
			`${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.lastName} should be disenroll from the ${beneficiary.lastEnrollment.program.name} program.`,
		actionCode,
	);
};
