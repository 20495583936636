import { IProgram } from "@/domain/program.interface";
import { axios } from "../utils/axios.create";

const findProgramsByAccount = async (account: string): Promise<IProgram[]> => {
	return axios
		.get(`/account/${account}/programs`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const patchProgramSetting = async (
	programId: string,
	todayDateAndTime: Date | null,
): Promise<{ message: string }> => {
	return axios
		.patch(`/program/${programId}`, {
			todayDateAndTime: todayDateAndTime,
		})
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

export const programService = {
	findProgramsByAccount,
	patchProgramSetting,
};
