import { HeadCell } from "../components/DataTable/dataTable.interface";

export const headCellsConfig = {
	default: [
		{
			id: "fullname",
			isSortable: true,
			disablePadding: true,
			label: "Full name",
			width: "160px",
			align: "left",
		},
		{
			id: "provider",
			isSortable: true,
			disablePadding: false,
			label: "Provider",
			align: "left",
		},
		{
			id: "cmStartDate",
			isSortable: true,
			disablePadding: false,
			label: "CM start date",
			width: "160px",
			align: "left",
		},
		{
			id: "cmEndDate",
			isSortable: true,
			disablePadding: false,
			label: "CM end date",
			width: "160px",
			align: "left",
		},
		{
			id: "status",
			isSortable: true,
			disablePadding: false,
			label: "Status",
			width: "140px",
			align: "center",
		},
		{
			id: "incEarned",
			isSortable: true,
			disablePadding: false,
			label: "Inc. earned",
			width: "140px",
			align: "right",
		},
		{
			id: "incReceived",
			isSortable: true,
			disablePadding: false,
			label: "Inc. received",
			width: "140px",
			align: "right",
		},
		{
			id: "action",
			isSortable: false,
			disablePadding: false,
			label: "Action",
			width: "140px",
			align: "right",
		},
	] as HeadCell[],
	user: [
		{
			id: "name",
			isSortable: true,
			disablePadding: true,
			label: "Name",
			width: "160px",
			align: "left",
		},
		{
			id: "lastname",
			isSortable: true,
			disablePadding: true,
			label: "Last name",
			width: "160px",
			align: "left",
		},
		{
			id: "action",
			isSortable: false,
			disablePadding: false,
			label: "Action",
			width: "140px",
			align: "right",
		},
	] as HeadCell[],
	consentFormsListView: [
		{
			id: "program",
			isSortable: true,
			disablePadding: true,
			label: "Program",
			width: "140px",
			align: "left",
		},
		{
			id: "cmStartDate",
			isSortable: true,
			disablePadding: false,
			label: "CM Start date",
			width: "140px",
			align: "left",
		},
		{
			id: "cmEndDate",
			isSortable: true,
			disablePadding: false,
			label: "CM End date",
			width: "140px",
			align: "left",
		},
		{
			id: "provider",
			isSortable: true,
			disablePadding: false,
			label: "Provider",
			width: "140px",
			align: "left",
		},
		{
			id: "status",
			isSortable: true,
			disablePadding: false,
			label: "Status",
			width: "140px",
			align: "left",
		},
		{
			id: "fileName",
			isSortable: true,
			disablePadding: false,
			label: "File name",
			width: "140px",
			align: "left",
		},
		{
			id: "action",
			isSortable: false,
			disablePadding: false,
			label: "Actions",
			width: "70px",
			align: "right",
		},
	] as HeadCell[],
	beneficiaryListView: [
		{
			id: "visit",
			isSortable: true,
			disablePadding: true,
			label: "Visit",
			width: "120px",
			align: "left",
		},
		{
			id: "provider",
			isSortable: false,
			disablePadding: true,
			label: "Provider",
			width: "160px",
			align: "left",
		},
		{
			id: "visitDate",
			isSortable: false,
			disablePadding: true,
			label: "Visit Date",
			width: "160px",
			align: "left",
		},
		{
			id: "results",
			isSortable: false,
			disablePadding: true,
			label: "Results",
			width: "160px",
			align: "center",
		},
		{
			id: "earned",
			isSortable: false,
			disablePadding: true,
			label: "Earned",
			width: "160px",
			align: "center",
		},
		{
			id: "action",
			isSortable: false,
			disablePadding: true,
			label: "Actions",
			width: "120px",
			align: "right",
		},
	] as HeadCell[],
	beneficiaryAuditLog: [
		{
			id: "timestamp",
			isSortable: false,
			disablePadding: true,
			label: "Timestamp",
			width: "150px",
			align: "left",
		},
		{
			id: "user",
			isSortable: false,
			disablePadding: true,
			label: "User",
			width: "120px",
			align: "left",
		},
		{
			id: "eventDescription",
			isSortable: false,
			disablePadding: true,
			label: "Event Description",
			align: "left",
		},
	] as HeadCell[],
	beneficiaryListIncentives: [
		{
			id: "timestamp",
			isSortable: false,
			disablePadding: true,
			label: "Timestamp",
			width: "200px",
			align: "left",
		},
		{
			id: "transaction",
			isSortable: false,
			disablePadding: true,
			label: "Transaction",
			width: "160px",
			align: "left",
		},
		{
			id: "value",
			isSortable: false,
			disablePadding: true,
			label: "Value",
			width: "160px",
			align: "center",
		},
		{
			id: "provider",
			isSortable: false,
			disablePadding: true,
			label: "Provider",
			width: "200px",
			align: "left",
		},
	] as HeadCell[],
	beneficiaryReportListView: [
		{
			id: "beneficiary",
			isSortable: true,
			disablePadding: true,
			label: "Name",
			width: "50px",
			align: "left",
		},
		{
			id: "beneficiaryCIN",
			isSortable: true,
			disablePadding: false,
			label: "CIN",
			width: "50px",
			align: "left",
		},
		{
			id: "providerSite",
			isSortable: true,
			disablePadding: false,
			label: "Site",
			width: "50px",
			align: "left",
		},
		{
			id: "provider",
			isSortable: true,
			disablePadding: false,
			label: "Provider",
			width: "50px",
			align: "left",
		},
		{
			id: "providerNPI",
			isSortable: true,
			disablePadding: false,
			label: "Provider NPI",
			width: "50px",
			align: "left",
		},
		{
			id: "enrollmentPeriod",
			isSortable: true,
			disablePadding: false,
			label: "Enrollment period",
			width: "50px",
			align: "left",
		},
		{
			id: "status",
			isSortable: true,
			disablePadding: false,
			label: "Status",
			width: "50px",
			align: "center",
		},
		{
			id: "totalNeg",
			isSortable: true,
			disablePadding: false,
			label: "Total Neg.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalPos",
			isSortable: true,
			disablePadding: false,
			label: "Total Pos.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalAbs",
			isSortable: true,
			disablePadding: false,
			label: "Abs.",
			width: "50px",
			align: "left",
		},
		{
			id: "incentiveEarned",
			isSortable: true,
			disablePadding: false,
			label: "Inc. earned",
			width: "15px",
			align: "left",
		},
		{
			id: "incentiveReceived",
			isSortable: true,
			disablePadding: false,
			label: "Inc. received",
			width: "15px",
			align: "left",
		},
		{
			id: "bankBalance",
			isSortable: true,
			disablePadding: false,
			label: "Bank balance",
			width: "15px",
			align: "left",
		},
	] as HeadCell[],
	providerReportListView: [
		{
			id: "providerName",
			isSortable: true,
			disablePadding: true,
			label: "Name",
			width: "50px",
			align: "left",
		},
		{
			id: "providerNpi",
			isSortable: true,
			disablePadding: false,
			label: "NPI",
			width: "50px",
			align: "left",
		},
		{
			id: "rolName",
			isSortable: true,
			disablePadding: false,
			label: "Role",
			width: "50px",
			align: "left",
		},
		{
			id: "startDate",
			isSortable: true,
			disablePadding: false,
			label: "Start date",
			width: "50px",
			align: "left",
		},
		{
			id: "pihpName",
			isSortable: true,
			disablePadding: false,
			label: "PIHP",
			width: "50px",
			align: "left",
		},
		{
			id: "providerSiteName",
			isSortable: true,
			disablePadding: false,
			label: "Provider site",
			width: "50px",
			align: "left",
		},
		{
			id: "totalEnrollments",
			isSortable: true,
			disablePadding: false,
			label: "# of benef.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalVisits",
			isSortable: true,
			disablePadding: false,
			label: "# of visits",
			width: "50px",
			align: "left",
		},
		{
			id: "totalNeg",
			isSortable: true,
			disablePadding: false,
			label: "Total Neg.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalPos",
			isSortable: true,
			disablePadding: false,
			label: "Total Pos.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalExc",
			isSortable: true,
			disablePadding: false,
			label: "Total Exc.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalUnex",
			isSortable: true,
			disablePadding: false,
			label: "Total Unex.",
			width: "50px",
			align: "left",
		},
		{
			id: "totalMissed",
			isSortable: true,
			disablePadding: false,
			label: "Missed entries",
			width: "50px",
			align: "left",
		},
		{
			id: "incentiveGenerated",
			isSortable: true,
			disablePadding: false,
			label: "Incentives generated",
			width: "50px",
			align: "left",
		},
		{
			id: "incentiveDisbursed",
			isSortable: true,
			disablePadding: false,
			label: "Incentives disbursed",
			width: "50px",
			align: "left",
		},
	] as HeadCell[],
};
