import { useQuery, useMutation } from "@tanstack/react-query";
import { cardsService } from "../../services/cardOrders.service";
import { CardOrderRequest } from "../../domain/cardOrders.interface";

export const useGetCards = (
	userId: string,
	orgUnitId: string,
	pageSize?: number,
	pageNumber?: number,
) => {
	return useQuery({
		queryKey: ["sims-cards-order-get", userId, orgUnitId, pageSize, pageNumber],
		queryFn: () =>
			cardsService.getCardOrders(userId, orgUnitId, pageSize, pageNumber),
		staleTime: 1000 * 60 * 5,
	});
};

export const useGetOrderDetails = (orderId: string) => {
	const query = useQuery({
		queryKey: ["sims-order-details", orderId],
		queryFn: () => cardsService.getCardOrder(orderId),
		enabled: !!orderId,
		staleTime: 1000 * 60 * 5,
	});

	return {
		...query,
		refetch: query.refetch,
	};
};

export const useCreateOrder = () => {
	return useMutation({
		mutationFn: (order: CardOrderRequest) => cardsService.addOrder(order),
	});
};
