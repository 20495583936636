import { useQuery } from "@tanstack/react-query";
import { reportService } from "../../services/report.service";
import {
	beneficiaryReportParams,
	providerReportParams,
} from "../../domain/report.interface";

const { getBeneficiaryReport, getProviderReport } = reportService;

export const useGetBeneficiaryReport = (
	params: beneficiaryReportParams,
	runReport: boolean,
) => {
	const beneficiaryReport = useQuery({
		queryKey: ["sims-report-beneficiary"],
		queryFn: () => getBeneficiaryReport(params),
		enabled: runReport,
		staleTime: 0,
	});

	return beneficiaryReport;
};

export const useDownloadBeneficiaryReport = (
	params: beneficiaryReportParams,
) => {
	const downloadBeneficiaryReport = useQuery({
		queryKey: ["sims-report-beneficiary-download"],
		queryFn: () => getBeneficiaryReport(params),
		enabled: true,
	});

	return downloadBeneficiaryReport;
};

export const useGetProviderReport = (
	params: providerReportParams,
	runReport: boolean,
) => {
	const providerReport = useQuery({
		queryKey: ["sims-report-provider"],
		queryFn: () => getProviderReport(params),
		enabled: runReport,
	});

	return providerReport;
};

export const useDownloadProviderReport = (params: providerReportParams) => {
	const downloadProviderReport = useQuery({
		queryKey: ["sims-report-provider-download"],
		queryFn: () => getProviderReport(params),
		enabled: true,
	});

	return downloadProviderReport;
};
