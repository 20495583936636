import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import common from "./en/common.json";
import users from "./en/users.json";
import cards from "./en/cards.json";
import dashboard from "./en/dashboard.json";

export const defaultNS = "common";

i18next.use(initReactI18next).init({
	lng: "en", // if you're using a language detector, do not define the lng option
	debug: false,
	resources: {
		en: {
			common,
			users,
			cards,
			dashboard,
		},
	},
	defaultNS,
});
