import { axios } from "../utils/axios.create";
import {
	AuditlogPayload,
	AuditlogResponseBody,
	AuditlogParam,
	AuditlogPaginated,
} from "../domain/auditlog.interface";

const create = async (data: AuditlogPayload): Promise<AuditlogResponseBody> => {
	return axios
		.post("/auditlog", data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const findByModule = async (
	data: AuditlogParam,
): Promise<AuditlogPaginated> => {
	return axios
		.get(
			`/auditlog?module=${data.module}&from=${data.from}&to=${data.to}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}${data.lastDocId ? "&lastDocId=" + data.lastDocId : ""}`,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

export const auditlogService = {
	create,
	findByModule,
};
