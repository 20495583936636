import { axios } from "../utils/axios.create";
import {
	BeneficiaryItem,
	BeneficiaryItemSearched,
	BeneficiaryPayload,
	BeneficiaryResponse,
	BeneficiaryVisitData,
	Program,
	Provider,
	ProviderSite,
	IWeekStatus,
	ConsentFormData,
	Consent,
	ConsentPayload,
	ConsentUrlResponse,
	BeneficiaryEnrollmentPeriodsResponse,
	BeneficiaryIncentiveHistoryResponse,
	ParamsDeleteCard,
	ICardDelete,
	CardData,
	CardPayload,
	CardPinPayload,
} from "../domain/beneficiary.interface";
import { ACCOUNT_NAME } from "../utils/general";
import dayjs from "dayjs";
import { TreeItem } from "../domain/common.interface";

const findAll = async ({
	providerSite,
	programId,
	providerId,
	userId,
	status,
	search,
	pageNumber,
	pageSize,
}: {
	providerSite: string;
	programId: string;
	providerId: string;
	userId: string;
	status: string[];
	search: string;
	pageNumber: number;
	pageSize: number;
}): Promise<{
	data: BeneficiaryResponse[];
	total: number;
	totalCard: number;
	totalActive: number;
	totalDisenroll: number;
	totalCompleted: number;
	totalPaused: number;
}> => {
	providerSite = providerSite === "all" ? "" : providerSite;
	programId = programId === "all" ? "" : programId;
	providerId = providerId === "all" ? "" : providerId;

	return axios
		.get(
			`/beneficiaries/information/${ACCOUNT_NAME}?providerSite=${providerSite}&programId=${programId}&providerId=${providerId}&status=${status.join(",")}&search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}&userId=${userId}`,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const findById = async (
	beneficiaryId: string,
	enrollmentId?: string,
): Promise<BeneficiaryResponse> => {
	let enrollmentIdParam = "";

	if (enrollmentId && enrollmentId.trim() !== "") {
		enrollmentIdParam = `?enrollmentId=${enrollmentId}`;
	}

	return axios
		.get(
			`/beneficiaries/beneficiary/information/${ACCOUNT_NAME}/${beneficiaryId}${enrollmentIdParam}`,
		)
		.then((res) => res.data.body[0])
		.catch((err) => Promise.reject(err.response.data));
};

const create = async (
	data: BeneficiaryPayload,
): Promise<{ beneficiaryId: string; enrollmentId: string }> => {
	return axios
		.post("/beneficiaries", data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const update = async (
	data: Partial<BeneficiaryPayload>,
	beneficiaryId: string,
	enrollmentId: string,
): Promise<BeneficiaryItem> => {
	return axios
		.put(`/beneficiaries/${beneficiaryId}/${enrollmentId}`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const patchBeneficiary = async (
	beneficiaryId: string,
	data: Partial<Pick<BeneficiaryPayload, "userId" | "phone" | "email">>,
): Promise<BeneficiaryItem> => {
	return axios
		.patch(`/beneficiaries/patch/${beneficiaryId}`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const updateReconfirm = async (
	userId: string,
	beneficiaryId: string,
	enrollmentId: string,
): Promise<BeneficiaryItem> => {
	return axios
		.put(
			`/beneficiaries/reconfirmeligibility/${ACCOUNT_NAME}/${beneficiaryId}/${enrollmentId}`,
			{
				userId,
			},
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const getConsentForms = async (
	beneficiaryId: string,
): Promise<ConsentFormData[]> => {
	return axios
		.get(`/beneficiaries/${beneficiaryId}/consents`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const patchConsentForm = async (
	data: ConsentPayload,
	beneficiaryId: string,
	enrollmentId: string,
): Promise<Consent> => {
	return axios
		.patch(
			`/beneficiaries/${beneficiaryId}/enrollments/${enrollmentId}/consent`,
			data,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const getConsentFormUrl = async (
	s3Url: string,
): Promise<ConsentUrlResponse> => {
	const urlParams = new URLSearchParams();
	urlParams.append("s3Url", s3Url);
	return axios
		.get(`/consent-file?${urlParams.toString()}`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const findPrograms = async (programIds: string[]): Promise<Program[]> => {
	return axios
		.get(
			`/beneficiaries/programs/${ACCOUNT_NAME}?userProgramIds=${programIds.join(",")}`,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const findProviderSites = async (
	ouIds: string[],
	userId: string,
	createTree: boolean,
): Promise<
	{
		key: string;
		id: string;
		value: string;
		name: string;
		children: TreeItem[];
	}[]
> => {
	const createTreeParam = createTree ? `&createTree=${createTree}` : "";

	if (ouIds.length === 0) {
		return [];
	}

	return axios
		.get(
			`/beneficiaries/providers-site/${ACCOUNT_NAME}?orgUnits=${ouIds.join(",")}${createTreeParam}&userId=${userId}`,
		)
		.then((res) => {
			const providers = res.data.body;

			return [
				...providers.map(
					(provider: (TreeItem | ProviderSite) & { _id?: string }) => {
						return {
							key: provider.id || provider._id,
							id: provider.id || provider._id,
							name: provider.name,
							value: provider.name,
							children: (provider as TreeItem).children,
						};
					},
				),
			];
		})
		.catch((err) => Promise.reject(err.response.data));
};

const findProviders = async (
	programId: string[],
	orgUnitId: string[],
	includeAll: boolean,
): Promise<{ key: string; value: string }[]> => {
	return axios
		.get(
			`/beneficiaries/providers/${ACCOUNT_NAME}?orgUnitIds=${orgUnitId.join(",")}&programIds=${programId.join(",")}`,
		)
		.then((res) => {
			const providers = res.data.body as Provider[];
			const optionAll = includeAll ? [{ key: "all", value: "All" }] : [];

			return [
				...optionAll,
				...providers.map((provider: Provider) => {
					return {
						key: provider._id,
						value: `${provider.firstName} ${provider.lastName}`,
					};
				}),
			];
		})
		.catch((err) => Promise.reject(err.response.data));
};

const disenroll = async (
	data: {
		reasons: string;
		notes: string;
		userId: string;
	},
	beneficiaryId: string,
	enrollmentId: string,
): Promise<BeneficiaryItem> => {
	return axios
		.put(`/beneficiaries/disenroll/${beneficiaryId}/${enrollmentId}`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const reenroll = async (
	data: Partial<BeneficiaryPayload>,
	beneficiaryId: string,
	enrollmentId: string,
): Promise<{ beneficiaryId: string; enrollmentId: string }> => {
	return axios
		.put(`/beneficiaries/reenroll/${beneficiaryId}/${enrollmentId}`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const findByName = async (
	name: string,
	userId: string,
): Promise<BeneficiaryItemSearched[]> => {
	return axios
		.get(
			`/beneficiaries/search?data=${name}&userId=${userId}&account=${ACCOUNT_NAME}`,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const findListVisits = async (
	enrollmentId: string,
	rangeWeek: string,
): Promise<BeneficiaryVisitData[]> => {
	const [from, to] = rangeWeek.split("-");
	return axios
		.get(`enrollments/${enrollmentId}/udt-results?from=${from}&to=${to}`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const getCurrentWeekUdtStatus = async (
	enrollmentId: string,
): Promise<IWeekStatus> => {
	return axios
		.get(`/enrollments/${enrollmentId}/current-week-status`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const getEnrollmentPeriods = async (
	beneficiaryId: string,
): Promise<{ key: string; value: string }[]> => {
	return axios
		.get(`/beneficiaries/${beneficiaryId}/enrollment-periods`)
		.then((res) => {
			const response = res.data.body;

			const beneficiaryEnrollmentPeriods = response.map(
				(item: BeneficiaryEnrollmentPeriodsResponse) => {
					const value = `${item.program.name}: ${dayjs(item.startDate).format("MM/DD/YYYY")} - ${item.endDate ? dayjs(item.endDate).format("MM/DD/YYYY") : ""}`;

					return {
						key: item._id,
						value,
					};
				},
			);

			return beneficiaryEnrollmentPeriods;
		})
		.catch((err) => Promise.reject(err.response.data));
};

const findIncentiveHistory = async (
	enrollmentId: string,
	pageNumber: number,
	pageSize: number,
): Promise<BeneficiaryIncentiveHistoryResponse> => {
	return axios
		.get(
			`enrollments/${enrollmentId}/incentive-history?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`,
		)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const deleteCard = async (params: ParamsDeleteCard): Promise<ICardDelete> => {
	return axios
		.patch(`/beneficiaries/${params.beneficiaryId}/cards/${params.cardId}`, {
			providerId: params.providerId,
		})
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const getCards = async (beneficiaryId: string): Promise<CardData[]> => {
	return axios
		.get(`/beneficiaries/${beneficiaryId}/cards`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const postAddCard = async (
	beneficiaryId: string,
	data: CardPayload,
): Promise<CardData[]> => {
	return axios
		.post(`/beneficiaries/${beneficiaryId}/card`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const postSetPin = async (
	accountId: string,
	data: CardPinPayload,
): Promise<CardData[]> => {
	return axios
		.post(`/cards/accounts/${accountId}/pin`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

export const beneficiaryService = {
	findAll,
	findById,
	create,
	update,
	patchBeneficiary,
	updateReconfirm,
	getConsentForms,
	patchConsentForm,
	getConsentFormUrl,
	findPrograms,
	findProviderSites,
	findProviders,
	disenroll,
	reenroll,
	findByName,
	findListVisits,
	getCurrentWeekUdtStatus,
	getEnrollmentPeriods,
	findIncentiveHistory,
	deleteCard,
	getCards,
	postAddCard,
	postSetPin,
};
