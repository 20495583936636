import React, { forwardRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAppTheme } from "../../utils/theme";
import FormHelperText from "@mui/material/FormHelperText";

interface UploadButtonProps {
	value?: File;
	onChange: (file: File) => void;
	disabled?: boolean;
}

const UploadButton = forwardRef<HTMLInputElement, UploadButtonProps>(
	({ value, onChange, disabled, ...props }, ref) => {
		const theme = useAppTheme();
		const [fileName, setFileName] = useState<string | null>(null);
		const [fileBlob, setFileBlob] = useState<Blob | null>(null);
		const [error, setError] = useState<string>("");

		const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.[0];
			if (file) {
				if (file.size > 10 * 1024 * 1024) {
					// 10 MB max size
					setError("File size exceeds the limit of 10 MB.");
				} else if (
					!["image/png", "image/jpeg", "application/pdf"].includes(file.type)
				) {
					setError(
						"File type not accepted. Only *.png, *.jpeg, *.pdf files are allowed.",
					);
				} else {
					onChange(file);
					setFileName(file.name);
					setFileBlob(new Blob([file], { type: file.type }));
					setError(""); // Clear any previous errors
				}
			}
		};

		const handleViewFile = () => {
			if (fileBlob) {
				const url = URL.createObjectURL(fileBlob);
				window.open(url);
			}
		};

		useEffect(() => {
			if (value) {
				setFileName(value.name);
				setFileBlob(new Blob([value], { type: value.type }));
			}
		}, [value]);

		return (
			<>
				{fileName && (
					<Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
						<Typography
							color={theme.palette.textMain.dark4}
							onClick={handleViewFile}
							sx={{ textDecoration: "underline", cursor: "pointer" }}
						>
							{fileName}
						</Typography>
					</Box>
				)}
				<Button
					variant="outlined"
					component="label"
					startIcon={<FileUploadIcon />}
					disabled={disabled}
				>
					{fileName ? "Replace Form" : "Upload"}
					<input
						type="file"
						hidden
						ref={ref}
						onChange={handleFileChange}
						{...props}
					/>
				</Button>
				{error && (
					<FormHelperText className="!ml-[14px]" error>
						{error}
					</FormHelperText>
				)}
			</>
		);
	},
);

UploadButton.displayName = "UploadButton";

export default UploadButton;
