import { TreeItem } from "@/domain/common.interface";
import { RESULT_STATUS, VISIT_STATUS } from "../domain/beneficiary.enum";
import { AppTheme } from "./theme";

export const ACCOUNT_NAME = process.env.REACT_APP_ACCOUNT as string;

export const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
};

export const toBase64 = (file: File) =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

export const getStatusColor = (result: string, theme: AppTheme) => {
	switch (result) {
		case RESULT_STATUS.POSITIVE:
			return theme.palette.error.light;
		case RESULT_STATUS.NEGATIVE:
			return theme.palette.secondary.light;
		case RESULT_STATUS.EXCUSED_ABSENCE:
			return theme.palette.outline.dark;
		case RESULT_STATUS.UNEXCUSED_ABSENCE:
			return theme.palette.warning.light;
		case VISIT_STATUS.MISSED:
			return theme.palette.outline.dark;
		default:
			return theme.palette.secondary.light;
	}
};

export const getStatusText = (result: string) => {
	switch (result) {
		case RESULT_STATUS.POSITIVE:
			return "Pos UDT";
		case RESULT_STATUS.NEGATIVE:
			return "Neg UDT";
		case RESULT_STATUS.EXCUSED_ABSENCE:
			return "Ex. Absence";
		case RESULT_STATUS.UNEXCUSED_ABSENCE:
			return "Un. Absence";
		case VISIT_STATUS.MISSED:
			return "Missed entry";
		default:
			return "Pos UDT";
	}
};

export const treeToArray = (tree: TreeItem[]): TreeItem[] => {
	const result: TreeItem[] = [];
	for (const item of tree) {
		result.push(item);
		result.push(...treeToArray(item.children));
	}
	return result;
};

export const formatCommaSeparatedNumber = (
	value: number | undefined,
): string => {
	if (value === undefined) return "0.00";
	return value.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const generateSampleFromRegex = (regex: string): string => {
	// Remove leading ^ and trailing $ if they exist
	const cleanedRegex = regex.replace(/^\^/, "").replace(/\$$/, "");
	let sample = "";
	let i = 0;

	while (i < cleanedRegex.length) {
		let char = cleanedRegex[i];

		if (char === "\\") {
			// Handle escape sequences (e.g., \d, \w)
			i++; // Skip the backslash
			char = cleanedRegex[i];

			if (char === "d") {
				// Handle digit placeholder
				sample += Math.floor(Math.random() * 10);
			} else if (char === "w") {
				// Handle word character placeholder
				const letters =
					"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
				sample += letters.charAt(Math.floor(Math.random() * letters.length));
			} else if (char === "s") {
				// Handle whitespace character placeholder
				sample += " ";
			} else {
				// Handle other escaped characters
				sample += char;
			}
		} else if (char === "{") {
			// Handle quantifiers (e.g., {5,6})
			const endBracketIndex = cleanedRegex.indexOf("}", i);
			if (endBracketIndex > -1) {
				const quantifier = cleanedRegex.slice(i + 1, endBracketIndex);
				const [min, max] = quantifier.split(",").map(Number);
				const repeatCount = max || min; // Use the max if specified, otherwise min

				const lastChar = sample[sample.length - 1];
				sample = sample.slice(0, -1); // Remove the last character (it's already counted)

				for (let j = 0; j < repeatCount; j++) {
					sample += lastChar;
				}

				i = endBracketIndex; // Move index to the end of the quantifier
			}
		} else {
			// Handle custom character classes or specific characters
			sample += char;
		}
		i++;
	}

	return sample;
};
