import { FC } from "react";
import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import { Controller, Control } from "react-hook-form";
import { ICreateUserPayload } from "@/domain/user.interface";

type Props = {
	control: Control<ICreateUserPayload>;
	rolesList: Array<{
		id: string;
		name: string;
		type: string;
		level: number;
	}>;
};

export const RoleForm: FC<Props> = (props) => {
	const { control, rolesList } = props;

	function getRows(type: string) {
		return rolesList
			?.filter((rol) => rol.type === type)
			.map((rol) => (
				<FormControlLabel
					key={rol.id}
					value={rol.id}
					control={<Radio />}
					label={rol.name}
					sx={{ paddingLeft: "1rem" }}
				/>
			));
	}

	return (
		<Controller
			name="roleId"
			control={control}
			defaultValue=""
			render={({ field: { onChange, value, name } }) => (
				<RadioGroup
					onChange={onChange}
					value={value}
					name={name}
					aria-labelledby="demo-radio-buttons-group-label"
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
						width={["100%", "100%", "100%", "75%", "60%"]}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
							}}
						>
							<Typography
								variant="titleMedium"
								color="primary.dark"
								fontWeight="bold"
							>
								Standard system roles
							</Typography>
							{getRows("Standard System Roles")}
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
							}}
						>
							<Typography
								variant="titleMedium"
								color="primary.dark"
								fontWeight="bold"
							>
								Custom roles
							</Typography>
							{getRows("Custom Roles")}
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
							}}
						>
							<Typography
								variant="titleMedium"
								color="primary.dark"
								fontWeight="bold"
							>
								Standard account roles
							</Typography>
							{getRows("Standard Account Roles")}
						</Box>
					</Box>
				</RadioGroup>
			)}
		/>
	);
};

export default RoleForm;
