import { useMutation, useQuery } from "@tanstack/react-query";
import { programService } from "../../services/program.service";

const { findProgramsByAccount, patchProgramSetting } = programService;

export const useGetProgramsByAccount = (account: string) => {
	return useQuery({
		queryKey: ["sims-users-programs"],
		queryFn: () => findProgramsByAccount(account),
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});
};

export const usePatchProgramSetting = (
	programId: string,
	todayDateAndTime: Date | null,
) => {
	return useMutation({
		mutationFn: () => patchProgramSetting(programId, todayDateAndTime),
	});
};
