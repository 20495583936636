import DataTable from "./DataTable";
import {
	HeadCell,
	DataTableCell,
	DataTableCellValue,
} from "./dataTable.interface";

export type { HeadCell, DataTableCell, DataTableCellValue };

export default DataTable;
