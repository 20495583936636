import React, { FC, useMemo, useState } from "react";
import {
	Box,
	Button,
	Fade,
	Grid,
	Link,
	Slide,
	Stack,
	Typography,
} from "@mui/material";
import KPICard from "../../components/KpiCards/KpiCard";
import getIcon, { IconNames } from "./IconMappings";
import { useDismissAll } from "../../hooks/useAlertAndEvents";
import { IAlertEvent } from "@/domain/alertEvent.interface";
import useDate from "../../hooks/useDate";
import { useUserStore } from "../../stores/user.store";
import Spinner from "../../components/Spinner";
import { useSetupEvents } from "../../hooks/useAlertAndEvents/EventsSetup";
import { EVENT_ENUM } from "../../domain/events.enum";
import { createLinkedNarrative } from "./NarrativeComponent";

type EventOrAlertItemProps = {
	icon: IconNames;
	textContent: React.ReactNode;
	checkAction?: () => void;
	dismissAction?: () => void;
};

const getIconString = (actionCode: string): IconNames => {
	switch (actionCode) {
		case EVENT_ENUM.EVE_BEN_ENROLL:
		case EVENT_ENUM.EVE_BEN_DISENROLL:
			return "person";
		case EVENT_ENUM.EVE_BEN_COMPL:
			return "trophy";
		case EVENT_ENUM.EVE_MISSED_ENTRY:
			return "warning";
		default:
			return "alert";
	}
};

const EventOrAlertItemBase: FC<EventOrAlertItemProps> = (props) => (
	<Grid container alignItems={"center"} spacing={2}>
		<Grid item xs="auto">
			{getIcon(props.icon)}
		</Grid>
		<Grid item xs>
			{props.textContent}
		</Grid>
		<Grid item xs="auto">
			{props.checkAction && (
				<Button variant="outlined" onClick={props.checkAction} color="inherit">
					Check
				</Button>
			)}
		</Grid>
		<Grid item xs="auto">
			{props.dismissAction && (
				<Button
					variant="outlined"
					onClick={props.dismissAction}
					color="inherit"
				>
					Dismiss
				</Button>
			)}
		</Grid>
	</Grid>
);

const AlertEventItem = ({
	alertEvent,
	visible,
	onAnimationEnd,
}: {
	alertEvent: IAlertEvent;
	visible: boolean;
	onAnimationEnd: () => void;
}): JSX.Element => {
	const { dayjs } = useDate();
	const { mutateAsync: dismissAll } = useDismissAll(alertEvent.providerId);

	const handleDismiss = async () => {
		onAnimationEnd();
		setTimeout(async () => {
			await dismissAll(alertEvent._id);
		}, 500);
	};

	const content = (
		<EventOrAlertItemBase
			icon={getIconString(alertEvent.actionCode ?? "")}
			textContent={
				<Stack direction={"column"}>
					<Typography
						variant="bodyLarge"
						color={(theme) => theme.palette.textMain.dark2}
					>
						{createLinkedNarrative(alertEvent)}
					</Typography>
					<Typography
						variant="inputHelper"
						color={(theme) => theme.palette.textMain.main2}
					>
						{dayjs(alertEvent.dateOfProcess).format("DD/MM/YYYY HH:mm")}
					</Typography>
				</Stack>
			}
			dismissAction={alertEvent._id ? handleDismiss : undefined}
		/>
	);

	switch (alertEvent.type) {
		case "ALERT":
		case "EVENT":
			return (
				<Fade in={visible} timeout={500}>
					<div>{content}</div>
				</Fade>
			);
		default:
			return (
				<Fade in={visible} timeout={500}>
					<div>{content}</div>
				</Fade>
			);
	}
};

const SlidingAlertEventItem = ({
	alertEvent,
	visible,
	onAnimationEnd,
}: {
	alertEvent: IAlertEvent;
	visible: boolean;
	onAnimationEnd: () => void;
}): JSX.Element => {
	const content = (
		<AlertEventItem
			alertEvent={alertEvent}
			visible={visible}
			onAnimationEnd={onAnimationEnd}
		/>
	);

	return (
		<Slide
			direction="left"
			in={visible}
			mountOnEnter
			unmountOnExit
			timeout={{ enter: 0, exit: 500 }}
			easing="ease-in-out"
		>
			<div>{content}</div>
		</Slide>
	);
};

export function EventsAndAlertsComponent() {
	const { userHasAccessToCode } = useUserStore();
	const userInfo = useUserStore((state) => state.userInfo);
	const [visibleAlerts, setVisibleAlerts] = useState<Record<string, boolean>>(
		{},
	);

	const { mutateAsync: dismissAll, isPending: isDismissingAll } = useDismissAll(
		// @ts-expect-error userInfo is not undefined
		userInfo?.id,
	);

	const {
		events: eventsAndAlerts,
		refetchBackendAlerts,
		isFetching,
	} = userInfo
		? useSetupEvents(userInfo)
		: { events: [], refetchBackendAlerts: () => undefined, isFetching: false };

	const isLoading = useMemo(
		() => isDismissingAll || isFetching,
		[isDismissingAll, isFetching],
	);

	const handleDismissAll = async (alertId?: string) => {
		const alertIds = eventsAndAlerts
			?.filter((alert) => alert._id)
			.map((alert) => alert._id);
		if (alertIds) {
			const newVisibleAlerts = alertIds.reduce(
				(acc, id) => ({ ...acc, [id]: false }),
				{},
			);
			setVisibleAlerts(newVisibleAlerts);
		}
		await dismissAll(alertId);
		refetchBackendAlerts();
	};

	const handleAnimationEnd = (alertId: string) => {
		setVisibleAlerts((prev) => ({ ...prev, [alertId]: false }));
	};

	return (
		<KPICard gridProps={{ md: 12, paddingTop: 0 }}>
			<Stack
				direction="column"
				justifyContent={"space-between"}
				height={"100%"}
				spacing={2}
			>
				<Box
					sx={{
						position: "sticky",
						top: 0,
						backgroundColor: "white",
						zIndex: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems={"center"}
						marginTop={1}
						marginBottom={1}
					>
						<Typography variant="h5" fontWeight={700} color="textMain.dark2">
							Events & alerts
						</Typography>
						<Link href="#" underline="hover" onClick={() => handleDismissAll()}>
							<Typography
								variant="labelMedium"
								sx={{ fontWeight: 700 }}
								color={(theme) => theme.palette.textMain.dark4}
							>
								DISMISS ALL
							</Typography>
						</Link>
					</Stack>
				</Box>
				{!isLoading &&
					eventsAndAlerts?.map((alertOrEvent, index) => {
						const alertId = alertOrEvent._id;
						const isVisible = alertId ? visibleAlerts[alertId] !== false : true;
						return (
							alertOrEvent.actionCode &&
							userHasAccessToCode(alertOrEvent.actionCode) && (
								<SlidingAlertEventItem
									key={`${alertOrEvent.actionCode}-${index}`}
									alertEvent={alertOrEvent}
									visible={isVisible}
									onAnimationEnd={() => alertId && handleAnimationEnd(alertId)}
								/>
							)
						);
					})}

				{isLoading && <Spinner />}
			</Stack>
		</KPICard>
	);
}
