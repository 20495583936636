import React, { ReactNode, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { SnackBarContext } from "./FeedBackContext";
import Alert, { AlertProps } from "@mui/material/Alert";
import GenericAlertModal from "../../components/modals/GenericAlertModal";
import LinkNotOpenedModal from "../../components/modals/LinktNotOpenedModal";

const BUTTON_LINK_TAB_ID = "sims-open-tab-button";

export const FeedBackProvider = ({ children }: { children: ReactNode }) => {
	const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [snackBarSeverity, setSnackBarSeverity] =
		useState<AlertProps["severity"]>("error");
	const [isSimpleAlertOpen, setIsSimpleAlertOpen] = useState(false);
	const [simpleAlertMessage, setSimpleAlertMessage] = useState<{
		message: string;
		action?: () => void;
	}>({
		message: "",
	});
	const [isOpenLinkNotOpenedModal, setIsOpenLinkNotOpenedModal] =
		useState(false);
	const [linkToOpen, setLinkToOpen] = useState<string | undefined>();

	const showSnackBar = (
		message: string,
		severity: AlertProps["severity"] = "error",
	) => {
		setSnackBarSeverity(severity);
		setSnackBarMessage(message);
		setIsSnackBarOpen(true);
	};

	const hideSnackBar = () => {
		setSnackBarMessage("");
		setIsSnackBarOpen(false);
	};

	const showSimpleAlert = (message: string, onOk?: () => void) => {
		setSimpleAlertMessage({
			message,
			action: onOk,
		});
		setIsSimpleAlertOpen(true);
	};

	const onHideSimpleAlert = () => {
		setSimpleAlertMessage({
			message: "",
		});
		setIsSimpleAlertOpen(false);
		simpleAlertMessage.action && simpleAlertMessage.action();
	};

	const openLinkInTab = (link?: string) => {
		setLinkToOpen(link);
		if (!link) {
			return;
		}

		const aElement = document.getElementById(
			BUTTON_LINK_TAB_ID,
		) as HTMLLinkElement;
		aElement.href = link;
		aElement.dispatchEvent(new MouseEvent("click", { bubbles: true }));
		setIsOpenLinkNotOpenedModal(true);
	};

	return (
		<SnackBarContext.Provider
			value={{
				showSnackBar,
				hideSnackBar,
				showSimpleAlert,
				hideSimpleAlert: onHideSimpleAlert,
				openLinkInTab,
			}}
		>
			{children}
			<Snackbar
				open={isSnackBarOpen}
				autoHideDuration={6000}
				onClose={hideSnackBar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={hideSnackBar}
					severity={snackBarSeverity}
					variant="filled"
					sx={{ width: "100%" }}
				>
					{snackBarMessage}
				</Alert>
			</Snackbar>
			{isSimpleAlertOpen && (
				<GenericAlertModal
					title="Warning"
					description={simpleAlertMessage.message}
					submitText="Accept"
					onClick={onHideSimpleAlert}
				/>
			)}
			<a
				hidden
				id={BUTTON_LINK_TAB_ID}
				target="_blank"
				rel="noopener noreferrer"
			/>
			{isOpenLinkNotOpenedModal && linkToOpen && (
				<LinkNotOpenedModal
					isOpen={isOpenLinkNotOpenedModal}
					onClose={() => setIsOpenLinkNotOpenedModal(false)}
					link={linkToOpen}
				/>
			)}
		</SnackBarContext.Provider>
	);
};
