import { createContext, useContext } from "react";

interface XlsGeneratorContextType {
	exportToExcel: (
		headerMapping: { [key: string]: string },
		// TODO: Define the type of the jsonData parameter
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		jsonData: any[],
		fileName: string,
	) => void;
}

export const XlsGeneratorContext = createContext<
	XlsGeneratorContextType | undefined
>(undefined);

export const useXlsGenerate = () => {
	const context = useContext(XlsGeneratorContext);
	if (context === undefined) {
		throw new Error(
			"useXlsGenerate must be used within an XlsGeneratorProvider",
		);
	}
	return context;
};
