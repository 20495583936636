export const menuAdmin: {
	code: string;
	icon: string;
	action?: () => void;
}[] = [
	{ code: "DASH_VIEW", icon: "content-paste-outlined" },
	{ code: "PART_VIEW", icon: "person-outlined" },
	{ code: "USER_MNG_VIEW", icon: "group-outlined" },
	{ code: "PROG_MNG_VIEW", icon: "programs-config" },
	{ code: "ROLE_MNG_VIEW", icon: "hub-outlined" },
	{ code: "OU_CONF_VIEW", icon: "domain-outlined" },
	{ code: "REP_VIEW", icon: "note-outlined" },
	{ code: "REP_BEN_VIEW", icon: "assessment-outlined" },
	{ code: "REP_PROV_VIEW", icon: "assessment-outlined" },
	{ code: "REP_PIHP_VIEW", icon: "assessment-outlined" },
	{ code: "REP_PROG_OVER_VIEW", icon: "assessment-outlined" },
	{ code: "REP_USER_INVI_VIEW", icon: "assessment-outlined" },
	{ code: "HELP_SUPPORT_VIEW", icon: "help-outline-outlined" },
	{ code: "AWS_ACCESS", icon: "content-paste-outlined" },
	{ code: "PHY_CARD_ORDER", icon: "credit-card" },
];

export const getIconOrDefault = (code: string) => {
	const icon = menuAdmin.find((item) => item.code === code);
	if (icon) return icon.icon;
	return "content-paste-outlined";
};
