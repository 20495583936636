import { ISettingResponse } from "@/domain/setting.interface";
import { settingService } from "../../services/setting.service";
import { useQuery } from "@tanstack/react-query";

const { findAll } = settingService;

export const useGetSetting = () => {
	const settingsQuery = useQuery({
		queryKey: ["get-setting-list"],
		queryFn: findAll,
		staleTime: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
	});

	return settingsQuery;
};

/**
 * Returns the settings object with the name as the key and the type from type property
 * @returns
 */
export const useSettings = () => {
	const settingsQuery = useGetSetting();
	const { data: settings } = settingsQuery;
	// convert the array of settings to an object with the name as the key and the type from type property
	const settingsObj:
		| Record<ISettingResponse["name"], ISettingResponse>
		| undefined = settings?.reduce(
		(acc, setting) => {
			acc[setting.name] = setting;
			return acc;
		},
		{} as Record<ISettingResponse["name"], ISettingResponse>,
	);

	/**
	 * Get the value of a setting by name
	 * @param name
	 * @returns
	 */
	const getSettingValue = (name: ISettingResponse["name"]) => {
		return settingsObj?.[name]?.default;
	};

	return { ...settingsQuery, ...settingsObj, getSettingValue };
};

export const useCurrencySetting = (): ISettingResponse => {
	const currencySymbol = { USD: "$", EUR: "€", GBP: "£" };
	const { data } = useGetSetting();
	const currencySetting = data?.find((setting) => setting.name === "currency");

	if (currencySetting) {
		// @ts-expect-error - the default value is a string
		currencySetting.mask = currencySymbol[currencySetting?.default];
	}

	// @ts-expect-error - the default value is a string
	return currencySetting || { mask: "$", default: "USD" };
};

export const useIANATimeZoneSetting = (): string => {
	const { data } = useGetSetting();
	const timeZoneSetting = data?.find(
		(setting) => setting.name === "IANATimeZone",
	);

	return (timeZoneSetting?.default as string) || "America/New_York";
};

export const useSettingsV2 = (userId?: string) => {
	const account = process.env.REACT_APP_ACCOUNT ?? "";
	const settingsQuery = useQuery({
		queryKey: ["get-setting-v2-", account, userId],
		queryFn: () => settingService.getSettingsV2(account, userId),
		staleTime: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
	});

	return settingsQuery;
};
