import { OptionHelpProps } from "../pages/HelpAndSupport/OptionHelp";

export const optionsHelp: OptionHelpProps[] = [
	{
		id: "faq",
		title: "Frequently asked questions",
		description:
			"Download the Frequently Asked Questions which has information about how to manage your Beneficiaries and distribute incentives.",
		image: "/assets/faq_illustration.svg",
		colorButton: "inherit",
		buttonText: "Download",
		buttonVariant: "outlined",
	},
	{
		id: "training",
		title: "Training materials & program manual",
		description:
			"To access a complete list of training materials and the program manual, please click the button below.",
		image: "/assets/pdf_illustration.svg",
		colorButton: "inherit",
		buttonText: "Download",
		buttonVariant: "outlined",
	},
	{
		id: "zendesk",
		title: "Create ticket",
		description:
			"Generate a ticket, and our dedicated support staff will promptly assist you in resolving any problems, ensuring a smooth and hassle-free experience.",
		image: "/assets/help_desk_illustration.svg",
		colorButton: "primary",
		buttonText: "Create Request",
		buttonVariant: "contained",
	},
];
