import { axios } from "../utils/axios.create";
import {
	OrganizationItem,
	OrganizationsByRolPayload,
	OrganizationResponse,
	Params,
	ChildOrganization,
} from "@/domain/organization.interface";

const findAll = async (params: Params): Promise<OrganizationResponse[]> => {
	return axios
		.get(`/account/${params.account}/organizations`)
		.then((res) => res.data.body)
		.catch((err) => {
			throw new Error(err.response.data);
		});
};

const findOrganizationsByRoleId = async (
	account: string,
	roleId: string,
): Promise<OrganizationsByRolPayload> => {
	return axios
		.get(`/account/${account}/organizations-parent-level?roleId=${roleId}`)
		.then((res) => {
			const organizationsByLevelPayload = res.data
				.body as OrganizationsByRolPayload;

			return organizationsByLevelPayload;
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};

const findOrganizationsByAccountProgramAndParentOrg = async (
	accountId: string,
	programId: string,
	parentOrgId: string,
): Promise<ChildOrganization[]> => {
	const url = `/account/${accountId}/organizations?`;
	const parentOrgIdParam = parentOrgId ? `&parentOrgUnitId=${parentOrgId}` : "";
	const fullUrl = url + `programId=${programId}` + parentOrgIdParam;

	return axios
		.get(fullUrl)
		.then((res) => {
			let organizations = res.data.body as OrganizationItem[];

			if (!parentOrgId) {
				organizations = organizations.filter(
					(organization: OrganizationItem) => organization.ouLevel === 0,
				);
			}

			return organizations.map((organization: OrganizationItem) => {
				const { id, name } = organization;
				return {
					id,
					name,
				};
			});
		})
		.catch((err) => {
			return Promise.reject(new Error(err.response.data));
		});
};

export const organizationService = {
	findAll,
	findOrganizationsByRoleId,
	findOrganizationsByAccountProgramAndParentOrg,
};
