import { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { useCreateUser } from "../../hooks/useUser/index";
import UserInformation from "./UserInformation";
import { useGetUserById, useUpdateUser } from "../../hooks/useUser";
import { UserSchema } from "./useSchema";
import { IUserPayloadId } from "@/domain/user.interface";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useUserStore } from "../../stores/user.store";

const EditUser: FC = () => {
	const { userInfo } = useUserStore();
	const createUserMutation = useCreateUser();
	const navigate = useNavigate();
	const { id: userId } = useParams<{ id: string }>();
	if (!userId) navigate("/users");

	const { mutateAsync: createAuditLog } = useCreateAuditlog();
	const showConfirmationState = useState(false);
	const queryClient = useQueryClient();
	queryClient.invalidateQueries({
		queryKey: ["get-organizaation-list"],
	});
	queryClient.invalidateQueries({
		queryKey: ["get-user-by-id", userId],
	});

	// @ts-expect-error - userId is not null
	const { data: user, isLoading } = useGetUserById(userId);
	// @ts-expect-error - userId is not null
	const { mutateAsync, error } = useUpdateUser({ userId });
	const generalErrorMessageState = useState("");

	const onSubmit = async (data: UserSchema) => {
		const dataMapped: IUserPayloadId = {
			id: userId as string,
			account: process.env.REACT_APP_ACCOUNT as string,
			email: data.email,
			firstName: data.firstName,
			lastName: data.lastName,
			status: data.status,
			phoneNumber: data.phoneNumber,
			npi: data.npi || "",
			programs:
				data.programOrganizationsGroupList?.map((selOrg) => {
					return {
						programId: selOrg.programId,
						orgUnitId: selOrg.organizationIdsList?.map((item) => item) || [],
					};
				}) || [],
			roles: [data.roleId],
		};

		await mutateAsync(dataMapped);

		await queryClient
			.invalidateQueries({
				queryKey: ["sims-users", "get-user-by-id"],
			})
			.then(() =>
				createAuditLog({
					action: "When saving a user (editing)",
					module: "Users",
					option: "User edit",
					detail: `Edited the user ${dataMapped?.email}`,
					actionCode: "WEB_USRS_USR_EDIT",
					appType: "WEB_BACK_OFFICE",
					createdBy: userInfo?.id || "",
				}),
			)
			.then(() => {
				navigate("/users");
			});
	};

	useEffect(() => {
		if (error) {
			generalErrorMessageState[1](
				"An error occurred while saving the data, please check the data and try again.",
			);
		}
	}, [error]);

	const createAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!createAuditlogCalledRef.current && user) {
			createAuditLog({
				action: "When entering to edit a user",
				module: "Users",
				option: "User edit",
				detail: `Viewed the user ${user?.email}`,
				actionCode: "WEB_USRS_USR_VIEW",
				appType: "WEB_BACK_OFFICE",
				createdBy: userInfo?.id || "",
			});
			createAuditlogCalledRef.current = true;
		}
	}, [userInfo, user]);

	return (
		<div className="w-full overflow-y-auto scroll-smooth">
			<div className="container">
				<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							width: "100%",
						}}
					>
						<Breadcrumbs
							items={[
								{
									label: "user list",
									href: "/users",
									isLink: true,
								},
								{ label: "edit user", isLink: false },
							]}
						/>
						<Typography color="text.dark2" variant="headlineSmall">
							Edit user
						</Typography>
					</Box>

					<Card
						sx={{
							minWidth: 275,
							width: "100%",
							minHeight: 826,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<CardContent className="space-y-5" sx={{ flexGrow: 1 }}>
							{isLoading && (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									height="240px"
								>
									<CircularProgress />
								</Box>
							)}
							{user && (
								<UserInformation
									user={user}
									isEditMode={true}
									onSubmit={onSubmit}
									showConfirmationState={showConfirmationState}
									generalErrorMessageState={generalErrorMessageState}
								/>
							)}
						</CardContent>
						<CardActions sx={{ borderTop: "1px solid #e5e7eb" }}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									width: "100%",
									gap: "0.5rem",
									padding: "1rem",
								}}
							>
								<Button
									size="large"
									onClick={() => navigate("/users")}
									variant="outlined"
								>
									CANCEL
								</Button>
								<Button
									size="large"
									color="primary"
									variant="contained"
									sx={{ textTransform: "none", textAlign: "center" }}
									disabled={createUserMutation.isPending}
									onClick={() => {
										showConfirmationState[1](true);
									}}
								>
									SAVE
								</Button>
							</Box>
						</CardActions>
					</Card>
				</div>
			</div>
			{!!generalErrorMessageState[0] && (
				<Snackbar
					open={true}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					autoHideDuration={6000}
					onClose={() => {
						generalErrorMessageState[1]("");
					}}
				>
					<Alert
						onClose={() => {
							generalErrorMessageState[1]("");
						}}
						severity="error"
						variant="filled"
						sx={{ width: "100%" }}
					>
						{generalErrorMessageState[0]}
					</Alert>
				</Snackbar>
			)}
		</div>
	);
};

export default EditUser;
