import { useEffect, useState } from "react";
import {
	DataTableCell,
	Order,
	ComparableValue,
	DataTableCellValue,
} from "./dataTable.interface";
import React from "react";

const getComparableValue = (value: ComparableValue): string | number => {
	if (typeof value === "object" && value !== null && "id" in value) {
		if (!isNaN(Number((value as DataTableCellValue).id))) {
			return Number((value as DataTableCellValue).id);
		}
		return (value as DataTableCellValue).id.toString();
	}
	if (React.isValidElement(value)) {
		const element = value as React.ReactElement;
		const children = element.props.children;

		if (typeof children === "string") {
			return children.trim();
		}

		if (typeof children === "number") {
			return children;
		}

		if (Array.isArray(children)) {
			const rValue = children
				.map((child) => getComparableValue(child as ComparableValue))
				.join(" ")
				.trim();
			const parts = rValue.split(" ");
			if (parts.length > 1 && !isNaN(Number(parts[0]))) {
				return Number(parts[0]);
			}
			return rValue;
		}

		if (React.isValidElement(element.props.label)) {
			return getComparableValue(element.props.label);
		}

		return "";
	}

	if (typeof value === "string" || typeof value === "number") {
		return value.toString().trim();
	}

	if (typeof value === "object" && value !== null) {
		return JSON.stringify(value).trim();
	}

	return "";
};

export default function useTable(
	data: DataTableCell[],
	initialOrder: Order,
	initialOrderBy: keyof DataTableCell,
	initialPage: number,
) {
	const [order, setOrder] = useState<Order>(initialOrder);
	const [orderBy, setOrderBy] = useState<keyof DataTableCell>(initialOrderBy);
	const [selected, setSelected] = useState<readonly number[]>([]);
	const [page, setPage] = useState(initialPage);
	const [sortedData, setSortedData] = useState<DataTableCell[]>([]);
	useEffect(() => {
		const comparator = (a: DataTableCell, b: DataTableCell) => {
			const orderA = getComparableValue(a[orderBy] as ComparableValue);
			const orderB = getComparableValue(b[orderBy] as ComparableValue);

			if (orderA < orderB) {
				return order === "asc" ? -1 : 1;
			}
			if (orderA > orderB) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		};

		setSortedData([...data].sort(comparator));
	}, [data, order, orderBy]);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataTableCell,
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = data.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = () => {
		setPage(0);
	};

	return {
		order,
		orderBy,
		selected,
		sortedData,
		page,
		handleRequestSort,
		handleSelectAllClick,
		handleClick,
		handleChangePage,
		handleChangeRowsPerPage,
	};
}
