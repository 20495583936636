import { axios } from "../utils/axios.create";
import {
	beneficiaryReportParams,
	beneficiaryReportResponse,
	providerReportParams,
	providerReportResponse,
} from "../domain/report.interface";

const getBeneficiaryReport = async ({
	programId,
	providerSiteId,
	providerId,
	providerNpi,
	beneficiary,
	beneficiaryCin,
	pageNumber,
	pageSize,
	isDownload,
}: beneficiaryReportParams): Promise<{
	data: beneficiaryReportResponse[];
	total: number;
}> => {
	const URLparams = new URLSearchParams();
	if (programId) URLparams.append("programId", programId.join(","));
	if (providerSiteId)
		URLparams.append("providerSiteId", providerSiteId.join(","));
	if (providerId) URLparams.append("providerId", providerId);
	if (providerNpi) URLparams.append("providerNpi", providerNpi);
	if (beneficiary) URLparams.append("beneficiary", beneficiary);
	if (beneficiaryCin) URLparams.append("beneficiaryCin", beneficiaryCin);
	if (pageNumber) URLparams.append("pageNumber", (pageNumber + 1).toString());
	if (pageSize) URLparams.append("pageSize", pageSize.toString());
	if (isDownload) URLparams.append("isDownload", isDownload);

	return axios
		.get(`/reports/beneficiary?${URLparams.toString()}`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

const getProviderReport = async ({
	programId,
	providerSiteId,
	providerId,
	providerNpi,
	pageNumber,
	pageSize,
	isDownload,
}: providerReportParams): Promise<{
	data: providerReportResponse[];
	total: number;
}> => {
	const URLparams = new URLSearchParams();
	if (programId) URLparams.append("programId", programId.join(","));
	if (providerSiteId)
		URLparams.append("providerSiteId", providerSiteId.join(","));
	if (providerId) URLparams.append("providerId", providerId);
	if (providerNpi) URLparams.append("providerNpi", providerNpi);
	if (pageNumber) URLparams.append("pageNumber", (pageNumber + 1).toString());
	if (pageSize) URLparams.append("pageSize", pageSize.toString());
	if (isDownload) URLparams.append("isDownload", isDownload);

	return axios
		.get(`/reports/provider?${URLparams.toString()}`)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err.response.data));
};

export const reportService = {
	getBeneficiaryReport,
	getProviderReport,
};
