export type IProgram = {
	id: string;
	name: string;
};

export type Params = {
	account: string;
	userId?: string;
};

export enum IncentiveTypes {
	GC = "GC",
	PPC = "PPC",
}
