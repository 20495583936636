export enum UserStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
}

export type IUserDelete = {
	isDeleted: boolean;
	message: string;
};

export type IUser = {
	id: string;
	account: string;
	email: string;
	firstName: string;
	lastName: string;
	status: UserStatus;
	phoneNumber: string;
	npi: string;
	roles: string[];
	programs: IUserPrograms[];
};

export type IUserPrograms = {
	programId: string;
	orgUnitId: {
		activeEnrollmentsCount: number;
		orgUnitId: string;
	}[];
};

export type IUserWithId = IUser & {
	id: string;
};

export type ParamsDelete = {
	userId: string;
};

export type Params = {
	account: string;
};

export type IUserResponse = {
	statusCode: number;
	body: IUserWithId[];
};

export interface IUserPayload {
	account: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	npi: string;
	status: string;
	roles: string[];
	programs: {
		programId: string;
		orgUnitId: string[];
	}[];
}

export type IUserPayloadId = IUserPayload & {
	id: string;
};

export interface IProgramOrganizationsGroup {
	organizationIdsList?: string[] | undefined;
	programId: string;
}

export interface ICreateUserPayload {
	npi?: string | undefined;
	programOrganizationsGroupList?: IProgramOrganizationsGroup[] | undefined;
	roleId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	status: string;
}
