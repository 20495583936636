import createBeneficiarySchema from "../../../domain/beneficiary.schema";
import { TFunction } from "i18next";
import * as yup from "yup";

const createValidationSchema = (
	t: TFunction,
	options: {
		isEligibility: boolean;
		displayBeneficiaryName: boolean;
		displayBeneficiaryAddress: boolean;
		displayBeneficiaryCMEligible: boolean;
		beneficiaryCINFormat: string;
		isNewReenroll: boolean;
		minAgeValidation: number;
		displayBeneficiaryDob: boolean;
	},
) => {
	return yup.lazy(() => {
		const defaultSchema = createBeneficiarySchema(t, options);

		const reenrollmentValidation = (): yup.MixedSchema => {
			return options.isNewReenroll
				? yup
						.mixed()
						.required(
							t("validations.required_field", { field: "Consent form" }),
						)
				: yup.mixed();
		};

		const reEnrollSchema = yup.object().shape({
			file: reenrollmentValidation(),
		});

		return defaultSchema.concat(reEnrollSchema);
	});
};

export default createValidationSchema;
