import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
	AppState,
	Auth0Provider,
	Auth0ProviderOptions,
} from "@auth0/auth0-react";
import { getConfig } from "./config";
import history from "./utils/history";

const onRedirectCallback = (appState: AppState | undefined) => {
	history.push(
		appState && appState.returnTo
			? appState.returnTo
			: window.location.pathname,
	);
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig: Auth0ProviderOptions = {
	domain: config.domain,
	clientId: config.clientId,
	onRedirectCallback,
	authorizationParams: {
		organization: config.organization,
		redirect_uri: `${window.location.origin}/callback`,
		...(config.audience ? { audience: config.audience } : null),
	},
	useRefreshTokens: true,
	cacheLocation: "localstorage",
};

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);
root.render(
	<React.StrictMode>
		<Auth0Provider {...providerConfig}>
			<App />
		</Auth0Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
