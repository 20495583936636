import {
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import { userService } from "../../services/user.service";
import { IUser, IUserDelete, IUserPayload } from "@/domain/user.interface";
import { rolService } from "../../services/role.service";

const {
	findAll: findAllUsers,
	deleteOne,
	findById,
	updateById,
	create,
} = userService;

const { findActiveRolesByAccount } = rolService;

export const useGetUsersApp = (
	account: string,
	initialData = [],
): UseQueryResult<IUser[]> => {
	const userQuery = useQuery({
		queryKey: ["get-user-list"],
		queryFn: async () => await findAllUsers({ account }),
		initialData,
	});

	return userQuery;
};

export const useDeleteUser = (): UseMutationResult<
	IUserDelete,
	Error,
	string,
	unknown
> => {
	const userQuery = useMutation({
		mutationFn: async (userId: string) => await deleteOne({ userId }),
	});

	return userQuery;
};

export const useGetUserById = (
	userId: string,
): UseQueryResult<IUser, Error> => {
	const userQuery = useQuery<IUser, Error>({
		queryKey: ["get-user-by-id", userId],
		queryFn: async () => await findById(userId),
		staleTime: 0,
	});

	return userQuery;
};

export const useUpdateUser = ({ userId }: { userId: string }) => {
	return useMutation({
		mutationKey: ["update-user"],
		mutationFn: (data: Partial<IUserPayload>) => updateById(data, userId),
	});
};

export const useGetActiveRolesByAccount = (account: string) => {
	return useQuery({
		queryKey: ["sims-account-roles"],
		queryFn: () => findActiveRolesByAccount(account),
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});
};

export const useCreateUser = () => {
	return useMutation({
		mutationFn: (data: IUserPayload) => create(data),
	});
};
