import React, { useRef } from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { PDFGeneratorContext } from "./PDFGeneratorContext";

const PDFGeneratorProvider = ({ children }: { children: React.ReactNode }) => {
	const downloadRef = useRef(null);

	const handleBase64Certificate = (callback: (base64: string) => void) => {
		const input = downloadRef.current;
		if (input) {
			const pdf = new jsPDF("landscape", "pt", "a4");

			pdf.addImage(
				"/assets/certificate-background.png",
				"JPEG",
				0,
				0,
				842,
				595,
			);
			pdf.html(input, {
				callback: (pdf) => {
					const pdfBase64 = pdf.output("datauristring");
					callback(pdfBase64);
				},
				x: 121,
				y: 192,
			});
		}
	};

	const handleDownloadCertificate = (fileName: string) => {
		const input = downloadRef.current;
		if (input) {
			const pdf = new jsPDF("landscape", "pt", "a4");

			pdf.addImage(
				"/assets/certificate-background.png",
				"JPEG",
				0,
				0,
				842,
				595,
			);
			pdf.html(input, {
				callback: (pdf) => {
					pdf.save(fileName);
				},
				x: 121,
				y: 192,
			});
		}
	};

	const generateTablePDF = (
		headers: string[],
		// TODO: use same type when defined above
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data: any[],
		fileName: string,
	) => {
		const doc = new jsPDF("landscape", "pt", "a4");

		autoTable(doc, {
			head: [headers],
			body: data,
		});

		doc.save(fileName);
	};
	return (
		<PDFGeneratorContext.Provider
			value={{
				downloadRef,
				handleDownloadCertificate,
				handleBase64Certificate,
				generateTablePDF,
			}}
		>
			{children}
		</PDFGeneratorContext.Provider>
	);
};

export default PDFGeneratorProvider;
