import React from "react";
import { FC } from "react";
import { Chip, GridOwnProps, Stack, Typography } from "@mui/material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import KPICard from "../../components/KpiCards/KpiCard";

type Props = {
	title: string;
	content: string | number;
	footer?: {
		increased?: boolean;
		label?: string;
		description?: string;
		hidden?: boolean;
	};
	gridProps?: GridOwnProps;
};

export const BasicKPIComponent: FC<Props> = (props) => {
	return (
		<KPICard gridProps={props.gridProps} boxProps={{ height: 200 }}>
			<Stack
				direction="column"
				justifyContent={"space-between"}
				height={"100%"}
			>
				<Typography variant="subtitle1" color="textSecondary" fontWeight={700}>
					{props.title}
				</Typography>
				<Typography variant="h3" align="center">
					{props.content}
				</Typography>

				{props.footer && (
					<Stack
						direction="row"
						spacing={1}
						alignItems={"center"}
						visibility={props.footer.hidden ? "hidden" : "visible"}
					>
						<Chip
							icon={
								props.footer?.increased ? (
									<NorthEastIcon sx={{ maxHeight: "16px", maxWidth: "16px" }} />
								) : (
									<SouthEastIcon sx={{ maxHeight: "16px", maxWidth: "16px" }} />
								)
							}
							label={props.footer?.label}
						/>
						<Typography variant="body2" color="textSecondary">
							{props.footer?.description}
						</Typography>
					</Stack>
				)}
			</Stack>
		</KPICard>
	);
};

export default BasicKPIComponent;
