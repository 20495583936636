import { CardOrder } from "@/domain/cardOrders.interface";
import React, {
	createContext,
	useContext,
	useState,
	FC,
	ReactNode,
} from "react";
import { useGetOrderDetails } from "../../../hooks/useCards";

type ViewOrderModalContextType = {
	openModal: (orderId: string) => void;
	closeModal: () => void;
	isOpen: boolean;
	order: CardOrder | null;
	isLoading: boolean;
};

const ViewOrderModalContext = createContext<ViewOrderModalContextType | null>(
	null,
);

export const useViewOrderModal = () => {
	const context = useContext(ViewOrderModalContext);
	if (!context) {
		throw new Error(
			"useViewOrderModal must be used within a ViewOrderModalProvider",
		);
	}
	return context;
};

export const ViewOrderModalProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [orderId, setOrderId] = useState<string | null>(null);

	const { data: order, isLoading, refetch } = useGetOrderDetails(orderId || "");

	const openModal = (orderId: string) => {
		setOrderId(orderId);
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
		setOrderId(null);
	};

	React.useEffect(() => {
		if (isOpen && orderId) {
			refetch();
		}
	}, [isOpen, orderId, refetch]);

	return (
		<ViewOrderModalContext.Provider
			value={{ openModal, closeModal, isOpen, order: order || null, isLoading }}
		>
			{children}
		</ViewOrderModalContext.Provider>
	);
};
