/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: review this file it appears to be failing the tests when removing some of the imports
import React, { useState, useEffect, useMemo } from "react";
import { Box, Divider, Grid, Typography, Button, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import BasicSelect from "../../components/BasicSelect";
import TreeView from "../../components/TreeView/Index";
import { TreeItem } from "../../domain/common.interface";
import { useQueryClient } from "@tanstack/react-query";
import { useUserStore } from "../../stores/user.store";
import { Program } from "../../domain/beneficiary.interface";
import { useGetOrganizationList } from "../../hooks/useOrganizations";

interface Filters {
	programId: string;
	providerId: string;
	providerSiteId: string;
	status: string[];
	search: string;
	pageNumber: number;
	pageSize: number;
}

interface HeaderProps {
	filtersState: [Filters, React.Dispatch<React.SetStateAction<Filters>>];
	handleSearch: () => void;
}

const Header: React.FC<HeaderProps> = ({ filtersState, handleSearch }) => {
	const queryClient = useQueryClient();
	const { userInfo } = useUserStore();
	const [filters, setFilters] = filtersState;
	const [showFilters, setShowFilters] = useState(false);

	const [programsOptions, setProgramsOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [providerOptions, setProviderOptions] = useState<
		Array<{ key: string; value: string }>
	>([]);
	const [providerSitesOptions, setProviderSitesOptions] = useState<
		Array<TreeItem>
	>([]);
	const [userProgram, setUserProgram] = useState<Program | undefined>();

	const toggleFilters = () => {
		setShowFilters(!showFilters);
	};

	// @ts-expect-error userInfo is not undefined
	const { data: dataOrganization } = useGetOrganizationList(userInfo.account);

	const organizationsAssigned = useMemo(() => {
		const userOrganizations = userInfo?.programs.flatMap((program) =>
			program.orgUnitId.map((o) => (typeof o === "string" ? o : o.orgUnitId)),
		);

		return (
			dataOrganization?.filter((org) => userOrganizations?.includes(org.id)) ||
			[]
		);
	}, [userInfo, dataOrganization]);

	return (
		<Box marginBottom={2}>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} md={6}>
					<Stack direction="column">
						<Typography variant="h4" fontWeight={700} gutterBottom>
							Hello, {userInfo?.firstName} {userInfo?.lastName}!
						</Typography>
						<Typography
							variant="titleSmall"
							color={(theme) => theme.palette.textMain.main2}
						>
							{organizationsAssigned.map((org) => org.name).join(", ")}
						</Typography>
						<Typography
							variant="bodyLarge"
							color={(theme) => theme.palette.textMain.main2}
						>
							Here is what is going on today.
						</Typography>
					</Stack>
				</Grid>
				{false && (
					<Grid
						item
						xs={12}
						md={6}
						container
						spacing={2}
						justifyContent="flex-end"
					>
						<Grid item>
							<Button
								variant="contained"
								startIcon={<FilterListIcon />}
								onClick={toggleFilters}
								sx={{
									"&:hover": {
										bgcolor: "textMain.main",
									},
									color: "textMain.dark2",
									bgcolor: "surface.main",
									height: "100%",
									borderRadius: "buttonRadius.main",
									borderColor: "icon.main",
								}}
							>
								{showFilters ? "Hide All Filters" : "Show All Filters"}
							</Button>
						</Grid>
						<Grid item xs={6}>
							<BasicSelect
								sx={{
									backgroundColor: "white",
								}}
								handleChange={(value) => {
									setFilters((filters) => ({
										...filters,
										programId: value as string,
									}));
									setUserProgram(
										userInfo?.programs?.find(
											(program) => program.programId === value,
										) as unknown as Program,
									);

									queryClient.removeQueries({
										queryKey: ["sims-beneficiaries-provider-sites"],
									});

									queryClient.removeQueries({
										queryKey: ["sims-beneficiaries-providers"],
									});

									setFilters((filters) => ({
										...filters,
										providerSiteId: "",
										providerId: "",
										pageNumber: 0,
									}));

									setProviderSitesOptions([]);
									setProviderOptions([]);

									if (value === "all") {
										setUserProgram(undefined);
									}
								}}
								data={programsOptions || []}
								value={filters.programId}
								label="Filter by program"
								id="beneficiary-program"
								isLoading={false}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			{showFilters && (
				<Box marginTop={2} marginBottom={2}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12} sm={6} md={3}>
							<TreeView
								textFieldProps={{
									sx: {
										backgroundColor: "white",
									},
								}}
								key={filters.programId}
								label="Filter by OU"
								selectedId={filters.providerSiteId}
								expanded
								onSelect={(id) => {
									queryClient.removeQueries({
										queryKey: ["sims-beneficiaries-providers"],
									});

									setFilters((filters) => ({
										...filters,
										providerSiteId: id,
										providerId: "",
										pageNumber: 0,
									}));

									setProviderOptions([]);
								}}
								data={providerSitesOptions}
								anchorEl={null}
								includeChildren
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<BasicSelect
								sx={{
									backgroundColor: "white",
								}}
								handleChange={(value) => {
									setFilters((filters) => ({
										...filters,
										providerId: value as string,
										pageNumber: 0,
									}));
								}}
								data={providerOptions || []}
								value={filters.providerId}
								isLoading={false}
								label="Filter by provider"
								id="beneficiary-provider"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<BasicSelect
								sx={{
									backgroundColor: "white",
								}}
								handleChange={(value) => {
									setFilters((filters) => ({
										...filters,
										status: value as string[],
										pageNumber: 0,
									}));
								}}
								data={[
									{ key: "ACTIVE", value: "Active" },
									{ key: "COMPLETED", value: "Completed" },
									{ key: "PAUSED", value: "Paused" },
									{ key: "DISENROLLED", value: "Disenrolled" },
								]}
								value={filters.status}
								label="Filter by status"
								id="beneficiary-status"
								multiple
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Button
								size="large"
								color="primary"
								variant="contained"
								sx={{
									borderRadius: "buttonRadius.main",
									textTransform: "none",
									height: "3rem",
									textAlign: "center",
								}}
								onClick={handleSearch}
							>
								Search
							</Button>
						</Grid>
					</Grid>
				</Box>
			)}
			<div className="py-5">
				<Divider
					sx={{
						bgcolor: "surface.main",
						borderWidth: 2,
						position: "absolute",
						left: 0,
						right: 0,
					}}
				/>
			</div>
		</Box>
	);
};

export default Header;
