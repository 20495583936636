import {
	createTheme,
	PaletteOptions,
	ThemeOptions,
	useTheme,
} from "@mui/material";

import { TypographyOptions } from "@mui/material/styles/createTypography";

type CustomPaletteOptions = {
	main: string;
	main2?: string;
	main3?: string;
	light: string;
	dark: string;
	light2?: string;
	dark2?: string;
	light3?: string;
	dark3?: string;
	light4?: string;
	dark4?: string;
};

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		surface: CustomPaletteOptions;
		accent: CustomPaletteOptions;
		icon: CustomPaletteOptions;
		outline: CustomPaletteOptions;
		menu: CustomPaletteOptions;
		textMain: CustomPaletteOptions;
		textPrimary: CustomPaletteOptions;
		textSecondary: CustomPaletteOptions;
		textLinkHelpSupport: CustomPaletteOptions;
	}
	interface PaletteOptions {
		surface: CustomPaletteOptions;
		accent: CustomPaletteOptions;
		icon: CustomPaletteOptions;
		outline: CustomPaletteOptions;
		menu: CustomPaletteOptions;
		textMain: CustomPaletteOptions;
		textPrimary: CustomPaletteOptions;
		textSecondary: CustomPaletteOptions;
		textLinkHelpSupport: CustomPaletteOptions;
	}
}

export interface ExtendedPaletteOptions extends PaletteOptions {
	surface: CustomPaletteOptions;
	accent: CustomPaletteOptions;
	icon: CustomPaletteOptions;
	outline: CustomPaletteOptions;
	menu: CustomPaletteOptions;
	textMain: CustomPaletteOptions;
	textPrimary: CustomPaletteOptions;
	textSecondary: CustomPaletteOptions;
	textLinkHelpSupport: CustomPaletteOptions;
	buttonRadius?: CustomPaletteOptions;
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		display: true;
		headlineLarge: true;
		headlineMedium: true;
		headlineSmall: true;
		inputHelper: true;
		titleMedium: true;
		titleSmall: true;
		bodyLarge: true;
		bodyLargeBold: true;
		bodyMedium: true;
		bodySmall: true;
		labelLarge: true;
		labelMedium: true;
		labelSmall: true;
		titleTableHeader: true;
		bodyOptionHelp: true;
		titleSmallOptionHelp: true;
	}
}

export interface ExtendedTypographyOptions extends TypographyOptions {
	display?: React.CSSProperties;
	headlineLarge?: React.CSSProperties;
	headlineMedium?: React.CSSProperties;
	headlineSmall?: React.CSSProperties;
	titleMedium?: React.CSSProperties;
	titleSmall?: React.CSSProperties;
	bodyLarge?: React.CSSProperties;
	bodyLargeBold?: React.CSSProperties;
	bodyMedium?: React.CSSProperties;
	bodySmall?: React.CSSProperties;
	labelLarge?: React.CSSProperties;
	labelMedium?: React.CSSProperties;
	labelSmall?: React.CSSProperties;
	titleTableHeader?: React.CSSProperties;
	bodyOptionHelp?: React.CSSProperties;
	titleSmallOptionHelp?: React.CSSProperties;
}

export const themePalette = {
	mode: "light",
	primary: {
		main: "#6F3996",
		light: "#BAA3CA",
		dark: "#52197B",
	},
	secondary: {
		main: "#4CB7A4",
		light: "#E7EFB8",
		dark: "#005757",
	},
	error: {
		main: "#AB0707",
		light: "#FCD6D0",
		dark: "#AB0707",
	},
	warning: {
		main: "#F8955B",
		light: "#FFE7E0",
		dark: "#F26716",
	},
	surface: {
		main: "#F7F4F9",
		light: "#FFFFFF",
		dark: "#E9D0F4",
	},
	accent: {
		main: "#52197B",
	},
	icon: {
		main: "#ADA6B3",
		light: "#D9D1DD",
		dark: "#4C4C56",
		dark2: "#52197B",
	},
	outline: {
		main: "#D9D1DD",
		light: "#E4DEE9",
		dark: "#ADA6B3",
		dark2: "#4C4C56",
	},
	menu: {
		main: "#52197B",
		light: "#8A54B2",
		light2: "#6F3996",
	},
	textMain: {
		main: "#E5E6E6",
		main2: "#79797D",
		main3: "#BAA3CA",
		light: "#FFFFFF",
		dark: "#CCCCCE",
		light2: "#FCFCFC",
		dark2: "#1F2026",
		light3: "#F8F8F8",
		dark3: "#F26716",
		light4: "#F5F5F5",
		dark4: "#005757",
	},
	textPrimary: {
		main: "#1F2026",
		light: "#1F2026",
	},
	textSecondary: {
		main: "#79797D",
		light: "#BAA3CA",
	},
	textLinkHelpSupport: {
		main: "#3a6364",
	},
	buttonRadius: {
		main: "8px",
	},
};
export const themeTypography = {
	display: {
		fontWeight: "900",
		fontSize: "4rem",
		lineHeight: "5.5rem",
	},
	headlineLarge: {
		fontWeight: "bold",
		fontSize: "3rem",
		lineHeight: "4rem",
	},
	headlineMedium: {
		fontWeight: "bold",
		fontSize: "2rem",
		lineHeight: "2.5rem",
	},
	headlineSmall: {
		fontWeight: "bold",
		fontSize: "1.5rem",
		lineHeight: "2rem",
	},
	inputHelper: {
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "166%" /* 19.92px */,
		letterSpacing: "0.4px",
	},
	titleMedium: {
		fontWeight: "900",
		fontSize: "1.25rem", // 20px
		lineHeight: "1.5rem",
	},
	titleSmall: {
		fontWeight: "700",
		fontSize: "18px",
		lineHeight: "1.25rem",
	},
	titleTableHeader: {
		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "1.5rem",
	},
	titleSmallOptionHelp: {
		fontWeight: "700",
		fontSize: "18px",
		lineHeight: "1.5rem",
	},
	bodyLarge: {
		fontWeight: "normal",
		fontSize: "1rem", //16px
		lineHeight: "1.5rem",
	},
	bodyLargeBold: {
		fontWeight: "bold",
		fontSize: "1rem", //16px
		lineHeight: "1.5rem",
	},
	bodyMedium: {
		fontWeight: "normal",
		fontSize: "0.875rem",
		lineHeight: "1.25rem",
	},
	bodySmall: {
		fontWeight: "normal",
		fontSize: "0.75rem",
		lineHeight: "1rem",
	},
	bodyOptionHelp: {
		fontWeight: "normal",
		fontSize: "1rem",
		lineHeight: "1.5rem",
	},
	labelLarge: {
		fontWeight: "300",
		fontSize: "1rem",
		lineHeight: "1.5rem",
	},
	labelMedium: {
		fontWeight: "300",
		fontSize: "0.75rem",
		lineHeight: "1.5rem",
	},
	labelSmall: {
		fontWeight: "300",
		fontSize: "11px",
		lineHeight: "1rem",
	},
};

const theme = createTheme({
	palette: themePalette as ExtendedPaletteOptions,
	typography: themeTypography as ExtendedTypographyOptions,
} as ThemeOptions);

export default theme;

export type AppTheme = typeof theme;

export const useAppTheme = () => useTheme<AppTheme>();
