import { axios } from "../utils/axios.create";
import { RoleItem } from "../domain/rol.interface";

const findActiveRolesByAccount = async (
	account: string,
): Promise<{ id: string; name: string; type: string; level: number }[]> => {
	return axios
		.get(`/account/${account}/roles`)
		.then((res) => {
			const roles = res.data.body as RoleItem[];
			return roles
				.filter((rol: RoleItem) => rol.status.toUpperCase() === "ACTIVE") // Filter only active roles
				.map((rol: RoleItem) => {
					return {
						id: rol.id,
						name: rol.name,
						type: rol.type,
						level: rol.level,
					};
				});
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

export const rolService = {
	findActiveRolesByAccount,
};
