import React from "react";
import { BeneficiaryResponse } from "../../../domain/beneficiary.interface";

type ModalContextType = {
	openModal: (beneficiary?: BeneficiaryResponse) => void;
	closeModal: () => void;
	isBeneficiaryPage: boolean;
	isReenrolled: boolean;
	showButton: () => void;
	hideButton: () => void;
	setIsReenrolled: (isReenrolled: boolean) => void;
};

export const RegisterBeneficiaryModalContext =
	React.createContext<ModalContextType | null>(null);

export const useRegisterBeneficiaryModal = () => {
	const context = React.useContext(RegisterBeneficiaryModalContext);
	if (!context) {
		throw new Error(
			"useRegisterBeneficiaryModal must be used within a RegisterBeneficiaryModalProvider",
		);
	}
	return context;
};
