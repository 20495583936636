import { RoleItem } from "@/domain/rol.interface";
import { axios } from "../utils/axios.create";
import {
	IUser,
	IUserDelete,
	ParamsDelete,
	Params,
	IUserPayload,
} from "@/domain/user.interface";
import { IAction } from "@/domain/action.interface";

const findAll = async (params: Params): Promise<IUser[]> => {
	return axios
		.get(`/account/${params.account}/users`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const deleteOne = async (params: ParamsDelete): Promise<IUserDelete> => {
	return axios
		.delete(`/users/${params.userId}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const findById = async (userId: string): Promise<IUser> => {
	return axios
		.get(`/users/${userId}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

export interface IRoleAction extends Omit<RoleItem, "actions" | "level"> {
	actions: IAction[];
}
export interface IUserRole extends Omit<IUser, "roles"> {
	roles: IRoleAction[];
}

const findByEmail = async (email: string): Promise<IUserRole> => {
	return axios
		.get(`/user-info?${new URLSearchParams({ email }).toString()}`)
		.then((res) => {
			return res.data.body;
		})
		.catch((err) => {
			return Promise.reject(err.response.data);
		});
};

const updateById = async (
	data: Partial<IUserPayload>,
	userId: string,
): Promise<IUserPayload> => {
	return axios
		.put(`/user/${userId}`, data)
		.then((res) => res.data.body)
		.catch((err) => Promise.reject(err));
};

const create = async (data: IUserPayload): Promise<IUser> => {
	return axios
		.post("/user", data)
		.then((res) => res.data.body)
		.catch((err) => {
			return Promise.reject(err);
		});
};

export const userService = {
	findAll,
	deleteOne,
	findById,
	findByEmail,
	updateById,
	create,
};
