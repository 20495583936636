import React, { FC, useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	MenuItem,
	IconButton,
	Box,
	Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useOrderModal } from "./OrderModalContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetOrganizationList } from "../../../hooks/useOrganizations";
import { useCreateOrder } from "../../../hooks/useCards";
import { useCreateAuditlog } from "../../../hooks/useAuditlog";
import { useUserStore } from "../../../stores/user.store";
import { useAppTheme } from "../../../utils/theme";
import { useTranslation } from "react-i18next";
import { IncentiveTypes } from "../../../domain/program.interface";

interface OrderFormInputs {
	ou: string;
	numberOfCards: number;
}

const schema = yup.object().shape({
	ou: yup.string().required("Organization Unit is required"),
	numberOfCards: yup
		.number()
		.required("Number of cards is required")
		.positive("Number of cards must be a positive number")
		.integer("Number of cards must be an integer")
		.max(500, "Maximum number of cards is 500"),
});

const AddOrderModal: FC = () => {
	const { t } = useTranslation("cards");
	const { isOpen, closeModal } = useOrderModal();
	const { userInfo } = useUserStore();
	const createAuditlogMutation = useCreateAuditlog();
	const createOrderMutation = useCreateOrder();
	const theme = useAppTheme();
	const { control, handleSubmit, reset } = useForm<OrderFormInputs>({
		resolver: yupResolver(schema),
		defaultValues: {
			ou: "",
			numberOfCards: 0,
		},
	});
	const [organizations, setOrganizations] = useState<
		Array<{ key: string; value: string }>
	>([]);

	const { data: organizationData, isLoading } = useGetOrganizationList(
		userInfo?.account ?? "",
	);

	useEffect(() => {
		if (organizationData) {
			const orgOptions = organizationData
				.filter((o) =>
					o.programs.some((p) => p.sims.incentiveType === IncentiveTypes.PPC),
				)
				.map((org) => ({
					key: org.id,
					value: org.name,
				}));
			setOrganizations(orgOptions);
		}
	}, [organizationData]);

	const onSubmit: SubmitHandler<OrderFormInputs> = async (data) => {
		try {
			const newOrder = await createOrderMutation.mutateAsync({
				orgUnitId: data.ou,
				totalOrder: data.numberOfCards,
				userId: userInfo?.id || "",
			});
			closeModal();
			reset();
			await createAuditlogMutation.mutateAsync({
				module: "Phyiscal Prepaidcard",
				action: "When adding a new Order",
				detail: `New Order ${newOrder.orderId} was created`,
				actionCode: "WEB_ADD_CARD_ORDER",
				option: "Order Add",
				createdBy: userInfo?.id || "",
			});
		} catch (error) {
			closeModal();
			reset();
			throw new Error("Failed to create order");
		} finally {
			closeModal();
			reset();
		}
	};

	const handleClose = () => {
		reset({ ou: "", numberOfCards: 0 });
		closeModal();
	};

	return (
		<Dialog
			open={isOpen || false}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
		>
			<Box sx={{ position: "relative" }}>
				<DialogTitle
					sx={{
						bgcolor: theme.palette.primary.dark,
						color: theme.palette.surface.light,
					}}
				>
					<Typography variant="headlineSmall">{t("add_new_order")}</Typography>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: theme.palette.surface.light,
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
				>
					<Controller
						name="ou"
						control={control}
						render={({ field, fieldState }) => (
							<TextField
								{...field}
								value={field.value || ""}
								label={t("organization_unit")}
								select
								fullWidth
								margin="normal"
								error={!!fieldState.error}
								helperText={fieldState.error?.message}
							>
								<MenuItem value="">
									<em></em>
								</MenuItem>
								{!isLoading &&
									organizations.map((org) => (
										<MenuItem key={org.key} value={org.key}>
											{org.value}
										</MenuItem>
									))}
							</TextField>
						)}
					/>
					<Controller
						name="numberOfCards"
						control={control}
						render={({ field, fieldState }) => (
							<TextField
								{...field}
								label={t("number_of_cards")}
								type="number"
								fullWidth
								margin="normal"
								error={!!fieldState.error}
								helperText={fieldState.error?.message}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions sx={{ padding: "1rem" }}>
					<Button
						size="large"
						onClick={handleClose}
						variant="outlined"
						sx={{ textTransform: "uppercase" }}
					>
						{t("cancel")}
					</Button>
					<Button
						size="large"
						disabled={createOrderMutation.isPending}
						onClick={handleSubmit(onSubmit)}
						variant="contained"
						color="primary"
						sx={{ textTransform: "uppercase", marginLeft: "0.5rem" }}
					>
						{t("send_order")}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrderModal;
