import { styled } from "@mui/material/styles";
import ListItemButton, {
	ListItemButtonProps,
} from "@mui/material/ListItemButton";

export interface MenuItemButtonProps extends ListItemButtonProps {
	open?: boolean;
	active?: boolean;
}

export const MenuItemButton = styled(ListItemButton, {
	shouldForwardProp: (prop) => !["open", "active"].includes(prop.toString()),
})<MenuItemButtonProps>(({ theme, active }) => ({
	borderRadius: "8px",
	":hover": {
		backgroundColor: theme.palette.menu.light,
		color: theme.palette.surface.light,
		".MuiListItemIcon-root": {
			color: theme.palette.surface.light,
		},
	},
	backgroundColor: active ? theme.palette.menu.light : "transparent",
	color: active ? theme.palette.surface.light : theme.palette.primary.light,
	".MuiListItemIcon-root": {
		color: active ? theme.palette.surface.light : theme.palette.primary.light,
	},
}));
