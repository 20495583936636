import React, { ReactNode, useState } from "react";
import RegisterBeneficiaryModal from ".";
import { RegisterBeneficiaryModalContext } from "./RegisterBeneficiaryModalContext";
import { BeneficiaryResponse } from "../../../domain/beneficiary.interface";

export const RegisterBeneficiaryModalProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isBeneficiaryPage, setIsBeneficiaryPage] = useState(false);
	const [isRegister, setIsRegister] = useState(true);
	const [isReenrolled, setIsReenrolled] = useState(false);
	const [beneficiary, setBeneficiary] = useState<
		BeneficiaryResponse | undefined
	>(undefined);

	const openModal = (beneficiary?: BeneficiaryResponse) => {
		setIsOpen(true);
		if (beneficiary) {
			setIsRegister(false);
		}
		setBeneficiary(beneficiary);
	};
	const closeModal = () => {
		setIsOpen(false);
		setIsRegister(true);
	};

	const showButton = () => setIsBeneficiaryPage(true);
	const hideButton = () => setIsBeneficiaryPage(false);

	return (
		<RegisterBeneficiaryModalContext.Provider
			value={{
				openModal,
				closeModal,
				isBeneficiaryPage,
				isReenrolled,
				setIsReenrolled,
				showButton,
				hideButton,
			}}
		>
			{children}
			{isOpen && (
				<RegisterBeneficiaryModal
					open={isOpen}
					isRegister={isRegister}
					onClose={closeModal}
					beneficiary={beneficiary}
					onReenroll={() => setIsReenrolled(true)}
				/>
			)}
		</RegisterBeneficiaryModalContext.Provider>
	);
};
