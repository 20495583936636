"use client";

import React, { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { FeedBackProvider } from "../providers/FeedBackProvider/FeedBackProvider";
import PDFGeneratorProvider from "../providers/PDFGeneratorProvider/PDFGeneratorProvider";
import XlsGeneratorProvider from "../providers/XlsGeneratorProvider/XlsGeneratorProvider";
import { RegisterBeneficiaryModalProvider } from "../components/modals/RegisterBeneficiary/RegisterBeneficiaryModalProvider";
import Spinner from "../components/Spinner";

function Providers({ children }: React.PropsWithChildren) {
	const [client] = React.useState(new QueryClient());

	return (
		<QueryClientProvider client={client}>
			<ThemeProvider theme={theme}>
				<FeedBackProvider>
					<PDFGeneratorProvider>
						<XlsGeneratorProvider>
							<RegisterBeneficiaryModalProvider>
								<Suspense fallback={<Spinner />}>{children}</Suspense>
							</RegisterBeneficiaryModalProvider>
						</XlsGeneratorProvider>
					</PDFGeneratorProvider>
				</FeedBackProvider>
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}

export default Providers;
