import { FC, useEffect, useRef, useState } from "react";
import { useAppTheme } from "../../utils/theme";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SearchOff } from "@mui/icons-material";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import { useUserStore } from "../../stores/user.store";
import {
	useDownloadProviderReport,
	useGetProviderReport,
} from "../../hooks/useReports";
import { useCurrencySetting } from "../../hooks/useSetting";
import { useCreateAuditlog } from "../../hooks/useAuditlog";
import { headCellsConfig } from "../../utils/tableConfig";
import { useXlsGenerate } from "../../providers/XlsGeneratorProvider/XlsGeneratorContext";
import { usePDFGenerate } from "../../providers/PDFGeneratorProvider/PDFGeneratorContext";
import DataTable, {
	DataTableCell,
	DataTableCellValue,
	HeadCell,
} from "../../components/DataTable";
import ReportFilters, { ReportFiltersProps } from "./ReportFilters";
import { buttonExport, runReportButton, searchOffIcon } from "./Report.styles";
import { useQueryClient } from "@tanstack/react-query";
import { formatCommaSeparatedNumber } from "../../utils/general";

interface ExtendedReportFiltersProps extends ReportFiltersProps {
	pageSize: number;
	pageNumber: number;
}

const ProviderReport: FC = () => {
	const theme = useAppTheme();
	const queryClient = useQueryClient();
	const { userInfo } = useUserStore();
	const currencySetting = useCurrencySetting();
	const createAuditlogMutation = useCreateAuditlog();

	const userProgramsIds =
		userInfo?.programs.map((program) => program.programId) || [];

	const programsDefaultValue = userProgramsIds.sort().join(",") || "";

	const [filters, setFilters] = useState<ReportFiltersProps>({
		programId: [programsDefaultValue],
		providerSiteId: [],
		providerId: "",
		providerNpi: "",
		beneficiary: "",
		beneficiaryCin: "",
	});

	const [searchReport, setSearchReport] = useState(false);

	const [reportFilters, setReportFilters] =
		useState<ExtendedReportFiltersProps>({
			programId: [programsDefaultValue],
			providerSiteId: [],
			providerId: "",
			providerNpi: "",
			beneficiary: "",
			beneficiaryCin: "",
			pageSize: 10,
			pageNumber: 0,
		});

	const {
		data: providerReport,
		isLoading,
		isRefetching,
		refetch: refetchProviderList,
	} = useGetProviderReport(reportFilters, searchReport);

	const { refetch: downloadReportRefetch, isRefetching: isDownloadRefetching } =
		useDownloadProviderReport({ ...reportFilters, isDownload: "true" });

	const { exportToExcel } = useXlsGenerate();
	const { generateTablePDF } = usePDFGenerate();

	const postAuditlog = async ({
		action,
		actionDetail,
		actionCode,
	}: {
		action: string;
		actionDetail: string;
		actionCode: string;
	}) => {
		await createAuditlogMutation.mutateAsync({
			appType: "WEB_BACK_OFFICE",
			module: "Reporting",
			option: "Provider Report",
			actionCode,
			action,
			detail: actionDetail,
			createdBy: userInfo?.id || "",
		});
	};

	const postAuditlogCalledRef = useRef(false);

	useEffect(() => {
		if (!postAuditlogCalledRef.current) {
			postAuditlog({
				action: "When entering",
				actionDetail: "Access to provider report",
				actionCode: "WEB_REPORT_PROV_ACCESS",
			});
			postAuditlogCalledRef.current = true;
		}
	}, [postAuditlogCalledRef]);

	useEffect(() => {
		if (searchReport) {
			refetchProviderList();
			setSearchReport(false);
		}
	}, [searchReport]);

	useEffect(() => {
		return () => {
			queryClient.removeQueries({
				queryKey: ["sims-report-provider"],
			});
		};
	}, [queryClient]);

	const handleExportExcel = () => {
		downloadReportRefetch().then(({ data }) => {
			const reportData = data?.data || [];
			const headersMap = headCellsConfig.providerReportListView.reduce(
				(acc, header) => ({ ...acc, [header.label]: header.id }),
				{},
			);
			exportToExcel(headersMap, reportData, "provider_report");
		});
	};

	const handleExportPdf = () => {
		downloadReportRefetch().then(({ data }) => {
			const reportData = data?.data || [];
			const headers = headCellsConfig.providerReportListView.map(
				(header) => header.label,
			);
			const pdfData = reportData.map((item) => [
				item.providerName || "-",
				item.providerNpi || "-",
				item.rolName || "-",
				item.startDate || "-",
				item.pihpName || "-",
				item.providerSiteName || "-",
				item.totalEnrollments || "-",
				item.totalVisits || 0,
				item.totalNeg || 0,
				item.totalPos || 0,
				item.totalExc || 0,
				item.totalUnex || 0,
				item.totalMissed || 0,
				item.incentiveGenerated || 0,
				item.incentiveDisbursed || 0,
			]);
			generateTablePDF(headers, pdfData, "provider_report");
		});
	};

	const onChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setReportFilters((prevFilters) => ({
			...prevFilters,
			pageNumber: newPage,
		}));
		setSearchReport(true);
	};

	const onChangeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPageSize = Number(event.target.value);
		setReportFilters((prevFilters) => ({
			...prevFilters,
			pageNumber: 0,
			pageSize: newPageSize,
		}));
		setSearchReport(true);
	};

	const handleRunReport = () => {
		if (reportFilters.programId.length === 0) return;
		setSearchReport(true);
		setReportFilters({ ...filters, pageNumber: 0, pageSize: 10 });
		postAuditlog({
			action: "When searching",
			actionDetail: "provider report search",
			actionCode: "WEB_REPORT_PROV_SEARCH",
		});
	};

	const handleClearFilters = () => {
		setFilters({
			programId: [programsDefaultValue],
			providerSiteId: [],
			providerId: "",
			providerNpi: "",
			beneficiary: "",
			beneficiaryCin: "",
		});
		setReportFilters({
			programId: [programsDefaultValue],
			providerSiteId: [],
			providerId: "",
			providerNpi: "",
			beneficiary: "",
			beneficiaryCin: "",
			pageSize: 10,
			pageNumber: 0,
		});
		setSearchReport(false);
		queryClient.removeQueries({
			queryKey: ["sims-report-provider"],
		});
	};

	const onFiltersChange = (newFilters: ReportFiltersProps) => {
		setFilters(newFilters);
	};

	function createData(
		id: number,
		providerName: DataTableCellValue,
		providerNpi: DataTableCellValue,
		rolName: DataTableCellValue,
		startDate: DataTableCellValue,
		pihpName: DataTableCellValue,
		providerSiteName: DataTableCellValue,
		totalEnrollments: DataTableCellValue,
		totalVisits: DataTableCellValue,
		totalNeg: DataTableCellValue,
		totalPos: DataTableCellValue,
		totalExc: DataTableCellValue,
		totalUnex: DataTableCellValue,
		totalMissed: DataTableCellValue,
		incentiveGenerated: DataTableCellValue,
		incentiveDisbursed: DataTableCellValue,
	): DataTableCell {
		return {
			id,
			providerName,
			providerNpi,
			rolName,
			startDate,
			pihpName,
			providerSiteName,
			totalEnrollments,
			totalVisits,
			totalNeg,
			totalPos,
			totalExc,
			totalUnex,
			totalMissed,
			incentiveGenerated,
			incentiveDisbursed,
		};
	}

	const rows =
		providerReport?.data?.map((item, index) => {
			const providerName = item.providerName || "-";
			const providerNpi = item.providerNpi || "-";
			const rolName = item.rolName || "-";
			const startDate = item.startDate || "-";
			const pihpName = item.pihpName || "-";
			const providerSiteName = item.providerSiteName || "-";
			const totalEnrollments = item.totalEnrollments || "-";
			const totalVisits = item.totalVisits || 0;
			const totalNeg = item.totalNeg || 0;
			const totalPos = item.totalPos || 0;
			const totalExc = item.totalExc || 0;
			const totalUnex = item.totalUnex || 0;
			const totalMissed = item.totalMissed || 0;
			const incentiveGenerated = item.incentiveGenerated || 0;
			const incentiveDisbursed = item.incentiveDisbursed || 0;
			const row = createData(
				index,
				{
					id: providerName,
					node: (
						<div className="w-20 text-wrap overflow-ellipsis">
							{providerName}
						</div>
					),
				},
				{
					id: providerNpi,
					node: (
						<div className="w-20 text-nowrap overflow-hidden overflow-ellipsis">
							{providerNpi}
						</div>
					),
				},
				{
					id: rolName,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{rolName}
						</div>
					),
				},
				{
					id: startDate,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{startDate}
						</div>
					),
				},
				{
					id: pihpName,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{pihpName}
						</div>
					),
				},
				{
					id: providerSiteName,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{providerSiteName}
						</div>
					),
				},
				{
					id: totalEnrollments,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{totalEnrollments.toString()}
						</div>
					),
				},
				{
					id: totalVisits,
					node: (
						<div className="w-20 text-wrap overflow-hidden overflow-ellipsis">
							{totalVisits.toString()}
						</div>
					),
				},
				{
					id: totalNeg,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalNeg}
						</div>
					),
				},
				{
					id: totalPos,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalPos}
						</div>
					),
				},
				{
					id: totalExc,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalExc}
						</div>
					),
				},
				{
					id: totalUnex,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalUnex}
						</div>
					),
				},
				{
					id: totalMissed,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{totalMissed}
						</div>
					),
				},
				{
					id: incentiveGenerated,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{`${currencySetting.mask}${formatCommaSeparatedNumber(incentiveGenerated)}`}
						</div>
					),
				},
				{
					id: incentiveDisbursed,
					node: (
						<div className="w-15 text-wrap overflow-hidden overflow-ellipsis">
							{`${currencySetting.mask}${formatCommaSeparatedNumber(incentiveDisbursed)}`}
						</div>
					),
				},
			);
			return row;
		}) || [];

	const headCells: HeadCell[] = headCellsConfig.providerReportListView;

	return (
		<>
			<div className="w-full h-full overflow-y-auto scroll-smooth">
				<div className="container">
					<div className="flex flex-col items-center justify-start min-h-screen space-y-4">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								width: "100%",
							}}
						>
							<Typography variant="headlineSmall">
								Provider report <InfoOutlinedIcon />
							</Typography>
						</Box>

						<Card sx={{ minWidth: 275, width: "100%" }}>
							<CardContent className="space-y-5">
								<Box
									sx={{
										display: "flex",
										gap: "1rem",
									}}
								>
									<Button
										size="large"
										variant="outlined"
										sx={buttonExport(theme)}
										onClick={handleExportExcel}
										disabled={
											isLoading ||
											isRefetching ||
											isDownloadRefetching ||
											!providerReport?.total
										}
									>
										DOWNLOAD EXCEL
									</Button>
									<Button
										size="large"
										variant="outlined"
										sx={buttonExport(theme)}
										onClick={handleExportPdf}
										disabled={
											isLoading ||
											isRefetching ||
											isDownloadRefetching ||
											!providerReport?.total
										}
									>
										DOWNLOAD PDF
									</Button>
								</Box>
								<Box
									sx={{
										display: "grid",
										gridTemplateColumns: {
											xs: "repeat(1, 1fr)",
											sm: "repeat(2, 1fr)",
											md: "repeat(4, 1fr)",
											lg: "repeat(4, 1fr)",
										},
										gap: "1rem",
										width: "100%",
									}}
								>
									<ReportFilters
										onFiltersChange={onFiltersChange}
										filters={filters}
										showBeneficiaryFilters={false}
									/>
									<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
										<Button
											size="large"
											onClick={handleRunReport}
											color="primary"
											variant="contained"
											sx={runReportButton(theme)}
										>
											RUN REPORT
										</Button>

										<IconButton
											size="large"
											aria-label="clear filters"
											sx={searchOffIcon(theme)}
											onClick={handleClearFilters}
										>
											<SearchOff className="text-icon-primary" />
										</IconButton>
									</Box>
								</Box>

								<DataTable
									data={rows}
									rowsPerPage={reportFilters.pageSize}
									order="asc"
									orderBy="none"
									page={reportFilters.pageNumber}
									headCells={headCells}
									total={providerReport?.total}
									isLoading={isRefetching || isLoading}
									onChangePage={onChangePage}
									onChangeSize={onChangeSize}
								/>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProviderReport;
