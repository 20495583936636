import { useState, useMemo } from "react";
import { DataTableCell, Order } from "./dataTable.interface";

export function useFrontendPagination(
	data: DataTableCell[],
	initialOrder: Order,
	initialOrderBy: keyof DataTableCell,
	initialPage: number,
	initialRowsPerPage: number,
) {
	const [order, setOrder] = useState<Order>(initialOrder);
	const [orderBy, setOrderBy] = useState<keyof DataTableCell>(initialOrderBy);
	const [selected, setSelected] = useState<readonly number[]>([]);
	const [page, setPage] = useState(initialPage);
	const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

	const sortedData = useMemo(() => {
		const comparator = (a: DataTableCell, b: DataTableCell) => {
			let orderA = a[orderBy] || "";
			let orderB = b[orderBy] || "";
			if (typeof orderA === "object" && "id" in orderA) {
				orderA = orderA.id;
			}

			if (typeof orderB === "object" && "id" in orderB) {
				orderB = orderB.id;
			}

			if (orderA < orderB) {
				return order === "asc" ? -1 : 1;
			}
			if (orderA > orderB) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		};

		return data.slice().sort(comparator);
	}, [data, order, orderBy]);

	const paginatedData = useMemo(() => {
		const start = page * rowsPerPage;
		const end = start + rowsPerPage;
		return sortedData.slice(start, end);
	}, [sortedData, page, rowsPerPage]);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataTableCell,
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = sortedData.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return {
		order,
		orderBy,
		selected,
		page,
		rowsPerPage,
		sortedData: paginatedData,
		handleRequestSort,
		handleSelectAllClick,
		handleClick,
		handleChangePage,
		handleChangeRowsPerPage,
	};
}
