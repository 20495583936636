import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DataTableProps } from "./dataTable.interface";
import DataTableHead from "./DataTableHead";
import useTable from "./useTable";
import { useFrontendPagination as useFrontPagination } from "./useFrontendPagination";
import Skeleton from "@mui/material/Skeleton";
import { CSSProperties } from "react";

export default function DataTable({
	data,
	rowsPerPage,
	order: initialOrder,
	orderBy: initialOrderBy,
	page: initialPage,
	headCells,
	minWidth = 750,
	isLoading,
	total,
	messageEmptyData = "No data found",
	showPagination = true,
	onChangePage,
	onChangeSize,
	useFrontendPagination = false, // Default to false for backward compatibility
}: DataTableProps) {
	const useFrontPaginationHook = useFrontPagination(
		data,
		initialOrder,
		initialOrderBy,
		initialPage,
		rowsPerPage,
	);
	const {
		order,
		orderBy,
		selected,
		page,
		sortedData,
		handleRequestSort,
		handleSelectAllClick,
		handleClick,
		handleChangePage,
		handleChangeRowsPerPage,
	} = useFrontendPagination
		? useFrontPaginationHook
		: useTable(data, initialOrder, initialOrderBy, initialPage);
	const currentRowsPerPage = useFrontendPagination
		? useFrontPaginationHook.rowsPerPage
		: undefined;

	const isSelected = (id: number) => selected.indexOf(id) !== -1;
	return (
		<Box display="flex" flexDirection="column" width="100%">
			<TableContainer>
				<Table
					sx={{ minWidth: minWidth }}
					aria-labelledby="tableTitle"
					size="medium"
				>
					<DataTableHead
						numSelected={selected.length}
						order={order}
						orderBy={String(orderBy)}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={sortedData.length}
						headCells={headCells}
					/>
					<TableBody>
						{isLoading ? (
							<TableRow hover role="checkbox" tabIndex={-1} key="skeleton-row">
								{headCells.map((key, index) => (
									<TableCell key={`checkbox-table-cell-${index}`}>
										<Skeleton variant="text" width={100} height={32} />
									</TableCell>
								))}
							</TableRow>
						) : (
							<>
								{sortedData.map((row, index) => {
									const isItemSelected = isSelected(+row.id);
									const labelId = `enhanced-table-checkbox-${index}`;
									if (isLoading) {
										return (
											<TableRow
												hover
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.id}
												selected={isItemSelected}
											>
												{headCells.map((value, index) => (
													<TableCell key={`table-row-cell-${index}`}>
														<Skeleton variant="text" width={100} height={32} />
													</TableCell>
												))}
											</TableRow>
										);
									}

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, +row.id)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={`table-row-${row.id}-${index}`}
											selected={isItemSelected}
											sx={{
												cursor: "pointer",
												backgroundColor: (row.style as CSSProperties)
													?.backgroundColor,
											}}
										>
											{Object.keys(row).map((key, i) => {
												if (key !== "id" && key !== "style") {
													const headCell = headCells.find(
														(cell) => cell.id === key,
													);
													let cellValue = row[key];
													if (
														cellValue &&
														typeof cellValue === "object" &&
														"node" in cellValue
													) {
														cellValue = cellValue.node;
													}
													return (
														<TableCell
															key={`cell-${key}-${i}`}
															component={i === 0 ? "th" : "td"}
															id={i === 0 ? labelId : undefined}
															scope={i === 0 ? "row" : undefined}
															padding="normal"
															align={
																headCell?.id === "action"
																	? "right"
																	: headCell?.align
															}
														>
															{cellValue as React.ReactNode}
														</TableCell>
													);
												}
											})}
										</TableRow>
									);
								})}
							</>
						)}
						{!isLoading && sortedData.length === 0 && (
							<TableRow>
								<TableCell
									colSpan={15}
									sx={{
										textAlign: "center",
										padding: "1rem",
									}}
								>
									{messageEmptyData}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{showPagination && total ? (
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={total || data.length}
					rowsPerPage={currentRowsPerPage || rowsPerPage}
					page={initialPage || page}
					onPageChange={onChangePage || handleChangePage}
					onRowsPerPageChange={onChangeSize || handleChangeRowsPerPage}
				/>
			) : (
				<></>
			)}
		</Box>
	);
}
