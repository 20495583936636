import { useQuery } from "@tanstack/react-query";
import { dashboardService } from "../../services/dashboard.service";

const useKPIData = (userId: string) => {
	return useQuery({
		queryKey: ["dashboard-kpi-data", userId],
		queryFn: () => dashboardService.getKPIData(userId),
		staleTime: 1000 * 60 * 5,
	});
};

export default useKPIData;
