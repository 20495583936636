import { Box, Button, Card } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BasicSelect from "../../components/BasicSelect/BasicSelect";
import {
	ParentOrganization,
	ChildOrganization,
} from "../../domain/organization.interface";
import { IProgram } from "@/domain/program.interface";
import CircularProgress from "@mui/material/CircularProgress";
import OrganizationCheckboxList from "./OrganizationCheckboxList";
import {
	Controller,
	Control,
	useWatch,
	useForm,
	UseFormGetValues,
	useFieldArray,
} from "react-hook-form";
import { ICreateUserPayload } from "@/domain/user.interface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { organizationService } from "../../services/organization.service";

const { findOrganizationsByAccountProgramAndParentOrg } = organizationService;

type Props = {
	control: Control<ICreateUserPayload>;
	getValues: UseFormGetValues<ICreateUserPayload>;
	programsList: Array<IProgram> | undefined;
	parentLevelName: string;
	parentOrganizationsList: Array<ParentOrganization>;
	showPreviewState: [boolean, Dispatch<SetStateAction<boolean>>];
};

const AssignOuForm: FC<Props> = (props: Props) => {
	const {
		control,
		getValues,
		programsList,
		parentLevelName,
		parentOrganizationsList,
	} = props;

	const defaultSelectedGroup = {
		programId: "",
		organizationIdsList: "",
	};

	const [, setShowPreview] = props.showPreviewState;

	const schema = yup.object().shape({
		selectedProgramId: yup.string().required("This field is required"),
		selectedParentOrganizationId: yup
			.string()
			.required("This field is required"),
	});

	const {
		control: filterControl,
		formState: { errors: filterErrors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			selectedProgramId: defaultSelectedGroup.programId,
			selectedParentOrganizationId: defaultSelectedGroup?.organizationIdsList,
		},
	});

	const selectedProgramId = useWatch({
		control: filterControl,
		name: "selectedProgramId",
	});

	const selectedParentOrganizationId = useWatch({
		control: filterControl,
		name: "selectedParentOrganizationId",
	});

	const [childOrganizationsList, setChildOrganizationsList] = useState<
		Array<ChildOrganization>
	>([]);
	const [areChildOrganizationListLoading, setAreChildOrganizationListLoading] =
		useState(false);

	useEffect(() => {
		if (
			!selectedProgramId ||
			(!selectedParentOrganizationId && parentOrganizationsList.length > 0)
		) {
			return;
		}
		setAreChildOrganizationListLoading(true);

		findOrganizationsByAccountProgramAndParentOrg(
			process.env.REACT_APP_ACCOUNT as string,
			selectedProgramId,
			selectedParentOrganizationId,
		)
			.then((organizations: Array<ChildOrganization>) => {
				setChildOrganizationsList(organizations);
			})
			.finally(() => {
				setAreChildOrganizationListLoading(false);
			});
	}, [selectedProgramId, selectedParentOrganizationId]);

	const { append, remove } = useFieldArray({
		control,
		name: "programOrganizationsGroupList",
	});

	const [currentOrganizationIdsList, setCurrentOrganizationIdsList] = useState<
		string[]
	>([]); // group selected organizations ids

	useEffect(() => {
		setCurrentOrganizationIdsList(
			getValues("programOrganizationsGroupList")?.find(
				(g) => g.programId === selectedProgramId,
			)?.organizationIdsList || [],
		);
	}, [selectedProgramId]);

	function addOrganizationId(selectedOrganizationId: string) {
		control._formState.isDirty = true;
		setCurrentOrganizationIdsList((prevOrganizationIdsList) => [
			...prevOrganizationIdsList,
			selectedOrganizationId,
		]);
	}

	function deleteOrganizationId(selectedOrganizationId: string) {
		control._formState.isDirty = true;
		const organizationIndex = currentOrganizationIdsList.findIndex(
			(organizationId) => organizationId === selectedOrganizationId,
		);
		if (organizationIndex === -1) return;

		setCurrentOrganizationIdsList((prevOrganizationIdsList) =>
			prevOrganizationIdsList.filter(
				(organizationId, index) => index !== organizationIndex,
			),
		);
	}

	useEffect(() => {
		if (control._formState?.isDirty) {
			const programOrganizationsGroupList = getValues(
				"programOrganizationsGroupList",
			);
			if (!programOrganizationsGroupList) return;

			const currentProgramOrganizationsGroupIndex =
				programOrganizationsGroupList.findIndex(
					(programOrganizationsGroup) =>
						programOrganizationsGroup.programId === selectedProgramId,
				);

			if (currentProgramOrganizationsGroupIndex > -1) {
				remove(currentProgramOrganizationsGroupIndex);
			}

			if (currentOrganizationIdsList.length === 0) return;

			append({
				programId: selectedProgramId,
				organizationIdsList: currentOrganizationIdsList,
			});
		}
	}, [currentOrganizationIdsList]);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedOrganizationId = event.target.value;
		const isOrganizationChecked = event.target.checked;
		if (isOrganizationChecked) {
			addOrganizationId(selectedOrganizationId);
		} else {
			deleteOrganizationId(selectedOrganizationId);
		}
	};

	return (
		<>
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "repeat(4, 1fr)",
					gap: "1rem",
					width: "100%",
				}}
			>
				<Controller
					name="selectedProgramId"
					control={filterControl}
					render={({ field: { onChange, value } }) => (
						<BasicSelect
							data={
								programsList?.map((program) => {
									return { key: program.id, value: program.name };
								}) || []
							}
							value={value}
							required={true}
							label="Program"
							id="program"
							multiple={false}
							handleChange={onChange}
							error={!!filterErrors.selectedProgramId}
							errorMessage={filterErrors.selectedProgramId?.message || ""}
						/>
					)}
				/>

				{parentOrganizationsList.length ? (
					<Controller
						name="selectedParentOrganizationId"
						control={filterControl}
						render={({ field: { onChange, value } }) => (
							<BasicSelect
								data={
									parentOrganizationsList.map((org) => {
										return { key: org.id, value: org.name };
									}) || []
								}
								value={value}
								required={true}
								label={parentLevelName}
								id="parentLevelName"
								multiple={false}
								handleChange={onChange}
								error={!!filterErrors.selectedParentOrganizationId}
								errorMessage={
									filterErrors.selectedParentOrganizationId?.message || ""
								}
							/>
						)}
					/>
				) : null}
			</Box>

			<Box
				sx={{
					display: "flex",
					gap: "1rem",
				}}
				width={["100%", "100%", "100%", "100%", "80%"]}
			>
				<Card
					sx={{
						width: "70%",
						display: "flex",
						flexDirection: "column",
						flexGrow: 1,
						padding: "1rem 2rem",
						alignItems: "flex-start",
					}}
				>
					{areChildOrganizationListLoading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="240px"
							width="100%"
						>
							<CircularProgress />
						</Box>
					) : (
						<OrganizationCheckboxList
							programId={selectedProgramId}
							childOrganizationsList={childOrganizationsList} // available organizations
							currentOrganizationIdsList={currentOrganizationIdsList} // selected organizations
							handleCheckboxChange={handleCheckboxChange}
						/>
					)}
				</Card>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
						width: "30%",
					}}
				>
					<Button
						size="large"
						onClick={() => setShowPreview(true)}
						variant="outlined"
					>
						PREVIEW ASSIGNED OU
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default AssignOuForm;
