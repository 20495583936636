import { useEffect, useState, useMemo } from "react";
import { IAlertEvent } from "@/domain/alertEvent.interface";
import { EVENT_ENUM } from "../../domain/events.enum";
import { IUserRole } from "../../services/user.service";
import { useUserStore } from "../../stores/user.store";
import { useGetBeneficiaries } from "../useBeneficiaries";
import useDate from "../useDate";
import {
	addBeneficiariesCompletedEvents,
	addBeneficiariesDisenrollEvents,
	addBeneficiariesEnrollEvents,
	addBeneficiariesMissedEntryEvents,
	addBeneficiariesCertificateAvailableEvents,
	addBeneficiariesReconfirmationEvents,
	addBeneficiaries31DaysAbsEvents,
} from "./AlertAndEventsHelper";
import { useGetAlertAndEvents } from "../../hooks/useAlertAndEvents";

export const useSetupEvents = (userInfo: IUserRole) => {
	const { userHasAccessToCode } = useUserStore();
	const [events, setEvents] = useState<IAlertEvent[]>([]);
	const { dayjs } = useDate();

	const programs = useMemo(() => {
		return userInfo?.programs.reduce((acc, program) => {
			return acc ? `${acc},${program.programId}` : program.programId;
		}, "");
	}, [userInfo]);

	const initialFilters = useMemo(
		() => ({
			programId: programs,
			providerId: "",
			providerSiteId: "",
			status: ["ACTIVE", "COMPLETED", "PAUSED", "DISENROLLED"],
			search: "",
			pageNumber: 0,
			pageSize: 1000,
		}),
		[programs],
	);

	const {
		data: beneficiaryList,
		isLoading: benefLoading,
		error,
		refetch: refetchBeneficiaryList,
	} = useGetBeneficiaries({
		programId: initialFilters.programId,
		providerSite: initialFilters.providerSiteId,
		providerId: initialFilters.providerId,
		status: initialFilters.status,
		search: initialFilters.search,
		pageNumber: initialFilters.pageNumber + 1,
		pageSize: initialFilters.pageSize,
		userId: userInfo?.id,
	});

	const {
		data: backendAlerts,
		isLoading: backendLoading,
		isFetching: backendFetching,
		error: backendError,
		refetch: refetchBackendAlerts,
	} = useGetAlertAndEvents(userInfo?.id);

	useEffect(() => {
		if (initialFilters.programId) {
			refetchBeneficiaryList();
			refetchBackendAlerts();
		}
	}, [initialFilters.programId, refetchBeneficiaryList, refetchBackendAlerts]);

	useEffect(() => {
		if (!benefLoading && beneficiaryList && !backendLoading && backendAlerts) {
			const newEvents: IAlertEvent[] = [];

			for (const event in EVENT_ENUM) {
				switch (event) {
					case EVENT_ENUM.EVE_BEN_ENROLL:
						if (userHasAccessToCode(EVENT_ENUM.EVE_BEN_ENROLL)) {
							addBeneficiariesEnrollEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_BEN_ENROLL,
							);
						}
						break;
					case EVENT_ENUM.EVE_BEN_DISENROLL:
						if (userHasAccessToCode(EVENT_ENUM.EVE_BEN_DISENROLL)) {
							addBeneficiariesDisenrollEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_BEN_DISENROLL,
							);
						}
						break;
					case EVENT_ENUM.EVE_MISSED_ENTRY:
						if (userHasAccessToCode(EVENT_ENUM.EVE_MISSED_ENTRY)) {
							addBeneficiariesMissedEntryEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_MISSED_ENTRY,
							);
						}
						break;
					case EVENT_ENUM.EVE_BEN_COMPL:
						if (userHasAccessToCode(EVENT_ENUM.EVE_BEN_COMPL)) {
							addBeneficiariesCompletedEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_BEN_COMPL,
							);
							addBeneficiariesCertificateAvailableEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_BEN_COMPL,
							);
						}
						break;
					case EVENT_ENUM.EVE_CM_ELIG:
						if (userHasAccessToCode(EVENT_ENUM.EVE_CM_ELIG)) {
							addBeneficiariesReconfirmationEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_CM_ELIG,
							);
						}
						break;
					case EVENT_ENUM.EVE_31DAYS_ABS:
						if (userHasAccessToCode(EVENT_ENUM.EVE_31DAYS_ABS)) {
							addBeneficiaries31DaysAbsEvents(
								newEvents,
								beneficiaryList?.data ?? [],
								dayjs,
								EVENT_ENUM.EVE_31DAYS_ABS,
							);
						}
						break;
					default:
						break;
				}
			}

			newEvents.push(...backendAlerts);

			setEvents(newEvents);
		}
		if (error || backendError) {
			// TODO: if needed handle scenario instead of logging a console console.error(error);
		}
	}, [
		beneficiaryList,
		benefLoading,
		backendAlerts,
		backendLoading,
		error,
		backendError,
		userHasAccessToCode,
		dayjs,
	]);

	return { events, refetchBackendAlerts, isFetching: backendFetching };
};
