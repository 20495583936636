import React, { FC } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Box,
	Typography,
	CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import DataTable, { HeadCell } from "../../DataTable";
import { useViewOrderModal } from "./ViewOrderModalContext";
import { useAppTheme } from "../../../utils/theme";
import { useTranslation } from "react-i18next";

const ViewOrderModal: FC = () => {
	const { t } = useTranslation("cards");
	const { isOpen, closeModal, order, isLoading } = useViewOrderModal();
	const theme = useAppTheme();

	const headCells: HeadCell[] = [
		{
			id: "accountId",
			disablePadding: false,
			label: t("acct_id"),
			isSortable: false,
			align: "center",
		},
		{
			id: "firstName",
			disablePadding: false,
			label: t("first_name"),
			isSortable: false,
			align: "center",
		},
		{
			id: "lastName",
			disablePadding: false,
			label: t("last_name"),
			isSortable: false,
			align: "center",
		},
		{
			id: "isVirtual",
			disablePadding: false,
			label: t("is_virtual"),
			isSortable: false,
			align: "center",
		},
	];

	if (!isOpen) {
		return null;
	}

	if (isLoading) {
		return (
			<Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
				<Box
					sx={{
						position: "relative",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<CircularProgress />
				</Box>
			</Dialog>
		);
	}

	if (!order) {
		return null;
	}

	const rows = (order.cards || []).map((card, index) => ({
		id: index,
		accountId: card.accountId || "",
		firstName: card.firstName,
		lastName: card.lastName,
		isVirtual: card.isVirtual ? t("yes") : t("no"),
	}));

	return (
		<Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
			<Box sx={{ position: "relative" }}>
				<DialogTitle
					sx={{
						bgcolor: theme.palette.primary.dark,
						color: theme.palette.surface.light,
					}}
				>
					<Typography variant="headlineSmall">{t("view_order")}</Typography>
					<IconButton
						aria-label="close"
						onClick={closeModal}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: theme.palette.surface.light,
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
						marginTop: "1rem",
					}}
				>
					<Typography variant="body1">
						<strong>{t("order_id")}:</strong> {order.orderId}
					</Typography>
					<Typography variant="body1">
						<strong>{t("date_and_time")}:</strong> {order.orderDate}
					</Typography>
					<Typography variant="body1">
						<strong>{t("organization_unit")}:</strong> {order.organizationName}
					</Typography>
					<Typography variant="body1">
						<strong>{t("number_of_cards")}:</strong> {order.totalOrder}
					</Typography>
					<DataTable
						useFrontendPagination={true}
						data={rows}
						rowsPerPage={5}
						order="asc"
						orderBy="accountId"
						page={0}
						headCells={headCells}
						total={rows.length}
						isLoading={false}
						showPagination={true}
					/>
				</DialogContent>
			</Box>
		</Dialog>
	);
};

export default ViewOrderModal;
